import React, { useState } from "react";
import OfferBlock from "../../common/OfferBlock";
import { IoIosArrowForward } from "react-icons/io";
import { useSelector } from "react-redux";
import { Bids } from "../../../services";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";

const BuyQuantity = ({ data }) => {
  const navigate = useNavigate();
  const [pay, setPay] = useState("");
  const user = useSelector((state) => state.user);

  const sellCoins = (pay * data.price).toFixed(2);

  const handleOffer = () => {
    // if (!data.isBuy && (pay < data.limitFrom || pay > data.limitTo)) {
    if (pay < data.limitFrom || pay > data.limitTo) {
      toast.error("Coins should be in limit");
    } else {
      Bids.createOffer(
        user.id,
        data.userId,
        pay,
        data._id,
        data.isBuy ? false : true
      )
        .then((res) => {
          if (res.status === 200) {
            toast.success("posted successfully");
            navigate("/");
          }
        })
        .catch((e) => {
          // console.log('e', e)
          toast.error("error in posting");
        });
    }
  };

  const styles = {
    select:
      " block w-full sm:w-[440px] h-[40px] px-3 py-1 h-12 text-base font-normal text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  };

  const handleOnChange = (e) => {
    setPay(e.target.value);
    // const reg = /^[0-9]*(,\d+)?$/;
    // let preval = e.target.value;
    // if (e.target.value === "" || reg.test(e.target.value)) {
    //   setPay(e.target.value);
    // }
  };

  return (
    <OfferBlock>
      <div>
        <div className="flex flex-col gap-5 p-4 sm:p-8">
          <div className="flex flex-row flex-wrap justify-between">
            <div className="min-w-screen md:max-w-[440px]">
              <p className="text-white">{`I want to ${
                data.isBuy ? "Sell" : "Buy"
              } Coins`}</p>
              <input
                className={styles.select}
                type="number"
                placeholder="Min amount 50"
                value={pay}
                onChange={(e) => handleOnChange(e)}
              />
              {/* <p className="text-gray-400">Coins limit should be between {data.limitFrom} to {data.limitTo}</p> */}
            </div>
          </div>
          <div className="h-3 text-white">
            {pay && (
              <p>
                you will {`${data.isBuy ? "get" : "pay"}`}{" "}
                {!data.isBuy ? sellCoins : sellCoins} USD
              </p>
            )}
          </div>

          {/* <OfferInputField
            label="Trade Partner’s Bank"
            value={bank}
            onChange={setBank}
          /> */}
        </div>
        {user.firstName && user.lastName && user.bio && user.image ? (
          <div className="flex flex-row justify-center p-5">
            <button
              disabled={!pay}
              className="send-coins flex flex-row justify-center items-center bg-[#78FFD6] gap-2 rounded-md px-10 py-2"
              onClick={() => handleOffer()}
            >
              Done
            </button>
          </div>
        ) : (
          <div className="flex flex-row justify-between items-center px-4 py-2 my-5 bg-[#F53F3F]">
            <div className="flex flex-row items-center gap-3">
              <img
                className="w-12 h-12"
                src="/images/important.png"
                alt="img"
              />
              <div className="text-white">
                <p className="font-bold">We need some additional details</p>
                <p>
                  To buy or sell USDT coins, you need to update your profile.
                </p>
              </div>
            </div>
            <Link to="/account-setting">
              <button className="flex flex-row justify-center items-center bg-[#2FA348] text-white gap-2 rounded-md px-2 py-1">
                Update your profile
                <IoIosArrowForward style={{ color: "white" }} />
              </button>
            </Link>
          </div>
        )}
      </div>
    </OfferBlock>
  );
};

export default BuyQuantity;
