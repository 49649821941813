import React from "react";

const Condition = ({ terms }) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-white text-xl">Condition</p>
      <div className="w-full border border-[#78FFD6] bg-[#10192F] p-3">
        <p className="text-white">
          {terms}
        </p>
      </div>
    </div>
  );
};

export default Condition;
