import React from "react";
import LandingHeader from "../../common/LandingHeader";
import ServicesBlocks from "../../common/ServicesBlocks";
import ServicesOffered from "../../common/ServicesOffered";

const heading = `The Service We Provide For You`;
const detail = "";

const services = [
  { images: '/images/trusted.svg', heading: "90,000+", detail: "Trusted Offers" },
  { images: '/images/food-stall.svg', heading: "12,000+", detail: "Trusted Vendors" },
  { images: '/images/Customers.svg', heading: "27,000+", detail: "Trusted Customers" },
];

const data = [
  {
    icon: "/images/services/one.svg",
    heading: "Buy USDT online",
    detail:
      "Buy USDT on P2Pways in real time. Trade with other users online using our live chat.",
  },
  {
    icon: "/images/services/two.svg",
    heading: "Sell USDT",
    detail:
      "Sell your USDT at your chosen rate, and get paid in one of numerous payment methods.",
  },
  {
    icon: "/images/services/three.svg",
    heading: "Trade with secure escrow",
    detail:
      "Your USDT is held in our secure escrow until the trade is completed successfully.",
  },
  {
    icon: "/images/services/four.svg",
    heading: "Build your reputation",
    detail:
      "Our user feedback system enables you to identify trusted and experienced peers to trade with.",
  },
  {
    icon: "/images/services/five.svg",
    heading: "Get a free wallet",
    detail:
      "Get a life-time free USDT wallet maintained by P2Pways, the leading provider of secure USDT wallets.",
  },
  {
    icon: "/images/services/six.svg",
    heading: "Earn extra income",
    detail:
      "Take advantage of our Affiliate Program to create a steady income stream.(Coming Soon)",
  },
];

const Services = () => {
  return (
    <div className="container py-10">
      <h1 className='text-2xl sm:text-4xl pb-10 text-center font-bold text-[#78FFD6]'>Our Goals for 2023</h1>
      <div className="flex flex-row justify-center flex-wrap gap-1 md:gap-14 ">
        {services.map((item, index) => (
          <ServicesBlocks
            key={index}
            index={index}
            icon={item.images}
            heading={item.heading}
            text={item.detail}
          />
        ))}
      </div>
      <LandingHeader heading={heading} detail={detail} />
      <div className="flex flex-row justify-center flex-wrap">
        {data.map((d, index) => (
          <ServicesOffered
            key={index}
            index={index}
            icon={d.icon}
            heading={d.heading}
            detail={d.detail}
          />
        ))}
      </div>
    </div>
  );
};

export default Services;
