export const buyData = [
    {
        heading: '1-Set your requirements ',
        detail: 'Choose your preferred payment method and the maximum amount of USDT you’re willing to buy. Once you’re done, click Search For Offers. You will see a list of relevant offers to choose from.'
    },
    {
        heading: '2-Review offers',
        detail: 'Before selecting an offer, be sure to check all vital information about the seller, including but not limited to their name, reputation, verification level, and rate per USDT. Once you’ve found a suitable offer, click buy. It won’t open a trade yet, but will guide you through the offer terms set by the seller.'
    },
    {
        heading: '3-Start the trade',
        detail: 'If you are satisfied with the seller’s terms, enter the amount you’re willing to trade for and click Buy now. This will open a live trade chat and move your USDT to our secured escrow. Read the instructions provided carefully, and follow them. Once you paid and the seller received the full payment, seller will release the USDT to your wallet on P2Pways minus the platform fee.'
    },
    {
        heading: '4-Leave feedback',
        detail: 'After successfully receiving your USDT, don’t forget to give your trade partner feedback. This is important for our platform as it helps build a user’s reputation.'
    },
]