import React from "react";

const InputGroup = ({
  isDollar,
  label,
  placeholder,
  value,
  onChange,
  trade,
  description,
  info,
}) => {

  const handleOnChange = (e) => {
    const reg = /^[0-9][.\d]*(,\d+)?$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      onChange(e.target.value);
    } 
  };

  const styles = {
    input:
      "w-full sm:w-[290px] h-[50px] lg:min-w-[350px] px-3 py-1 text-base font-normal text-gray-500 bg-white m-0 focus:text-gray-700 focus:bg-white focus:outline-none placeholder:text-[12px]",
    group:
      "w-full sm:max-w-[360px] lg:min-w-[420px] flex flex-row h-[50px] text-base font-normal bg-white",
  };
  return (
    <div className="w-[340px] md:w-[420px] flex flex-col gap-2">
      <p className="text-white">{label}</p>
      <div className={styles.group}>
        <input
          className={styles.input}
          type="number"
          placeholder={placeholder}
          value={value}
          onChange={(e) => handleOnChange(e)}
        />
        <div className="w-[70px] flex justify-center items-center px-5 border-l border-black text-black font-medium">
          {trade}
        </div>
      </div>
    </div>
  );
};

export default InputGroup;
