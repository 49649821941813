import React, { useState, useEffect } from "react";
import Page from "../components/common/Page";
import ContactInput from "../components/screens/Contact/ContactInput";
import ContactTextArea from "../components/screens/Contact/TextArea";
import { Formik } from "formik";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Auth from "../services/auth";
import { toast } from "react-toastify";

const ContactUs = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.id) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const contactSchema = yup.object().shape({
    Name: yup.string().required("Name is required"),
    Email: yup.string().email().required("Email is required"),
    Phone: yup.string().required("Phone Number is required"),
    Message: yup.string().required("Your message is required"),
  });

  return (
    <Page title="P2Pways – Contact us">
      <div className="container">
        <div className="">
          <h1 className="text-center text-[48px] font-bold text-[#26D1A0] mt-20">
            Contact Us
          </h1>
          <div className="">
            <Formik
              validateOnChange={false}
              initialValues={{
                Name: "",
                Email: "",
                Phone: "",
                Message: "",
              }}
              onSubmit={(values, { resetForm }) => {
                setIsLoading(true)
                const { Name, Email, Phone, Message } = values
                Auth.contactUs(Name, Email, Phone, Message)
                .then((res) => {
                  toast.success(`Thank you for contacting us, our team will answer your query shortly`)
                  resetForm({ values: "" })
                })
                .catch((e) => {
                  toast.error('Error in sending email')
                })
                .finally(() => setIsLoading(false))
              }}
              validationSchema={contactSchema}
            >
              {({ handleChange, handleSubmit, ...props }) => (
                <div className="w-full flex my-10">
                  <div className="w-full flex flex-row items-center">
                    <div className="w-full md:w-1/2 flex flex-col gap-2">
                      <form onSubmit={handleSubmit}>
                        <div className="w-full  md:max-w-[400px]">
                          <div className="w-full flex flex-col items-center md:items-start gap-5 p-5">
                            <div className="w-full">
                              <ContactInput
                                label="Your Name"
                                type="text"
                                placeholder="Enter your name"
                                value={props.values.Name}
                                onChange={handleChange("Name")}
                                error={props.errors.Name}
                                isInvalid={
                                  props.touched.Name && props.errors.Name
                                }
                              />
                            </div>
                            <div className="w-full">
                              <ContactInput
                                label="Email"
                                type="email"
                                placeholder="Enter your email address"
                                value={props.values.Email}
                                onChange={handleChange("Email")}
                                error={props.errors.Email}
                                isInvalid={
                                  props.touched.Email && props.errors.Email
                                }
                              />
                            </div>
                            <div className="w-full">
                              <ContactInput
                                label="Phone Number"
                                type="number"
                                placeholder="Enter your phone number"
                                value={props.values.Phone}
                                onChange={handleChange("Phone")}
                                error={props.errors.Phone}
                                isInvalid={
                                  props.touched.Phone && props.errors.Phone
                                }
                              />
                            </div>
                            <div className="w-full">
                              <ContactTextArea
                                label="Message"
                                type="text"
                                value={props.values.Message}
                                onChange={handleChange("Message")}
                                error={props.errors.Message}
                                isInvalid={
                                  props.touched.Message && props.errors.Message
                                }
                              />
                            </div>
                          </div>
                          <div className="flex flex-row justify-center gap-5 p-5">
                            <input
                              type="submit"
                              text="Contact Us"
                              className="w-full px-5 py-2 rounded bg-[#003472] text-white disabled:bg-slate-400 disabled:cursor-not-allowed"
                              onClick={handleSubmit}
                              disabled={isLoading}
                            />
                          </div>
                        </div>
                      </form>
                    </div>
                    <div className="w-1/2 hidden md:block">
                      <img src="/images/contact-img.png" alt="img" />
                    </div>
                  </div>
                </div>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ContactUs;
