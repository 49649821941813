import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Page from "../components/common/Page";
import ChatList from "../components/screens/Inbox/ChatList";
import Conversation from "../components/screens/Inbox/Conversation";
import { Chat } from "../services";

const Inbox = () => {
  const navigate = useNavigate();

  const [myChatGroups, setMyChatGroups] = useState([]);
  const [activeChat, setActiveChat] = useState(null);
  const [receiverId, setReceiverId] = useState(null);
  const [isView, setIsView] = useState(false);

  const loginUser = useSelector((state) => state.user);

  useEffect(() => {
    if (!loginUser.id) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Chat.getChatGroup(loginUser.id)
      .then((res) => {
        if (res.status === 200) {
          const filtered = res.data.filter((group) =>
            group.deletedBy.filter((id) => id === loginUser.id).length
              ? null
              : group
          );
          setMyChatGroups(filtered);
        }
      })
      .catch((e) => console.log("e", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);


  return (
    <Page title="P2Pways – Inbox">
      <div className="container flex flex-row justify-center px-2 sm:p-5">
        <div className="hide w-full h-[500px] md:flex flex-row md:w-11/12 mt-5 bg-[#404759]">
          <div className="w-1/3 min-w-[300px] bg-[#111b21] border-r border-[#34424b]">
            <div className="border-b border-[#222d34]">
              <div className="h-14 flex flex-col justify-center bg-[#202C33]">
                <p className="text-lg font-bold px-2 py-2 text-white">Chat</p>
              </div>
              <div className="bg-[#111b21] p-2">
                {/* <div className="rounded bg-[#202C33] border-b border-[#222d34]">
                  <input
                    className="w-full p-2 text-white bg-transparent outline-none"
                    type="text"
                    placeholder="search"
                    value={search}
                    onChange={(event) => handleSearch(event)}
                  />
                </div> */}
              </div>
            </div>
            <ChatList
              myChatGroups={myChatGroups}
              activeChat={activeChat}
              setActiveChat={setActiveChat}
              setReceiverId={setReceiverId}
            />
          </div>
          <div className="w-2/3 bg-black">
            <Conversation
              activeChat={activeChat}
              receiverId={receiverId}
              setReceiverId={setReceiverId}
            />
          </div>
        </div>
        <div className="mobile-chat w-full h-[500px]  flex flex-row md:w-12/12 mt-5 bg-[#404759]">
        {!isView && (
          <div className="w-full bg-[#111b21] border-r border-[#34424b]">
            <div className="border-b border-[#222d34]">
              <div className="h-14 flex flex-col justify-center bg-[#202C33]">
                <p className="text-lg font-bold px-2 py-2 text-white">Chat</p>
              </div>
              <div className="bg-[#111b21] p-2"></div>
            </div>
            
              <ChatList
                myChatGroups={myChatGroups}
                activeChat={activeChat}
                setActiveChat={setActiveChat}
                setReceiverId={setReceiverId}
                setIsView={setIsView}
              />
          </div>
            )}
          {isView && (
            <div className="w-full bg-black">
              <Conversation
                activeChat={activeChat}
                receiverId={receiverId}
                setReceiverId={setReceiverId}
                setIsView={setIsView}
              />
            </div>
          )}
        </div>
      </div>
    </Page>
  );
};

export default Inbox;
