import { ethers } from "ethers";
import { toast } from "react-toastify";
import { admin_address, admin_private_key } from "../config/admin";
import { binance_provider } from "../lib/config";

const Web3 = require("web3");

const web3 = new Web3(binance_provider);


const contract_address = "";

const address = admin_address;
const privateKey = admin_private_key;

export function depositBNB(to) {
  // console.log("web3", web3);

  const token_amount = "0.0008";
  console.log("to", to);

  // console.log("====", address, privateKey);

  let walletSigner = "";

  let send_token_amount = token_amount;
  let to_address = to;
  let send_address = address;
  // let gas_limit = "0x100000";

  web3.eth.getGasPrice().then(async (currentGasPrice) => {
    // let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice));
    console.log("......");

    if (contract_address.length) {
      try {
        let contract = new ethers.Contract(contract_address, walletSigner);
        let numberOfTokens = ethers.utils.parseUnits(send_token_amount, 18);
        contract
          .transfer(to_address, numberOfTokens)
          .then((transferResult) => {
            toast.success("Coins transferred");
          })
          .catch((e) => {
            toast.error("You dont have enough balance for estimate gas limit");
            // ; transaction may fail or may require manual gas limit
          });
      } catch (error) {
        toast.error(error);
      }
    } else {
      console.log("else");
      try {
        const signedTx = await web3.eth.accounts.signTransaction(
          {
            from: send_address,
            to: to_address,
            value: web3.utils.toWei(token_amount, "ether"),
            gas: "21000",
          },
          privateKey
        );
        console.log("signedTx", signedTx);

        web3.eth.sendSignedTransaction(
          signedTx.rawTransaction,
          function (error, hash) {
            if (!error) {
              console.log("🎉 transaction hash: ");
            } else {
              console.log("❗error:");
            }
          }
        );

        // })
      } catch (error) {
        console.log("e", error);
      }
    }
  });
}

