import React from "react";
import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";
import moment from "moment";


const AboutUser = ({ user }) => {
  const joining = moment(user.createdAt).fromNow();

  return (
    <div className="max-w-[425px] flex flex-row flex-wrap md:flex-col gap-8">
      <ProfileBlock>
        <Header>
          <p className="text-xl py-2 text-[#6DDABD] font-semibold">
            Verification
          </p>
        </Header>
        <Body>
        <div className="flex flex-row gap-2 mb-2">
            <img src="/images/profile/phone.svg" alt="img" />
            <p
              className={
                user.isPhoneVerified
                  ? "text-white text-sm"
                  : "text-[#FF7A7A] text-sm"
              }
            >
              {user.isPhoneVerified ? (
                <span>Phone verified</span>
              ) : (
                <span className="cursor-pointer">
                  Phone not verified
                </span>
              )}
            </p>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <img src="/images/profile/mail.svg" alt="img" />
            <p
              className={
                !user.isVerified
                  ? "text-[#FF7A7A] text-sm"
                  : "text-white text-sm"
              }
            >
              {user.isVerified ? "Email verified" : "Verify your email"}
            </p>
          </div>
        
        </Body>
      </ProfileBlock>
      <ProfileBlock>
        <Header>
          <div className="flex flex-row justify-between items-center py-2">
            <p className="text-xl text-[#6DDABD] font-semibold">Info</p>
            <p className="text-[#ABABAB] text-sm">{joining}</p>
          </div>
        </Header>
        <Body>
        <div className="flex flex-row gap-2 mb-2">
            <p className="text-white text-md w-[100px]">Location: </p>
            <span className="text-[#ABABAB] text-[13px]">
              {user?.country.toUpperCase()}
            </span>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <p className="text-white text-md w-[100px]">Language: </p>
            <span className="text-[#ABABAB] text-[13px]">ENGLISH</span>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <p className="text-white text-md w-[100px]">Trade: </p>
            <span className="text-[#ABABAB] text-[13px]">USDT</span>
          </div>
        </Body>
      </ProfileBlock>
    </div>
  );
};

export default AboutUser;

