import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";

import { toast } from "react-toastify";
import { Offer } from "../../../services";
import { getTokenBalance } from "../../../utils/getBalance";
import InputGroup from "../../common/InputGroup";
import LimitInputGroup from "../../common/LimitInputGroup";

import Page from "../../common/Page";
import { PaymentMethods } from "../../common/PaymentOptions";

const EditOffer = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const offer = location.state.data;

  const options = offer.paymentMethod.map((method, index) => ({
    value: method,
    label: method,
  }));

  const user = useSelector((state) => state.user);

  const [minimumPrice, setMinimumPrice] = useState(offer.limitFrom);
  const [maximumPrice, setMaximumPrice] = useState(offer.limitTo);
  const [myPrice, setMyPrice] = useState(offer.price);
  const [paymentMethod, setPaymentMethod] = useState(options);
  const [isLoading, setIsLoading] = useState(false);
  const [balance, setBalance] = useState("");
  const [postTotalCoins, setPostTotalCoins] = useState(0);
  const [remainingCoins, setRemainingCoins] = useState(balance);

  useEffect(() => {
    if (user.address) {
      getTokenBalance(setBalance, user.address);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Offer.getMyAllSellingOfferSum(user.id)
      .then((res) => {
        setPostTotalCoins(res.data.sum);
        setRemainingCoins(Number(balance) - Number(res.data.sum));
      })
      .catch((e) => setRemainingCoins(Number(balance) - 0));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance]);

  const changeHandler = (value) => {
    setPaymentMethod(value);
  };

  const style = {
    valueContainer: (base, state) => {
      const minWidth = "200px";
      const maxWidth = "420px";
      const Width = "100%";
      const minHeight = "50px";
      const borderColor = "none";
      const fontSize = "12px";
      return {
        ...base,
        minWidth,
        maxWidth,
        Width,
        minHeight,
        fontSize,
        borderColor,
      };
    },
  };

  const handleUpdate = () => {
    if (!offer.isBuy && Number(postTotalCoins) > Number(balance)) {
      toast.error(
        `You don't have enough coins to sell, delete your selling offer to create a new offer`
      );
    } else if (Number(minimumPrice) < 1) {
      return toast.error("Minimum price should be greater then 1");
    } else if (Number(minimumPrice) > Number(maximumPrice)) {
      return toast.error("Minimum price should be less than maximum price");
    } else if (Number(minimumPrice) === Number(maximumPrice)) {
      return toast.error("Maximum price should be greater than minimum price");
    } else if (Number(maximumPrice) > Number(remainingCoins) && !offer.isBuy) {
      toast.error(
        `You don't have enough coins to sell, your coins limit are ${remainingCoins.toFixed(
          2
        )}`
      );
    } else {
      setIsLoading(true);
      Offer.updateOffer(
        offer._id,
        myPrice,
        minimumPrice,
        maximumPrice,
        paymentMethod
      )
        .then((res) => {
          toast.success("Offer updated successfully");
          navigate("/profile");
        })
        .catch((e) => toast.error(e.response.message))
        .finally(() => setIsLoading(false));
    }
  };

  const handleCancel = () => {
    navigate("/profile");
  };

  // const handleOnChange = (e) => {
  //   const reg = /^[0-9][\.\d]*(,\d+)?$/;
  //   let preval = e.target.value;
  //   if (e.target.value === "" || reg.test(e.target.value)) {
  //     onChange(e.target.value);
  //   } 
  // };

  return (
    <Page title="P2Pways – Edit Offer">
      <div className="container px-1 mt-10 md:mt-20">
        <div className="flex flex-col items-center gap-10 md:px-20">
          <div className="w-11/12">
            <p className="text-xl text-[#78FFD6]">Offer Trade Limits</p>
            <div className="flex flex-row justify-between flex-wrap sm:mx-10 mt-5 gap-5">
              <LimitInputGroup
                label="Minimum USDT"
                placeholder="Enter min amount"
                value={minimumPrice}
                onChange={setMinimumPrice}
                description="Lorem ipsum dolor sit amet, consect etur adipiscing elit. Vestibulum ultri cies mi vel."
                info={true}
              />
              <LimitInputGroup
                label="Maximum USDT"
                placeholder="Enter max amount"
                value={maximumPrice}
                onChange={setMaximumPrice}
                description="Lorem ipsum dolor sit amet, consect etur adipiscing elit. Vestibulum ultri cies mi vel."
                info={true}
              />
            </div>
            <div className="flex flex-row justify-between flex-wrap mt-5"></div>
            <div className="mt-14">
              <p className="text-xl text-[#78FFD6]">
                Fixed price market rate your offer will list at
              </p>
              <div className="flex flex-row justify-between items-center gap-5 flex-wrap sm:mx-10 mt-5">
                <InputGroup
                  placeholder="Enter 
              your offer, exp: 1.01 price is 1% above market price"
                  value={myPrice}
                  onChange={setMyPrice}
                />
                <div className="w-full sm:max-w-[360px] lg:max-w-[420px]">
                  <Select
                    isMulti={true}
                    placeholder="All payment methods"
                    styles={style}
                    options={PaymentMethods}
                    value={paymentMethod}
                    onChange={changeHandler}
                  />
                </div>
              </div>

              {!!myPrice.length && (
                <p className="text-white sm:mx-10">
                  Your price is{" "}
                  <span className="text-[#FF0000]">
                    {((myPrice - 1 / 1) * 100).toFixed(1)}%
                  </span>{" "}
                  {(myPrice - 1 / 1) * 100 > 0 ? "above" : "below"} the market
                  price
                </p>
              )}
            </div>
          </div>
          <div className="flex flex-row justify-center gap-16 mt-16">
            <button
              className="send-coins px-8 py-2 bg-[#e1e7e0] rounded font-semibold disabled:bg-slate-500 disabled:cursor-not-allowed"
              onClick={() => handleCancel()}
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              className="send-coins px-8 py-2 bg-[#98F18A] rounded font-semibold disabled:bg-slate-500 disabled:cursor-not-allowed"
              onClick={() => handleUpdate()}
              disabled={isLoading}
            >
              Update
            </button>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default EditOffer;
