import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../common/Auth";
import { Formik } from "formik";
import * as yup from "yup";
import InputField from "../../common/InputField";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { register } from "../../../redux/user/action";
import { AiOutlineArrowLeft } from "react-icons/ai";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [state, setState] = useState(true);
  const list = ["Email"];


  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.id) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  const signUpSchema = yup.object().shape({
    isMail: yup.string(),
    Email: yup
      .string()
      .email()
      .when("isMail", {
        is: "Email",
        then: yup.string().required("Email is required"),
      }),
    Phone: yup.string().when("isMail", {
      is: "Phone Number",
      then: yup.string().required("Phone is required"),
    }),
    Password: yup
      .string()
      .min(12)
      .required("Password is required")
      .test(
        "regex",
        "A combination of uppercase letters,lowercase letters, numbers and symbols.",
        (val) => {
          const regExp = new RegExp(
            "^(?=.*\\d)(?=.*[!@#$_-])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
          );
          return regExp.test(val);
        }
      ),
    ConfirmPassword: yup
      .string()
      .min(12)
      .required("Confirm Password is required")
      .oneOf([yup.ref("Password")], "Password should be same."),
  });

  const handleBack = () => navigate("/login");

  return (
    <Auth>
      <div className="flex flex-col justify-center items-center p-5 h-full relative">
        <Formik
          validateOnChange={false}
          initialValues={{
            isMail: "Email",
            Email: "",
            Password: "",
          }}
          onSubmit={(values) => {
            setIsLoading(true);
            UserAuth.register(values.Email, values.Password)
              .then((res) => {
                if (res.status === 200) {
                  dispatch(register(values.Email));
                  toast.success("registered successfully.");
                  localStorage.setItem("qr", res?.data?.user?.qr);
                  localStorage.setItem("address", res?.data?.address);
                  navigate("/verification");
                }
              })
              .catch((e) => {
                toast.error(e.response.data);
                console.log("e", e);
              })
              .finally(() => setIsLoading(false));
          }}
          validationSchema={signUpSchema}
        >
          {({ handleChange, handleSubmit, setFieldValue, ...props }) => (
            <div className="flex flex-col justify-evenly items-center w-full h-5/6 mx-0 sm:mx-10">
              <div className="w-full relative">
              <p className="font-bold text-xl mt-5 text-blue-600 text-center">
                Sign up With P2Pways
              </p>
              <AiOutlineArrowLeft
                onClick={handleBack}
                style={{
                  color: "black",
                  width: "50px",
                  cursor: "pointer",
                  position: "absolute",
                  left: 10,
                  bottom: 0,
                  transform: "translate(-50%, -50%)",
                }}
              />
              </div>
              <div className="flex flex-row justify-center items-center gap-10">
                {list.map((l, index) => (
                  <p
                    key={index}
                    className={
                      props.values.isMail === l
                        ? "text-blue-600 border-b-2 border-blue-600 cursor-pointer"
                        : "cursor-pointer text-[#828282]"
                    }
                    onClick={() => setFieldValue("isMail", l)}
                  >
                    {l}
                  </p>
                ))}
              </div>
              <div className="w-full max-w-[500px] px-5">
                <form onSubmit={handleSubmit}>
                  <div>
                    {props.values.isMail === "Phone Number" ? (
                      <InputField
                        type="tel"
                        placeholder="Phone Number"
                        value={props.values.Phone}
                        onChange={handleChange("Phone")}
                        isInvalid={props.touched.Phone && props.errors.Phone}
                        error={props.errors.Phone}
                      />
                    ) : (
                      <InputField
                        type="Email"
                        placeholder="Email"
                        value={props.values.Email}
                        onChange={handleChange("Email")}
                        isInvalid={props.touched.Email && props.errors.Email}
                        error={props.errors.Email}
                      />
                    )}
                    <InputField
                      type={state ? "password" : "text"}
                      placeholder="Password"
                      value={props.values.Password}
                      onChange={handleChange("Password")}
                      isInvalid={
                        props.touched.Password && props.errors.Password
                      }
                      error={props.errors.Password}
                      state={state}
                      setState={setState}
                      isPassword={true}
                    />
                    <InputField
                      type={state ? "password" : "text"}
                      placeholder="Confirm Password"
                      value={props.values.ConfirmPassword}
                      onChange={handleChange("ConfirmPassword")}
                      isInvalid={
                        props.touched.ConfirmPassword &&
                        props.errors.ConfirmPassword
                      }
                      error={props.errors.ConfirmPassword}
                      state={state}
                      setState={setState}
                      isPassword={true}
                    />
                  </div>
                  <div className="flex flex-col items-center mt-5">
                    <input
                      type="submit"
                      className="flex flex-row items-center text-black rounded-lg gap-3 px-20 py-2 bg-[#78FFD6] disabled:bg-slate-400 disabled:cursor-not-allowed"
                      disabled={isLoading}
                      value="Sign Up"
                    />
                    {/* <SearchButton text="Sign Up" onClick={handleSubmit} /> */}
                    <p className="text-[#828282] text-center mt-1">
                      New to P2Pways?{" "}
                      <Link to="/login">
                        <span className="text-blue-600 cursor-pointer">
                          {" "}
                          Sign In
                        </span>
                      </Link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

export default SignUp;
