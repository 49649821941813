import React from "react";
import ActiveOffers from "./ActiveOffers";
import Feedback from "./Feedback";
import History from "./History";

const RightBlock = () => {
  return (
    <div className="flex flex-col gap-8">
      <ActiveOffers />
      <History />
      <Feedback />
    </div>
  );
};

export default RightBlock;

