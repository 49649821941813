import { ethers } from "ethers";
import { toast } from "react-toastify";
import { admin_address } from "../config/admin";
import { binance_provider, usdt_contract_address } from "../lib/config";
import { depositBNB } from "./depositBNB";
import { getBnbBalance } from "./getBnbBalance";

const contract_address = usdt_contract_address;

const web3 = ethers.providers.getDefaultProvider(binance_provider);

const send_abi = [
  // transfer
  {
    constant: false,
    inputs: [
      {
        name: "_to",
        type: "address",
      },
      {
        name: "_value",
        type: "uint256",
      },
    ],
    name: "transfer",
    outputs: [
      {
        name: "",
        type: "bool",
      },
    ],
    type: "function",
  },
];

export const sendBepToken = async (
  value,
  to,
  address,
  privateKey,
  callback
) => {
  const token_amount = (value * 0.99).toString();
  const deduction = (value * 0.01).toString();

  const userBnbBalance = await getBnbBalance(address);

  let wallet = new ethers.Wallet(privateKey);
  let walletSigner = wallet.connect(web3);

  let send_token_amount = token_amount;
  let to_address = to;
  let send_address = address;
  let gas_limit = "0x100000";

  web3.getGasPrice().then((currentGasPrice) => {
    let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice));
    if (contract_address) {
      try {
        let contract = new ethers.Contract(
          contract_address,
          send_abi,
          walletSigner
        );
        let numberOfTokens = ethers.utils.parseUnits(send_token_amount, 18);

        contract
          .transfer(to_address, numberOfTokens)
          .then((transferResult) => {
            toast.success("Coins transferred");
            adminPercentage(deduction, admin_address, address, privateKey);
            callback(true);
          })
          .catch((e) => {
            callback(false);
            toast.error(
              "You dont have enough balance (BNB) for estimate gas limit"
            );
            if (Number(userBnbBalance) < 0.0008) {
              depositBNB(send_address);
            }
          });
      } catch (error) {
        toast.error(error);
      }
    } // ether send
    else {
      // console.log("else");
      const tx = {
        from: send_address,
        to: to_address,
        value: ethers.utils.parseEther(send_token_amount),
        nonce: web3.getTransactionCount(send_address, "latest"),
        gasLimit: ethers.utils.hexlify(gas_limit), // 100000
        gasPrice: gas_price,
      };
      console.dir(tx);
      try {
        walletSigner.sendTransaction(tx).then((transaction) => {
          console.dir(transaction);
          alert("Send finished!");
        });
      } catch (error) {
        alert("failed to send!!");
      }
    }
  });
};

export function adminPercentage(value, to, address, privateKey) {
  let wallet = new ethers.Wallet(privateKey);
  let walletSigner = wallet.connect(web3);
  let send_token_amount = value;
  let to_address = to;
  let send_address = address;
  let gas_limit = "0x100000";

  web3.getGasPrice().then((currentGasPrice) => {
    let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice));
    if (contract_address) {
      try {
        let contract = new ethers.Contract(
          contract_address,
          send_abi,
          walletSigner
        );
        let numberOfTokens = ethers.utils.parseUnits(send_token_amount, 18);

        contract
          .transfer(to_address, numberOfTokens)
          .then((transferResult) => {})
          .catch((e) => console.log("ee"));
      } catch (error) {
        toast.error(error);
      }
    } // ether send
    else {
      // console.log("else");
      const tx = {
        from: send_address,
        to: to_address,
        value: ethers.utils.parseEther(send_token_amount),
        nonce: web3.getTransactionCount(send_address, "latest"),
        gasLimit: ethers.utils.hexlify(gas_limit), // 100000
        gasPrice: gas_price,
      };
      console.dir(tx);
      try {
        walletSigner.sendTransaction(tx).then((transaction) => {
          console.dir(transaction);
          alert("Send finished!");
        });
      } catch (error) {
        alert("failed to send!!");
      }
    }
  });
}

export async function withdraw_coins(value, to, address, privateKey, callback) {
  const token_amount = (value - 0.4).toString();
  const deduction = (0.4).toString();

  const userBnbBalance = await getBnbBalance(address);

  let wallet = new ethers.Wallet(privateKey);
  let walletSigner = wallet.connect(web3);
  let send_token_amount = token_amount;
  let to_address = to;
  let send_address = address;
  let gas_limit = "0x100000";

  web3.getGasPrice().then((currentGasPrice) => {
    let gas_price = ethers.utils.hexlify(parseInt(currentGasPrice));
    if (contract_address) {
      try {
        let contract = new ethers.Contract(
          contract_address,
          send_abi,
          walletSigner
        );
        let numberOfTokens = ethers.utils.parseUnits(send_token_amount, 18);
        console.log(`numberOfTokens: ${numberOfTokens}`);
        contract
          .transfer(to_address, numberOfTokens)
          .then((transferResult) => {
            console.dir("dir", transferResult);
            toast.success("Withdraw successfully");
            adminPercentage(deduction, admin_address, address, privateKey);
            callback(true);
          })
          .catch((e) => {
            callback(false);
            toast.error("You dont have enough balance for estimate gas limit");
            if (Number(userBnbBalance) < 0.0008) {
              depositBNB(send_address);
            }
          });
      } catch (error) {
        toast.error(error);
      }
    } // ether send
    else {
      // console.log("else");
      const tx = {
        from: send_address,
        to: to_address,
        value: ethers.utils.parseEther(send_token_amount),
        nonce: web3.getTransactionCount(send_address, "latest"),
        gasLimit: ethers.utils.hexlify(gas_limit), // 100000
        gasPrice: gas_price,
      };
      console.dir(tx);
      try {
        walletSigner.sendTransaction(tx).then((transaction) => {
          console.dir(transaction);
          alert("Send finished!");
        });
      } catch (error) {
        alert("failed to send!!");
      }
    }
  });
}

// const web3 = ethers.providers.getDefaultProvider(binance_provider);

// const web3 = ethers.providers.getDefaultProvider(
//   "https://bsc-dataseed.binance.org"
// );