import axios from "axios";
import { API_URL } from "../lib/config";

const token = localStorage.getItem("token");

const reqInstance = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Auth = {
  register: (email, password) => {
    return axios
      .post(`${API_URL}/v1/auth/register`, {
        email,
        password,
      })
      .then((res) => res);
  },
  login: (email, password) => {
    return axios
      .post(`${API_URL}/v1/auth/login`, {
        email,
        password,
      })
      .then((res) => res);
  },
  verifyToken: (email, code) => {
    return axios
      .post(`${API_URL}/v1/auth/verifyToken`, {
        email,
        code,
      })
      .then((res) => res);
  },
  logout: (userId) => {
    return axios.post(`${API_URL}/v1/auth/logout/${userId}`).then((res) => res);
  },
  verification: (email, otp) => {
    return axios
      .post(`${API_URL}/v1/auth/verification`, {
        email,
        otp,
      })
      .then((res) => res);
  },
  forgotPassword: (email) => {
    return axios
      .post(`${API_URL}/v1/auth/forgotPassword`, {
        email,
      })
      .then((res) => res);
  },
  createPassword: (email, password) => {
    return axios
      .post(`${API_URL}/v1/auth/createNewPassword`, {
        email,
        password,
      })
      .then((res) => res);
  },
  signedUrl: (fileName, fileType) => {
    return axios
      .post(`${API_URL}/v1/auth/uploadImage`, {
        fileName,
        fileType,
      })
      .then((res) => res);
  },
  uploadImage: (signedUrl, file) => {
    const options = {
      headers: {
        "Content-Type": file.type,
      },
    };
    return axios.put(signedUrl, file, options).then((res) => res);
  },
  updateUser: (userId, image, firstName, lastName, bio, country) => {
    const data = {
      image,
      firstName,
      lastName,
      bio,
      country,
    };
    return reqInstance
      .post(`${API_URL}/v1/auth/update-profile/${userId}`, data)
      .then((res) => res);
  },
  updatePassword: (email, password, newPassword) => {
    return reqInstance
      .post(`${API_URL}/v1/auth/update-password`, {
        email,
        password,
        newPassword,
      })
      .then((res) => res);
  },
  getAllUser: () => {
    return axios.get(`${API_URL}/v1/auth/getAllUsers`).then((res) => res);
  },
  getSingleUser: (userId) => {
    return axios
      .get(`${API_URL}/v1/auth/getSingleUser/${userId}`)
      .then((res) => res);
  },
  getWallet: (userId) => {
    return reqInstance
      .get(`${API_URL}/v1/auth/getWallet/${userId}`)
      .then((res) => res);
  },
  blockUser: (userId) => {
    return reqInstance
      .post(`${API_URL}/v1/auth/blockUser/${userId}`)
      .then((res) => res);
  },
  unBlockUser: (userId) => {
    return reqInstance
      .post(`${API_URL}/v1/auth/unBlockUser/${userId}`)
      .then((res) => res);
  },
  generatePhoneOtp: (userId, number) => {
    return axios
      .post(
        `${API_URL}/v1/auth/phoneOtp/${userId}`,
        {
          number,
        },
      )
      .then((res) => res);
  },
  phoneOtpVerification: (userId, otp, number) => {
    return axios
      .post(
        `${API_URL}/v1/auth/verifyPhoneOtp/${userId}`,
        {
          otp,
          number,
        },
      )
      .then((res) => res);
  },
  changeAuthenticatorStatus: (email, status) => {
    return axios
      .post(`${API_URL}/v1/auth/authenticator`, {
        email,
        status,
      })
      .then((res) => res);
  },
  recoveryKey: (email) => {
    return axios
      .post(`${API_URL}/v1/auth/authenticator/revovery`, {
        email,
      })
      .then((res) => res);
  },
  withdrawCoins: (email, token) => {
    return reqInstance
      .post(
        `${API_URL}/v1/auth/withdraw`,
        {
          email,
          token,
        },
      )
      .then((res) => res);
  },
  getUserByEmail: (email, token) => {
    return axios
      .post(
        `${API_URL}/v1/auth/getUserByEmail`,
        {
          email,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then((res) => res);
  },
  contactUs: (name, email, phone, message) => {
    return axios
      .post(`${API_URL}/v1/contact/contactUs`, {
        name,
        email,
        phone,
        message,
      })
      .then((res) => res);
  },
};

export default Auth;
