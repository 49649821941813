import React from "react";

const ServicesOffered = ({ index, icon, heading, detail }) => {
  return (
    <div
      key={index}
      className="w-full sm:w-1/2 md:w-1/3 flex flex-col justify-start items-center text-white gap-2 p-5"
    >
      <img className="w-11 h-11" src={icon} alt="img" />
      <p className="text-2xl text-center font-bold">{heading}</p>
      <p className="w-11/12 text-center">{detail}</p>
    </div>
  );
};

export default ServicesOffered;
