import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoinsTable from "../components/common/CoinsTable";
import HowToBuy from "../components/common/HowToBuy";
import Page from "../components/common/Page";
import Trade from "../components/screens/Trade";
import { Offer } from "../services";
import { getOffer } from "../redux/offer/action";

const BuyUSDT = () => {
  const dispatch = useDispatch();

  // const [currentPage, setCurrentPage] = useState(1);
  // const [postsPerPage, setPostsPerPage] = useState(5);

  const [buyer, setBuyer] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seller, setSeller] = useState([]);

  const [pakistaniBuyer, setPakistaniBuyer] = useState([]);
  const offer = useSelector((state) => state.offer.offer);
  const buyerData = offer.filter((item, index) => !item.isBuy);

  const user = useSelector((state) => state.user);
  const { country } = user;

  useEffect(() => {
    Offer.getAllOffer().then((res) => {
      dispatch(getOffer(res.data));
    });
    // .catch((e) => console.log("e", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setBuyer(buyerData);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (country?.length) {
      const data = buyer.filter(
        (item, index) => (item?.country).toLowerCase() === country.toLowerCase()
      );
      setPakistaniBuyer(data);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [buyer]);

  return (
    <Page title="P2Pways – Buy USDT">
      <div className="mx-5 lg:mx-10">
        <Trade page="buy" setBuyer={setBuyer} setSeller={setSeller} />

        {country?.length && (
          <CoinsTable
            heading={`Buy USDT online in ${country}
          `}
            isSell={false}
            data={pakistaniBuyer}
            // currentPage={currentPage}
            // setCurrentPage={setCurrentPage}
            // postsPerPage={postsPerPage}
            // setPostsPerPage={setPostsPerPage}
          />
        )}
        <CoinsTable
          heading="Buy USDT online in WorldWide"
          isSell={false}
          data={buyer}
          // currentPage={currentPage}
          // setCurrentPage={setCurrentPage}
          // postsPerPage={postsPerPage}
          // setPostsPerPage={setPostsPerPage}
        />
      </div>
      <HowToBuy header="How to Buy USDT on P2Pways" />
    </Page>
  );
};

export default BuyUSDT;
