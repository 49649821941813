import React, { useEffect } from "react";
import Footer from "../Footer";
import Navbar from "../Navbar";
import MetaTags from "react-meta-tags";

const Page = ({ title, children, onUpdateCoin = false }) => {
  useEffect(() => {
    //  👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="bg-[#10192F]">
      <MetaTags>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta
          name="description"
          content="P2Pways - Trade USDT person-to-person in an Easy, Fast, and Secure way"
        />
      </MetaTags>
      <main>
        <Navbar onUpdateCoin={onUpdateCoin} />
        <div className="min-h-[100vh]">{children}</div>
        <Footer />
      </main>
    </div>
  );
};

export default Page;
