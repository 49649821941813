export const sellData = [
    {
        heading: '1-Set your requirements ',
        detail: 'Choose your preferred payment method and the maximum amount of USDT you’re willing to sell. Once you’re done, click Search For Offers. You will see a list of relevant offers to choose from.'
    },
    {
        heading: '2-Review offers',
        detail: 'Before selecting an offer, be sure to check all vital information about the buyer, including but not limited to their name, reputation, verification level, and rate per USDT. Once you’ve found a suitable offer, click Sell. It won’t open a trade yet, but will guide you through the offer terms set by the buyer.'
    },
    {
        heading: '3-Start the trade',
        detail: 'If you are satisfied with the buyer’s terms, enter the amount you’re willing to trade for and click Sell Now. This will open a live trade chat and move your USDT to our secured escrow. Read the instructions provided carefully, and follow them. Once your buyer completes their end of the trade and you receive the payment, you can release the USDT.'
    },
    {
        heading: '4-Leave feedback',
        detail: 'After successfully selling your USDT, don’t forget to give your trade partner feedback. This is important for our platform as it helps build a user’s reputation.'
    },
]