import React from "react";
import Modal from "react-modal";
import { Chat } from "../../../services";

const ConfirmationModal = ({ modalIsOpen, setIsOpen, group, user }) => {

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const deleteChat = () => {
    Chat.deleteChat(group, user)
      .then((res) => {
        if (res.status === 200) {
          window.location.reload(false);
        }
      })
      .catch((e) => console.log("e", e));
    closeModal();
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          width: "max-content",
          minHeight: "max-content",
          transform: "translate(-50%, -50%)",
          border: "1px solid #ccc",
          background: "#fff",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          borderRadius: "14px",
          outline: "none",
        },
      }}
    >
      <div className="App flex flex-col gap-2">
        <h1 className="text-center">Are you sure to delete</h1>
        <div className="flex justify-center gap-20 mt-3">
          <button className={"px-5 py-1 rounded border-2"} onClick={closeModal}>
            No
          </button>
          <button
            className={"px-5 py-1 rounded bg-red-400"}
            onClick={() => deleteChat()}
          >
            Yes
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmationModal;
