import React from 'react'

const Auth = ({ children }) => {
  return (
    <div className='flex-1 flex flex-row justify-between min-h-screen'>
        <div className='w-1/2 lg:w-3/5 hide md:flex md:flex-col md:justify-center md:items-center p-4 bg-white'>
            <img className='w-full max-w-[700px] max-h-[550px]' src='/images/auth.png' alt='img' />
        </div>
        <div className='w-full md:w-1/2 lg:w-2/5 bg-auth'>
            { children }
        </div>
    </div>
  )
}

export default Auth