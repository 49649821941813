import { binance_provider } from "../lib/config";

const Web3 = require("web3");
// const web3 = new Web3(new Web3.providers.HttpProvider(quick_node_provider));

const web3 = new Web3(new Web3.providers.HttpProvider(binance_provider));

export const getBnbBalance = async (address) => {
  const balance = await web3.eth.getBalance(address);
  const calculatedBNB = web3.utils.fromWei(balance, "ether");
  return calculatedBNB;
};
