import React from "react";
import ActiveOffers from "./ActiveOffers";
import Feedback from "./Feedback";
import History from "./History";

const ProfileRightBlock = ({ user }) => {
  return (
    <div className="flex flex-col gap-8">
      <ActiveOffers user={user} />
      <History user={user} />
      <Feedback user={user} />
    </div>
  );
};

export default ProfileRightBlock;
