import React from "react";

const ProfileBlock = ({ children }) => {
  return (
    <div className="w-full rounded-[10px] border border-[#78FFD6]">
     {children}
    </div>
  );
};

export default ProfileBlock;
