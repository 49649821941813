export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";
export const REGISTER = "REGISTER";
export const ADDRESS = 'ADDRESS'
export const UPDATE = 'UPDATE'
export const REFRESH = 'REFRESH'
export const IS_HIDE = 'IS_HIDE'
export const IS_HIDE_BNB = 'IS_HIDE_BNB'
export const PHONE_VERIFICATION = 'PHONE_VERIFICATION'
export const AUTHENTICATOR_STATUS = 'AUTHENTICATOR_STATUS'
