import React from "react";

const ServicesBlocks = ({ index, icon, heading, text }) => {
  return (
    <div key={index} className="w-80 flex flex-row justify-center items-center gap-3 rounded-[20px] border-2 mb-20 border-[#78FFD6] py-2 bg-blocks">
      <img src={icon} alt="img" />
      <div>
        <p className="text-[#78FFD6] text-3xl">{heading}</p>
        <p className="text-white">{text}</p>
      </div>
    </div>
  );
};

export default ServicesBlocks;
