import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Page from "../components/common/Page";
import {
  MainSection,
  Services,
  Vendor,
  Ways,
  HappyClients,
} from "../components/screens/Landing";
import { getOffer } from "../redux/offer/action";
import { address } from "../redux/user/action";
import { Offer, UserAuth } from "../services";

const Landing = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    UserAuth.getWallet(user.id, user.token)
      .then((res) => {
        dispatch(address(res.data.address));
      })
      // .catch((e) => console.log("e"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Offer.getAllOffer()
      .then((res) => {
        dispatch(getOffer(res.data));
      })
      // .catch((e) => console.log("e", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  return (
    <Page title="P2Pways – Trade USDT person-to-person in an Easy, Fast, and Secure way">
      <div className="bg-[#10192F] px-10">
        <MainSection />
        <Services />
        <Vendor />
        <Ways />
        <HappyClients />
      </div>
    </Page>
  );
};

export default Landing;
