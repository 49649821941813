import React from "react";
import Router from "./router";
import CookieConsent from "react-cookie-consent";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./redux";

function App() {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router />
          <CookieConsent
            location="bottom"
            buttonText="ok!"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            This website uses cookies to enhance the user experience.{" "}
          </CookieConsent>
        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
