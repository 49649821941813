import React, { useState, useEffect } from "react";

import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";

import { UserReviews } from "../../../services";
import UserDetail from "../../common/ClientFeedback/UserDetail";

const tabs = ["Buyers", "Sellers"];
const options = ["All", "Positive", "Negative"];


const Feedback = ({ user }) => {
  const [selected, setSelected] = useState(0);
  const [option, setOption] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [filterdReviews, setFilteredReviews] = useState([]);


useEffect(() => {
  UserReviews.getAllReview(user.id)
  .then((res) => {
    if (res.status === 200) {
      setReviews(res.data.reviews);
      setFilteredReviews(res.data.reviews);
    }
  })
  // .catch((e) => console.log('e', e))
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

const handleProperties = (index) => {
  setOption(index);
  if (index === 0) {
    setFilteredReviews(reviews);
  }
  if (index === 1) {
    const positive = reviews.filter((review) => review.isPositive);
    setFilteredReviews(positive);
  }
  if (index === 2) {
    const negative = reviews.filter((review) => !review.isPositive);
    setFilteredReviews(negative);
  }
};

const handleTab = (index) => {
  setSelected(index);
  if (index === 0) {
    const buyer = reviews.filter((review) => review.isBuyer);
    setFilteredReviews(buyer);
  }
  if (index === 1) {
    const seller = reviews.filter((review) => !review.isBuyer);
    setFilteredReviews(seller);
  }
};

  return (
    <div>
      <div>
        <ProfileBlock>
        <Header>
            <div className="flex flex-row justify-between items-center sm:mx-5">
              <div className="flex flex-row items-center gap-6 sm:gap-14">
                {tabs.map((tab, index) => (
                  <p
                    className={
                      selected === index
                        ? "text-[#6DDABD] border-b-2 border-[#6DDABD] font-bold leading-9 cursor-pointer"
                        : "text-white leading-9 cursor-pointer"
                    }
                    onClick={() => handleTab(index)}
                  >
                    {tab}
                  </p>
                ))}
              </div>
              <div className="hide sm:flex flex-row items-center gap-3 sm:gap-8">
                {options.map((item, index) => (
                  <p
                    className={
                      option === index
                        ? "text-[#6DDABD] font-bold cursor-pointer"
                        : "text-white cursor-pointer"
                    }
                    onClick={() => handleProperties(index)}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </Header>
          <Body>
            <div className="max-h-[300px] overflow-auto scrollbar-hide">
              {!filterdReviews.length ? (
                <div className="flex flex-col justify-center items-center gap-2 py-3">
                  <p className="text-white">
                    This user don,t have any Reviews
                  </p>
                  <img src="/images/profile/empty.png" alt="img" />
                </div>
              ) : (
                <div>
                  {filterdReviews.map((review, index) => (
                    <div className="flex flex-col sm:flex-row items-start gap-5 mb-6 border-b py-5">
                      <UserDetail review={review} />
                      <p className="text-white">{review.comment}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Body>
        </ProfileBlock>
      </div>
    </div>
  );
};

export default Feedback;
