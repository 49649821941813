import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { io } from "socket.io-client";
import { API_URL } from "../../../lib/config";
import Header from "../../common/ProfileBlock/header";
import Picker from "emoji-picker-react";
import UserChat from "../../../services/chat";
import { BsEmojiSmile } from "react-icons/bs";
import { MdCancel } from "react-icons/md";

const socket = io.connect(API_URL);

socket.on("CONNECT", () => {
  console.log(`I'm connected with the back-end`);
});

const Chat = ({ user, senderProfile, data }) => {
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);

  const dummy = useRef(null);

  const userLastSeen = moment(user.lastSeen).fromNow();
  const senderLastSeen = moment(senderProfile.lastSeen).fromNow();

  const loginUser = useSelector((state) => state.user);

  const onEmojiClick = (event, { emoji }) => {
    console.log({ event, emoji });
    setMessage(message + emoji);
  };

  const handleSend = (event) => {
    event.preventDefault();
    socket.emit("chat", {
      message,
      senderId: loginUser.id,
      receiverId: loginUser.id === data.from ? data.to : data.from,
    });
    setMessage("");
    setShowEmoji(false);
  };

  useEffect(() => {
    UserChat.getChat(
      loginUser.id,
      loginUser.id === data.from ? data.to : data.from
    ).then((res) => {
      if (res.status === 200) {
        setChat(res.data);
      }
    });
    // .catch((e) => console.log("e.", e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    socket.on("chat", (payload) => {
      // console.log("payload", payload);
      setChat([...chat, payload]);
    });
  }, [chat]);

  return (
    <div className="flex flex-col gap-2">
      <p className="text-white text-xl">Chat</p>
      <div className="w-full min-h-[440px] border relative border-[#78FFD6] bg-[#10192F]">
        <Header>
          {(loginUser.id === data.from && !data.isBuy) ||
          (loginUser.id === data.to && data.isBuy) ? (
            <div className="flex flex-row justify-between items-center py-2">
              <div className="flex flex-row items-center gap-2">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user.image ? `${user.image}` : "/images/user_profile.png"
                  }
                  alt="img"
                />
                <div>
                  <p className="text-base text-[#78FFD6]">
                    {user.firstName} {user.lastName}
                  </p>
                  <p
                    className={
                      user.isOnline
                        ? "text-sm text-[#78FFD6]"
                        : "text-sm text-white text-[12px]"
                    }
                  >
                    {user.isOnline ? "online" : `seen ${userLastSeen}`}
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex flex-row justify-between items-center py-2">
              <div className="flex flex-row items-center gap-2">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    senderProfile.image
                      ? `${senderProfile.image}`
                      : "/images/user_profile.png"
                  }
                  alt="img"
                />
                <div>
                  <p className="text-base text-[#78FFD6]">
                    {senderProfile.firstName} {senderProfile.lastName}
                  </p>
                  <p
                    className={
                      senderProfile.isOnline
                        ? "text-sm text-[#78FFD6]"
                        : "text-sm text-white text-[12px]"
                    }
                  >
                    {senderProfile.isOnline
                      ? "online"
                      : `seen ${senderLastSeen}`}
                  </p>
                </div>
              </div>
            </div>
          )}
        </Header>
        <div className="h-[300px] overflow-auto scrollbar-hide flex flex-col gap-2 p-3">
          {chat.length ? (
            chat.map((payload, index) => (
              <div
                key={index}
                className={
                  loginUser.id === payload.senderId
                    ? "flex flex-row justify-end"
                    : "flex flex-row justify-start"
                }
              >
                <div
                  className={
                    loginUser.id === payload.senderId
                      ? `max-w-[60%] flex flex-row justify-end px-4 py-2 rounded-md bg-purple-600`
                      : `max-w-[60%] px-4 py-2 rounded-md bg-gray-300`
                  }
                >
                  <p
                    className={
                      loginUser.id === payload.senderId
                        ? `text-white`
                        : `text-black`
                    }
                  >
                    {payload.message}
                  </p>
                  <div ref={dummy} />
                </div>
              </div>
            ))
          ) : (
            <p className="text-white text-center">No chat </p>
          )}
        </div>
        <form onSubmit={handleSend}>
          <div className="w-full flex flex-row justify-center absolute bottom-5">
            <div className="w-[95%] flex flex-row gap-3 p-2 border rounded ">
              <input
                className="w-4/5 bg-transparent outline-none text-white"
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              {showEmoji && (
                <div className="emoji-picker">
                  <Picker onEmojiClick={onEmojiClick} />
                </div>
              )}
              <div className=" flex flex-row items-center gap-2 sm:gap-4">
                {!showEmoji ? (
                  <BsEmojiSmile
                    style={{
                      color: "white",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowEmoji(true)}
                  />
                ) : (
                  <MdCancel
                    style={{
                      color: "white",
                      width: "20px",
                      height: "20px",
                      cursor: "pointer",
                    }}
                    onClick={() => setShowEmoji(false)}
                  />
                )}
                <button
                  className="px-6 py-1 rounded bg-white"
                  type="submit"
                  disabled={!message.length}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      handleSend();
                      e.preventDefault();
                    }
                  }}
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Chat;
