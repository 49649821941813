import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Auth from "../../common/Auth";
import { Formik } from "formik";
import * as yup from "yup";
import InputField from "../../common/InputField";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { login, register } from "../../../redux/user/action";
import { AiOutlineArrowLeft } from "react-icons/ai";

const LogIn = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [state, setState] = useState(true);
  const [isLoading, setIsLoading] = useState(false);

  const loginSchema = yup.object().shape({
    Email: yup.string().required("Email is required"),
    Password: yup.string().required("Password is required"),
  });

  useEffect(() => {
    if (user.id) {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBack = () => navigate("/");

  return (
    <Auth>
      <div className="flex flex-col justify-center p-5 h-full">
        <AiOutlineArrowLeft
          onClick={handleBack}
          style={{
            color: "black",
            width: "50px",
            cursor: "pointer",
          }}
        />
        <Formik
          validateOnChange={false}
          initialValues={{
            Email: "",
            Password: "",
          }}
          onSubmit={(values) => {
            setIsLoading(true);
            UserAuth.login(values.Email, values.Password)
              .then((res) => {
                if (res.status === 200) {
                  // console.log("login", res);
                  if (res?.data?.userDocs?.isAuthenticatorEnable) {
                    localStorage.setItem("qr", res?.data?.userDocs?.qr);
                    localStorage.setItem("email", values.Email);
                    localStorage.setItem("token", res.data.token);
                    window.location.href = "/authenticator";
                  } else {
                    dispatch(login(res.data.userDocs, res.data.token));
                    toast.success("Login successfully.");
                    navigate("/");
                  }

                  //
                } else {
                  toast.error(res.data.message);
                }
              })
              .catch((e) => {
                if (e.response.data.message === "Please verify your email") {
                  toast.error("Please verify your email first");
                  dispatch(register(values.Email));
                  localStorage.setItem("qr", e?.response?.data?.userDocs?.qr);
                  localStorage.setItem("address", e?.response?.data?.userDocs?.address);
                  navigate("/verification");
                } else {
                  toast.error(e.response.data.message);
                }
              })
              .finally(() => setIsLoading(false));
          }}
          validationSchema={loginSchema}
        >
          {({ handleChange, handleSubmit, ...props }) => (
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col justify-evenly items-center h-5/6 mx-0 sm:mx-5 lg:mx-10">
                <p className="font-bold text-xl mt-5 mb-28 text-blue-600">
                  Log in With P2Pways
                </p>

                <div className="w-full max-w-[500px]">
                  <div className="flex flex-col gap-3 w-full">
                    <InputField
                      type="Email"
                      placeholder="Email"
                      value={props.values.Email}
                      onChange={handleChange("Email")}
                      isInvalid={props.touched.Email && props.errors.Email}
                      error={props.errors.Email}
                    />
                    <InputField
                      type={state ? "password" : "text"}
                      placeholder="Password"
                      value={props.values.Password}
                      onChange={handleChange("Password")}
                      isInvalid={
                        props.touched.Password && props.errors.Password
                      }
                      error={props.errors.Password}
                      isPassword={true}
                      state={state}
                      setState={setState}
                    />
                  </div>
                  <Link to="/forgot-password">
                    <p className="text-right text-sm cursor-pointer -mt-3">
                      Forgot Password
                    </p>
                  </Link>
                </div>
                <div>
                  <input
                    type="submit"
                    className="flex flex-row items-center text-black rounded-lg gap-3 mt-20 px-20 py-2 cursor-pointer bg-[#78FFD6] disabled:bg-slate-400 disabled:cursor-not-allowed"
                    disabled={isLoading}
                    value="Log In"
                  />
                  {/* <SearchButton text="Log In" onClick={handleSubmit} /> */}
                  <p className="text-[#828282] text-center mt-1">
                    New to P2Pways?{" "}
                    <Link to="/signup">
                      <span className="text-blue-600"> Sign Up</span>
                    </Link>
                  </p>
                </div>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

export default LogIn;
