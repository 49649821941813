import React from 'react'

const LandingHeader = ({ heading, detail }) => {
  return (
    <div className='flex flex-col items-center gap-3'>
        <p className='text-2xl sm:text-4xl text-center font-bold text-[#78FFD6]'>{heading}</p>
        <p className='md:w-4/5 text-white text-center'>{detail}</p>
    </div>
  )
}

export default LandingHeader