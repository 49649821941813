import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Page from "../components/common/Page";
import LeftBlock from "../components/screens/Profile/LeftBlock";
import RightBlock from "../components/screens/Profile/RightBlock";
import { MdOutlineContentCopy } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const navigate = useNavigate()
  // eslint-disable-next-line no-unused-vars
  const [copy, setCopy] = useState(false);

  const user = useSelector((state) => state.user);
  const name = user.firstName + " " +user.lastName;

  const clipboardText = (user) => {
    navigator.clipboard.writeText(user);
    setCopy(true);
  };

  useEffect(() => {
    if(!user.id){
      navigate('/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Page title="P2Pways – Profile">
      <div className="flex-1 min-h-screen px-5">
        <div className="container">
          {/* profile */}
          <div className="flex flex-row items-center flex-wrap sm:flex-nowrap gap-5 pt-14">
            <div className="min-w-full flex justify-start sm:min-w-[120px] md:min-w-[220px]">
              <img
                className="w-[120px] h-[120px] md:w-[220px] md:h-[220px] rounded-full border border-[#78FFD6]"
                src={
                  user.image
                    ? `${user.image}`
                    : "/images/user_profile.png"
                }
                alt="img"
              />
            </div>
            <div>
              <p className="text-2xl text-[#78FFD6] font-bold">
                {user.firstName ? name.toUpperCase() : "User Name"}
              </p>
              <p className="text-white">
                {user.bio
                  ? user.bio
                  : `Your message in Bio`}
              </p>
            </div>
          </div>
          {/* user */}
          <div className="flex flex-row justify-center mt-10">
            <div className="flex flex-col p-5 bg-[#404759] rounded-md">
              <div className="flex flex-row justify-between items-center bg-[#10192F]">
                <p className="w-full flex flex-row bg-white text-white text-ellipsis overflow-hidden p-2">
                  <span className="w-[200px] sm:w-full truncate sm:block text-black">
                    {" "}
                    {user.address}
                  </span>
                </p>
                <div
                  className="flex justify-center items-center text-[#3285FF] cursor-pointer p-3"
                  onClick={() => clipboardText(user.address)}
                >
                  <MdOutlineContentCopy style={{ color: "white" }} />
                </div>
              </div>
              <p className="text-white text-[14px] my-1">
                Copy this address and paste in your wallet to deposit coins
              </p>
              <p className="text-[#78FFD6]">
                Network:{" "}
                <span className="text-white"> BNB smart chain (BEP20)</span>
              </p>
            </div>
          </div>
          {/* detail */}
          <div className="flex flex-col md:flex-row gap-8 py-20">
            <div className="w-full sm:w-[350px]">
              <LeftBlock />
            </div>
            <div className="w-full">
              <RightBlock />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Profile;
