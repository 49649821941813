import React from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import BuyUSDT from "../pages/BuyUSDT";
import SellUSDT from "../pages/SellUSDT";
import LogIn from "../components/screens/LogIn";
import SignUp from "../components/screens/SignUp";
import VerifyOTP from "../components/screens/VerifyOTP";
import Landing from "../pages/Landing";
import Profile from "../pages/Profile";
import Offer from "../pages/Offer";
import CreateOfferPage from "../pages/CreateOfferPage";
import AccountSetting from "../pages/AccountSetting";
import AccountSecurity from "../pages/AccountSecurity";
import Transaction from "../components/screens/Transaction";
import AllUsers from "../pages/AllUsers";
import ViewProfile from "../pages/ViewProfile";
import Withdraw from "../pages/Withdraw";
import ForgotPassword from "../components/screens/ForgotPassword";
import ForgotPasswordOtp from "../components/screens/ForgotPasswordOtp.js";
import NewPassword from "../components/screens/NewPassword";
import Inbox from "../pages/Inbox";
import VerifyAuthenticator from "../components/screens/VerifyAuthenticator";
import ContactUs from "../pages/ContactUs";
import EditOffer from "../components/screens/Profile/EditOffer";
import Terms from "../pages/Terms";

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
            <Route path="/" element={<Landing />}></Route>
            <Route path="/buy-usdt" element={<BuyUSDT />}></Route>
            <Route path="/sell-usdt" element={<SellUSDT />}></Route>
            <Route path="/profile" element={<Profile />}></Route>
            <Route path="/offer" element={<Offer />}></Route>
            <Route path="/offer/:id" element={<EditOffer />}></Route>
            <Route path="/inbox" element={<Inbox />}></Route>
            <Route path="/withdraw" element={<Withdraw />}></Route>
            <Route path="/all-users" element={<AllUsers />}></Route>
            <Route path="/view-profile" element={<ViewProfile />}></Route>
            <Route path="/transaction/:id" element={<Transaction />}></Route>
            <Route path="/create-offer" element={<CreateOfferPage />}></Route>
            <Route path="/account-setting" element={<AccountSetting />}></Route>
            <Route path="/terms-conditions" element={<Terms />} />
            <Route
              path="/account-security"
              element={<AccountSecurity />}
            ></Route>
            <Route path="/contact-us" element={<ContactUs />}></Route>
       
            <Route path="/login" element={<LogIn />}></Route>authenticator
            <Route
              path="/authenticator"
              element={<VerifyAuthenticator />}
            ></Route>
            <Route path="/signup" element={<SignUp />}></Route>
            <Route path="/forgot-password" element={<ForgotPassword />}></Route>
            <Route
              path="/forgot-password-otp"
              element={<ForgotPasswordOtp />}
            ></Route>
            <Route path="/verification" element={<VerifyOTP />}></Route>
            <Route path="/new-password" element={<NewPassword />}></Route>
            <Route path="*" element={<Navigate to="/" replace />} />
      
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
