import React, { useState, useMemo, useEffect } from "react";
import { FiEdit2 } from "react-icons/fi";
import Page from "../components/common/Page";
import AccountInput from "../components/screens/Account/AccountInput";
import TextArea from "../components/screens/Account/TextArea";
import Modal from "react-modal";
import { FileUploader } from "react-drag-drop-files";
import { useSelector, useDispatch } from "react-redux";
import { Formik } from "formik";
import * as yup from "yup";
import { UserAuth } from "../services";
import { updateUser } from "../redux/user/action";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import countryList from "react-select-country-list";
import Auth from "../services/auth";

const AccountSetting = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const user = useSelector((state) => state.user);

  const [country, setCountry] = useState({
    value: user.country ? user.country : "",
    label: user.country ? user.country : "",
  });
  const options = useMemo(() => countryList().getData(), []);

  const [imageError, setImageError] = useState("");

  const [modalIsOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState(user.image ? user.image : null);
  const [image, setImage] = useState(null);
  const [url, setUrl] = useState(user.image ? user.image : "");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [imageStatus, setImageStatus] = useState(user.image ? true : false);

  useEffect(() => {
    if (!user.id) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChange = (file) => {
    setFile(URL.createObjectURL(file));
    setImage(file);

    setLoading(true);
    Auth.signedUrl(file.name, file.type)
      .then((res) => {
        if (res.status === 200) {
          handleUpload(res.data.signedurl, res.data.url, file);
        }
      })
      .catch((e) => {
        console.log("e", e);
        toast.error(e.response.data.message);
        setLoading(false);
      });
  };

  const handleUpload = (signedurl, imageUrl, file) => {
    Auth.uploadImage(signedurl, file)
      .then((res) => {
        console.log("res", res);
        if (res.status === 200) {
          setImageStatus(true);
          setUrl(imageUrl);
        }
      })
      .catch((e) => {
        console.log("e", e);
        toast.error("Error in uploading image");
        setImageStatus(false);
        setLoading(false);
        setFile(null);
        setImage(null);
      })
      .finally(() => setLoading(false));
  };

  function openModal() {
    setIsOpen(true);
  }

  const changeHandler = (value) => {
    setCountry(value);
  };

  const UploadModal = () => {
    const fileTypes = ["JPEG", "PNG", "GIF"];

    function afterOpenModal() {
      // references are now sync'd and can be accessed.
    }

    function closeModal() {
      setIsOpen(false);
    }
    return (
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={{
          overlay: {
            position: "fixed",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(0, 0, 0, 0.5)",
          },
          content: {
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "max-content",
            minHeight: "max-content",
            transform: "translate(-50%, -50%)",
            border: "1px solid #ccc",
            background: "#fff",
            overflow: "auto",
            WebkitOverflowScrolling: "touch",
            borderRadius: "14px",
            outline: "none",
          },
        }}
      >
        <div className="App flex flex-col gap-2">
          <h1 className="text-center">Drag & Drop Files here</h1>
          <FileUploader
            handleChange={(e) => handleChange(e)}
            name="file"
            types={fileTypes}
          />
          <p>
            {image !== null
              ? `File name: ${image.name}`
              : "no files uploaded yet"}
          </p>
          {file && (
            <div className="flex justify-center mt-3">
              <button
                className={
                  "px-10 py-3 rounded bg-green-500 cursor-pointer text-white hover:bg-green-600"
                }
                disabled={file === null && image === null}
                onClick={closeModal}
              >
                Upload
              </button>
            </div>
          )}
        </div>
      </Modal>
    );
  };

  const styles = {
    valueContainer: (base, state) => {
      const width = "280px";
      const height = "50px";
      const color = "gray";
      const borderColor = "none";
      return { ...base, width, height, color, borderColor };
    },
  };

  const account = yup.object().shape({
    FirstName: yup.string().required("First Name is required"),
    LastName: yup.string().required("Last Name is required"),
    Bio: yup.string().required("Bio is required"),
  });

  return (
    <Page title="P2Pways – Account Setting">
      <Formik
        validateOnChange={false}
        initialValues={{
          FirstName: user.firstName ? user.firstName : "",
          LastName: user.lastName ? user.lastName : "",
          Bio: user.bio ? user.bio : "",
          // Country: user.country ? user.country : "",
        }}
        onSubmit={(values) => {
          if (file === null) return setImageError("Profile picture is reuired");
          if (country === "undefined") return setError("Country is required");
          setImageError("");
          setError("");
          setLoading(true)
          UserAuth.updateUser(
            user.id,
            url,
            values.FirstName,
            values.LastName,
            values.Bio,
            country.label
          )
            .then((res) => {
              if (res.status === 200) {
                dispatch(updateUser(res.data));
                toast.success("Profile updated successfully.");
                setFile(null);
                setImage(null);
                navigate("/");
              }
            })
            .catch((e) => {
              toast.error(e.response.message);
              toast.error(e.response.data.message);
            })
            .finally(() => setLoading(false));
        }}
        validationSchema={account}
      >
        {({ handleChange, handleSubmit, ...props }) => (
          <>
            <UploadModal />
            <div className="flex justify-center items-center min-h-[90vh] my-10">
              <div className="flex flex-col gap-2">
                <p className="text-3xl text-[#78FFD6] px-2">Account Setting</p>
                <div className="w-full md:w-[700px] bg-[#404759] rounded">
                  {/* header */}
                  <div className="border-b border-[#EBEFFF]">
                    <div className="">
                      <div className="flex flex-col items-start sm:flex-row sm:items-center px-5 py-2 gap-2">
                        <div className="min-w-24 min-h-24 max-w-24 max-h-24 rounded-full shadow-sm relative">
                          <img
                            className="w-16 h-16 sm:w-24 sm:h-24 rounded-full border border-[#78FFD6] object-cover"
                            src={
                              user.image && file === null
                                ? `${user.image}`
                                : file === null && image === null
                                ? "/images/user_profile.png"
                                : file
                            }
                            alt="img"
                          />
                          {loading && (
                            <img
                              className="w-10 h-10 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                              src="/images/image-loader.gif"
                              alt="profile"
                            />
                          )}
                          <div
                            className="flex justify-center items-center p-1 rounded-full bg-[#ED5252] cursor-pointer absolute bottom-0 right-0"
                            onClick={openModal}
                          >
                            <FiEdit2 style={{ color: "white" }} />
                          </div>
                        </div>
                        <div className="">
                          <p className="text-xl sm:text-2xl text-white">
                            {user.firstName
                              ? user.firstName.toUpperCase()
                              : "User"}{" "}
                            {user.lastName
                              ? user.lastName.toUpperCase()
                              : "Name"}
                          </p>
                          <p className="text-[14px] sm:text-[16px] text-[#68768B]">
                            {user.id ? user.email : "user@gmail.com"}
                          </p>
                        </div>
                      </div>
                      {props.touched.File && props.errors.File && (
                        <p className="text-[#FF0000] text-[12px]">
                          {props.error.File}
                        </p>
                      )}
                      <div className="px-5">
                        <p className=" text-[#FF0000] text-[12px]">
                          {imageError ? imageError : ""}
                        </p>
                        <p className="text-base text-white">
                          Personal Information
                        </p>
                      </div>
                    </div>
                  </div>
                  {/* body */}
                  <div className="border-b border-[#EBEFFF]">
                    <div className="flex flex-col gap-3 p-5">
                      <div className="flex justify-between gap-8 sm:max-w-[320px]">
                        <AccountInput
                          label="First Name"
                          type="text"
                          placeholder="Enter First Name"
                          value={props.values.FirstName}
                          onChange={handleChange("FirstName")}
                          error={props.errors.FirstName}
                          isInvalid={
                            props.touched.FirstName && props.errors.FirstName
                          }
                        />
                      </div>
                      <div className="flex justify-between gap-8 sm:max-w-[320px]">
                        <AccountInput
                          label="Last Name"
                          type="text"
                          placeholder="Enter Last Name"
                          value={props.values.LastName}
                          onChange={handleChange("LastName")}
                          error={props.errors.LastName}
                          isInvalid={
                            props.touched.LastName && props.errors.LastName
                          }
                        />
                      </div>
                      <div className="country-body flex flex-col max-w-[320px] h-[80px]">
                        <p className="text-[#78FFD6]">Country</p>
                        <Select
                          styles={styles}
                          options={options}
                          value={country}
                          onChange={changeHandler}
                        />
                        <p className="text-[#FF0000] text-[12px]">{error}</p>
                      </div>
                      <div>
                        <TextArea
                          label="Bio"
                          type="text"
                          value={props.values.Bio}
                          onChange={handleChange("Bio")}
                          error={props.errors.Bio}
                          isInvalid={props.touched.Bio && props.errors.Bio}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row justify-end gap-5 p-5">
                    <button
                      className="px-5 py-2 border rounded-lg text-white disabled:bg-slate-400 disabled:cursor-not-allowed"
                      onClick={handleSubmit}
                      disabled={loading || !imageStatus}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </Formik>
    </Page>
  );
};

export default AccountSetting;
