import React, { useState, useEffect } from "react";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { BsStarHalf } from "react-icons/bs";
import { UserReviews } from "../../../services";

const Reviews = ({ reviews }) => {
  const [rating, setRating] = useState(0);
  const totalStars = 5;

  useEffect(() => {
    UserReviews.getAllReview(reviews._id)
      .then((res) => {
        setRating(res.data.rating);
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

  return (
    <div className="flex flex-row items-center gap-1">
     <div className="hide md:flex flex-row items-center gap-1">
      {[...new Array(totalStars)].map((arr, index) => {
        return index + 1 <= rating ? (
          <AiFillStar key={index} style={{ color: "yellow" }} />
        ) : rating > index && rating < index + 1 ? (
          <BsStarHalf key={index} style={{ color: "yellow" }} />
        ) : (
          <AiOutlineStar key={index} style={{ color: "yellow" }} />
        );
      })}
      </div>
      <span className="text-white">({rating > 0 ? rating.toFixed(1) : 0})</span>
    </div>
  );
};

export default Reviews;
