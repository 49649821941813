import axios from "axios";
import { API_URL } from "../lib/config";

const Bids = {
  createOffer: (userId, to, pay, offerId, isBuy) => {
    return axios
      .post(`${API_URL}/v1/bids/create/${userId}`, {
        to,
        pay,
        offerId,
        isBuy,
      })
      .then((res) => res);
  },

  getAllOffer: (id) => {
    return axios.get(`${API_URL}/v1/bids/getAll/${id}`).then((res) => res);
  },

  getMyOffer: (id) => {
    return axios.get(`${API_URL}/v1/bids/getMyBid/${id}`).then((res) => res);
  },

  acceptBid: (id) => {
    console.log("-id", id);
    return axios.put(`${API_URL}/v1/bids/accept/${id}`).then((res) => res);
  },

  cancelBid: (id) => {
    return axios.put(`${API_URL}/v1/bids/decline/${id}`).then((res) => res);
  },

  approveBid: (id) => {
    return axios.put(`${API_URL}/v1/bids/approve/${id}`).then((res) => res);
  },

  getTransactionBid: (id) => {
    return axios.get(`${API_URL}/v1/bids/transaction/${id}`).then((res) => res);
  },

  createTransaction: (
    offerId,
    from,
    to,
    senderAddress,
    receiverAddress,
    isBuy,
    amount
  ) => {
    return axios
      .post(`${API_URL}/v1/transaction/create`, {
        offerId,
        from,
        to,
        senderAddress,
        receiverAddress,
        isBuy,
        amount,
      })
      .then((res) => res);
  },

  getTransactions: (userId) => {
    return axios
      .get(`${API_URL}/v1/transaction/getMyTransaction/${userId}`)
      .then((res) => res);
  },

  emailOnTransaction: (sender, receiver, token) => {
    return axios
      .post(`${API_URL}/v1/transaction/emailOnTransaction`, {
        sender,
        receiver,
        token,
      })
      .then((res) => res);
  },

  deleteNotification: (offerId, userId) => {
    return axios
      .put(
        `${API_URL}/v1/bids/deleteNotification/offer/${offerId}/user/${userId}`
      )
      .then((res) => res);
  },

  readNotification: () => {
    return axios
      .patch(`${API_URL}/v1/bids/readNotification`)
      .then((res) => res);
  },
};

export default Bids;
