import axios from "axios";
import { API_URL } from "../lib/config";

const UserChat = {
  getChat: (senderId, receiverId) => {
    return axios
      .get(`${API_URL}/v1/chat/sender/${senderId}/receiver/${receiverId}`)
      .then((res) => res);
  },

  getChatGroup: (id) => {
    return axios.get(`${API_URL}/v1/chat/group/${id}`).then((res) => res);
  },

  getMyChat: (groupId) => {
    return axios.get(`${API_URL}/v1/chat/myChat/${groupId}`).then((res) => res);
  },

  deleteChat: (groupId, userId) => {
    return axios
      .put(`${API_URL}/v1/chat/group/${groupId}/user/${userId}`)
      .then((res) => res);
  },

  lastView: (groupId, userId) => {
    return axios
      .put(`${API_URL}/v1/chat/lastView/${groupId}/user/${userId}`)
      .then((res) => res);
  },

  unreadMessages: (userId) => {
    return axios
      .get(`${API_URL}/v1/chat/unread/user/${userId}`)
      .then((res) => res);
  },
};

export default UserChat;
