import React, { useState, useEffect } from "react";
import Auth from "../../common/Auth";
import SearchButton from "../../common/SearchButton";
import { Formik } from "formik";
import * as yup from "yup";
import InputField from "../../common/InputField";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const NewPassword = () => {
  const navigate = useNavigate();

  const [state, setState] = useState(true);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (user.id) {
      navigate("/");
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const schema = yup.object().shape({
    Password: yup.string().min(8).required("Password is required"),
    ConfirmPassword: yup
      .string()
      .min(8)
      .required("Confirm Password is required")
      .oneOf([yup.ref("Password")], "Password should be same."),
  });

  return (
    <Auth>
      <div className="flex flex-col justify-center p-5 h-full">
        <Formik
          validateOnChange={false}
          initialValues={{
            Password: "",
            ConfirmPassword: "",
          }}
          onSubmit={(values) => {
            UserAuth.createPassword(user.email, values.Password)
              .then((res) => {
                console.log("res", res);
                if (res.status === 200) {
                  toast.success("Password created successfully.");
                  navigate("/login");
                } else {
                  toast.error(res.data);
                }
              })
              .catch((e) => {
                toast.error(e.response.data);
              });
          }}
          validationSchema={schema}
        >
          {({ handleChange, handleSubmit, ...props }) => (
            <div className="flex flex-col justify-evenly items-center h-5/6 mx-0 sm:mx-5 lg:mx-10">
              <p className="font-bold text-xl mt-5 text-blue-600">
                Create New Password
              </p>
              <div className="w-full max-w-[500px]">
                <div className="flex flex-col gap-3 w-full">
                  <InputField
                    type={state ? "password" : "text"}
                    placeholder="Password"
                    value={props.values.Password}
                    onChange={handleChange("Password")}
                    isInvalid={props.touched.Password && props.errors.Password}
                    error={props.errors.Password}
                    isPassword={true}
                    state={state}
                    setState={setState}
                  />
                  <InputField
                    type={state ? "password" : "text"}
                    placeholder="Confirm Password"
                    value={props.values.ConfirmPassword}
                    onChange={handleChange("ConfirmPassword")}
                    isInvalid={
                      props.touched.ConfirmPassword &&
                      props.errors.ConfirmPassword
                    }
                    error={props.errors.ConfirmPassword}
                    isPassword={true}
                    state={state}
                    setState={setState}
                  />
                </div>
              </div>
              <div>
                <SearchButton text="Submit" onClick={handleSubmit} />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

export default NewPassword;
