import React from 'react'

const BecomeVendor = ({ index, icon, heading, detail }) => {
  return (
    <div key={index} className='md:w-4/5 flex flex-row justify-end gap-2'>
      <img className='w-14 h-14 md:w-20 md:h-20' src={icon} alt='img' />
      <div className='flex flex-col gap-2 md:gap-4'>
        <p className='text-xl sm:text-2xl font-bold text-white'>{heading}</p>
        <p className='text-white'>{detail}</p>
      </div>
    </div>
  )
}

export default BecomeVendor