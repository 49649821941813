import React from "react";
import LandingHeader from "../../common/LandingHeader";
import BecomeVendor from "./BecomeVendor";

const heading = `Become a USDT vendor on P2Pways`;
const detail =
  "Through a partnership with us, enjoy full financial freedom by becoming a USDT vendor. You are free from giving the fee to any third party, and a bundle of payment methods is available to endeavor your trade freedom. Check out the exciting perks that you get from us. ";

const data = [
  {
    icon: "/images/vendor/individual.svg",
    heading: "Better Trading",
    detail:
      "With our various provided trading methods for USDT, you grab various chances to elevate your revenue streams promptly. ",
  },
  {
    icon: "/images/vendor/secure.svg",
    heading: "Secure",
    detail:
      "We offer you a secure platform for USDT trading without the integration of any fraudulent activities through payments. ",
  },
  {
    icon: "/images/vendor/financial.svg",
    heading: "24/7 Customer Support",
    detail:
      "Enjoy a free consultation call with our experts if you have any queries regarding USDT trading whenever you want.",
  },
];

const Vendor = () => {
  return (
    <div className="container py-10">
      <LandingHeader heading={heading} detail={detail} />
      <div className="flex flex-row justify-between items-center">
        <div className="flex flex-col items-start gap-10 mt-5">
          {data.map((d, index) => (
            <BecomeVendor
              key={index}
              icon={d.icon}
              heading={d.heading}
              detail={d.detail}
            />
          ))}
        </div>
        <div className="hide md:flex justify-center items-center">
          <img src="/images/vendor/vendor.png" alt="img" />
        </div>
      </div>
    </div>
  );
};

export default Vendor;
