import React from "react";

const OfferBlock = ({ heading, children }) => {
  return (
    <div className="flex flex-col gap-2">
    <p className="text-white text-xl">{heading}</p>
    <div className="w-full min-h-[220px] border border-[#78FFD6] bg-[#10192F]">
     {children}
    </div>
    </div>
  );
};

export default OfferBlock;
