import { LOGIN, REGISTER, LOGOUT, ADDRESS, UPDATE, IS_HIDE, IS_HIDE_BNB, PHONE_VERIFICATION, AUTHENTICATOR_STATUS, REFRESH } from "./types";

export const login = (user, token) => {
  return {
    type: LOGIN,
    payload: {user, token},
  };
};


export const register = (data) => {
  return { type: REGISTER, payload: data };
};

export const logout = () => ({
  type: LOGOUT,
});

export const updateUser = (user) => {
  return {
    type: UPDATE,
    payload: user,
  };
}

export const address = (data) => {
  return { type: ADDRESS, payload: data }; 
}

export const phoneVerification = () => {
  console.log('action')
  return { type: PHONE_VERIFICATION }; 
}

export const authenticatorStatus = (status) => {
  return { type: AUTHENTICATOR_STATUS, payload: status };
}

export const onRefresh= (user) => {
  return {
    type: REFRESH,
    payload: user,
  };
};

export const onHideBalance= () => {
  return {
    type: IS_HIDE,
  };
};

export const onHideBnbBalance= () => {
  return {
    type: IS_HIDE_BNB,
  };
};
