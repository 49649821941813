import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Wrapper from "../../common/Wrapper";
import { BsSearch } from "react-icons/bs";
import Select from "react-select";
import { PaymentMethods } from "../../common/PaymentOptions";

const Trade = ({ page, setBuyer, setSeller }) => {
  const [payment, setPayment] = useState("");
  const [amount, setAmount] = useState("");
  const [isSelected, setIsSelected] = useState(0);
  const tabs = ["QUICK BUY", "QUICK SELL"];
  const [isBuy, setIsBuy] = useState(true);

  const [methodError, setMethodError] = useState("");
  const [amountError, setAmountError] = useState("");

  const offer = useSelector((state) => state.offer.offer);

  const buyerData = offer.filter((item, index) => !item.isBuy);
  const sellerData = offer.filter((item, index) => item.isBuy);

  useEffect(() => {
    if (page === "sell") setIsBuy(false);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!payment.length || !amount.length) {
      setBuyer(buyerData);
      setSeller(sellerData);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payment, amount]);

  const styles = {
    select:
      "max-h-[45px] form-select block w-full px-3 py-2 h-12 text-base font-normal text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  };

  const methodCompare = (array, payment) => {
    for (let i = 0; i < array.length; i++) {
      for (let j = 0; j < payment.length; j++) {
        if (array[i].toLowerCase() === payment[j].label.toLowerCase()) {
          return true;
        }
      }
    }
    return false;
  };

  const handleFilter = () => {
    if (!payment.length) {
      setMethodError("required");
    } else {
      setMethodError("");
    }
    if (!amount) {
      setAmountError("required");
    } else {
      setAmountError("");
      if (isBuy) {
        if (payment.length && amount.length) {
          const filteredBuyer = buyerData.filter(
            (b) =>
              methodCompare(b.paymentMethod, payment) &&
              // b.paymentMethod.includes(payment.label)
              Number(amount) >= b.limitFrom &&
              Number(amount) <= b.limitTo
          );
          // console.log("filteredBuyer", filteredBuyer);
          setBuyer(filteredBuyer);
        }
      } else {
        if (payment.length && amount.length) {
          const filteredSeller = sellerData.filter(
            (b, index) =>
              methodCompare(b.paymentMethod, payment) &&
              Number(amount) >= b.limitFrom &&
              Number(amount) <= b.limitTo
          );
          setSeller(filteredSeller);
        }
      }
    }
  };

  const changeHandler = (value) => {
    setPayment(value);
  };

  const style = {
    valueContainer: (base, state) => {
      const width = "100%";
      const minWidth = "200px";
      const height = "45px";
      const borderColor = "none";
      return { ...base, width, minWidth, height, borderColor };
    },
  };

  const handleOnChange = (e) => {
    const reg = /^[0-9][.\d]*(,\d+)?$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  return (
    <Wrapper>
      <div className="w-full pl-5 border-b border-[#E7E8EA]">
        <p className="text-2xl text-[#78FFD6] font-semibold text-center sm:text-left">
          Trade USDT with P2Pways
        </p>
        {/* tabs */}
        <div className="flex flex-row justify-center sm:justify-start gap-20 mt-5">
          {page === "buy" ? (
            <p className="font-semibold text-[#78FFD6] cursor-pointer border-b-2 leading-9 border-[#78FFD6]">
              {tabs[0]}
            </p>
          ) : page === "sell" ? (
            <p className="font-semibold text-[#78FFD6] cursor-pointer border-b-2 leading-9 border-[#78FFD6]">
              {tabs[1]}
            </p>
          ) : (
            tabs.map((t, index) => (
              <div className="max-w-max">
                <p
                  className={
                    isSelected === index
                      ? "font-semibold text-[#78FFD6] cursor-pointer border-b-2 leading-9 border-[#78FFD6]"
                      : "text-white cursor-pointer leading-9"
                  }
                  // eslint-disable-next-line no-sequences
                  onClick={() => (setIsSelected(index), setIsBuy(!isBuy))}
                >
                  {t}
                </p>
                {/* <p
                  className={
                    isSelected === index
                      ? "w-full h-1 bg-[#78FFD6] rounded-lg"
                      : ""
                  }
                ></p> */}
              </div>
            ))
          )}
        </div>
      </div>
      {/* table */}
      <div className="flex flex-row justify-center flex-wrap gap-5 mx-5">
        <div className="w-80">
          <p className="text-white">{page === "buy" ? "Buy" : "Sell"}</p>
          <input className={styles.select} value="USDT" disabled={true} />
          <p className="text-gray-400"></p>
        </div>
        <div className="method-wrapper tra w-80">
          <p className="text-white">Pay via</p>

          <Select
            isMulti={true}
            placeholder="All payment methods"
            styles={style}
            options={PaymentMethods}
            value={payment}
            onChange={changeHandler}
          />
          {<p className="text-[#FF0000]">{methodError}</p>}
        </div>
        <div className="w-80">
          <p className="text-white">I want to spend</p>
          <input
            className={styles.select}
            type="number"
            placeholder="Min amount 50"
            value={amount}
            onChange={(e) => handleOnChange(e)}
          />
          <p className="text-[#FF0000]">{amountError}</p>
        </div>
      </div>
      {/* button */}
      <div className="flex justify-center">
        <button
          className="send-coins flex flex-row items-center rounded-lg gap-3 px-20 py-2 bg-[#78FFD6]"
          disabled={!payment || !amount}
          onClick={() => handleFilter()}
        >
          Search
          {<BsSearch />}
        </button>
      </div>
    </Wrapper>
  );
};

export default Trade;
