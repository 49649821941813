import React, { useState, useEffect } from "react";
import moment from "moment";
import { Bids, Offer, UserAuth } from "../../../services";
import { Link } from "react-router-dom";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { depositBNB } from "../../../utils/depositBNB";
import { getBnbBalance } from "../../../utils/getBnbBalance";

const NotifyTo = ({ item, update, setUpdate }) => {
  const [user, setUser] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [offer, setOffer] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const userId = useSelector((state) => state.user.id);
  const token = useSelector((state) => state.user.token);


  const time = moment(item.createdAt).fromNow();

  useEffect(() => {
    UserAuth.getSingleUser(item.from).then((res) => setUser(res.data));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Offer.getSingleOffer(item.offerId)
      .then((res) => {
        if (res.status === 200) {
          setOffer(res.data);
        }
      })
      .catch((e) => console.log("e--", e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  const handleAccept = (id, item) => {
    setIsLoading(true);
    Bids.acceptBid(id)
      .then((res) => {
        if (res.status === 200) {
          setUpdate(!update);
          getUserWallet(item);
        }
      })
      .catch((e) => console.log("e", e))
      .finally(() => setIsLoading(false));
  };

  const handleDecline = (id) => {
    setIsLoading(true);
    Bids.cancelBid(id)
      .then((res) => {
        if (res.status === 200) {
          setUpdate(!update);
        }
        // console.log("res-", res);
      })
      .catch((e) => console.log("e-", e))
      .finally(() => setIsLoading(false));
  };

  const deleteNotification = (offerId) => {
    setIsLoading(true);
    Bids.deleteNotification(offerId, userId)
      .then((res) => {
        setUpdate(!update);
        toast.success("Notification deleted successfully");
      })
      .catch((e) => console.log("e", e))
      .finally(() => setIsLoading(false));
  };

  const getUserWallet = (item) => {
    UserAuth.getWallet(item.isBuy ? item.to : item.from, token)
      .then(async (res) => {
        const userBnbBalance = await getBnbBalance(res.data.address);
        if (userBnbBalance < 0.0008) {
          depositBNB(res.data.address);
        }
      })
      .catch((e) => console.log("e"));
  };

  return (
    <div className="border-b border-white relative">
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <img
              className="w-[38px] h-[38px] rounded-full border border-[#78FFD6]"
              src={!user.image ? "/images/user_profile.png" : `${user.image}`}
              alt=""
            />
            <div>
              <p className="text-[16px] text-[#78FFD6]">
                {user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : "User Name"}
              </p>
             <p className="text-[10px] text-white">
                {(item.isBuy)
                  ? `I want to buy ${item.pay} coins`
                  : `I want to sell ${item.pay} coins`}
              </p>
            </div>
          </div>
          <p className="min-w-[60px] float-right text-white text-[10px]">{time}</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-4 mt-2">
          {item.isApproved && !item.isRejected ? (
            <Link
              to={`/transaction/${item._id}`}
              state={{ data: item, user: user }}
            >
              <button
                className={`text-white rounded px-5 py-1 ${
                  item.isCompleted ? "bg-red-900" : "bg-[#37FF57]"
                }`}
                // onClick={() => handleAccept(item._id)}
              >
                {item.isCompleted ? "Completed" : "View"}
              </button>
            </Link>
          ) : item.isRejected ? (
            <button
              className="text-white rounded px-5 py-1 bg-red-900 disabled:cursor-not-allowed"
              disabled={true}
            >
              Rejected
            </button>
          ) : (
            <button
              className="text-white rounded px-3 py-1 bg-[#37FF57] disabled:cursor-not-allowed"
              onClick={() => handleAccept(item._id, item)}
              disabled={isLoading}
            >
              Accept
            </button>
          )}
          {(!item.isApproved && !item.isRejected) && (
            <button
              className="text-white rounded px-3 py-1 bg-[#FF5050] disabled:cursor-not-allowed"
              onClick={() => handleDecline(item._id)}
              disabled={isLoading}
            >
              Decline
            </button>
          )}
          {
            <button
              className="text-white rounded-full p-2 bg-gray-500 cursor-pointer disabled:cursor-not-allowed absolute bottom-[10px] right-[10px]"
              onClick={() => deleteNotification(item._id)}
              disabled={isLoading}
            >
              <AiOutlineDelete style={{ color: "white" }} />
            </button>
          }
        </div>
      </div>
    </div>
  );
};

export default NotifyTo;
