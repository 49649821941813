import { combineReducers, createStore } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import UserReducer from "./user/reducer";
import BuyerReducer from './buyer/reducer'
import SellerReducer  from "./seller/reducer";
import OfferReducer from "./offer/reducer";

const rootReducer = combineReducers({
  user: UserReducer,
  offer: OfferReducer,
  buyer: BuyerReducer,
  seller: SellerReducer
});

const persistConfig = {
  // Root
  key: "root",
  // Storage Method (React Native)
  storage: storage,
  // Whitelist (Save Specific Reducers)
  // whitelist: ['user'],
  // blacklist: ['reducer']
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer);
let persistor = persistStore(store);
export { store, persistor };
