import React, { useState, useEffect } from "react";

import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";

import OfferBlock from "../OfferBlock";
import { UserReviews } from "../../../services";
import UserDetail from "./UserDetail";

const options = ["All", "Positive", "Negative"];

const ClientFeedback = ({ offer }) => {
  const [reviews, setReviews] = useState([]);
  const [filterdReviews, setFilteredReviews] = useState([]);
  const [option, setOption] = useState(0);

  useEffect(() => {
    UserReviews.getAllReview(offer.userId._id)
      .then((res) => {
        if (res.status === 200) {
          setReviews(res.data.reviews);
          setFilteredReviews(res.data.reviews);
        }
      })
      .catch((e) => console.log("e"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProperties = (index) => {
    setOption(index);
    if (index === 0) {
      setFilteredReviews(reviews);
    }
    if (index === 1) {
      const positive = reviews.filter((review) => review.isPositive);
      setFilteredReviews(positive);
    }
    if (index === 2) {
      const negative = reviews.filter((review) => !review.isPositive);
      setFilteredReviews(negative);
    }
  };

  return (
    <div>
      <div>
        <OfferBlock>
          <Header>
            <div className="flex flex-row justify-between items-center sm:mx-5">
              <div className="flex flex-row items-center text-[12px] sm:text-base py-2 text-white gap-6 sm:gap-14">
                Feedback
              </div>
              <div className="flex flex-row items-center gap-3 sm:gap-8">
                {options.map((item, index) => (
                  <p
                    className={
                      option === index
                        ? "text-[#6DDABD] text-[12px] sm:text-base font-bold cursor-pointer"
                        : "text-white text-[12px] sm:text-base cursor-pointer"
                    }
                    onClick={() => handleProperties(index)}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </Header>
          <Body>
            <div className="min-h-[300px] max-h-[300px] offer-block scrollbar-hide relative">
              {!filterdReviews.length ? (
                <div className="flex flex-col justify-center items-center gap-2 py-3 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
                  <p className="text-white text-center">
                    This user don,t have any feedback
                  </p>
                  <img src="/images/profile/empty.png" alt="img" />
                </div>
              ) : (
                <div>
                  {filterdReviews.map((review, index) => (
                    <div className="flex flex-row items-start gap-2 mb-6 border-b py-5">
                      <UserDetail review={review} />
                      <p className="text-white">{review.comment}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Body>
        </OfferBlock>
      </div>
    </div>
  );
};

export default ClientFeedback;
