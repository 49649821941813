import React from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const SellBuyOption = ({ option, setOption, setSelected }) => {
  const user = useSelector((state) => state.user);
  const { isPhoneVerified } = user;

  const styles = {
    radio:
      "form-check-input rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-[#78FFD6] checked:border-[#78FFD6] focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
    label: "form-check-label inline-block text-white text-base",
  };

  const onNext = () => {
    if (!isPhoneVerified) {
      toast.error("Phone verification is required");
    }
    else if (!user.firstName) {
      toast.error(
        "Your profile is not complete, go to profile and complete it."
      );
    } else {
      setSelected(1);
    }
  };

  return (
    <div>
      <p className="text-[#78FFD6] text-2xl">What would you like to do?</p>
      <div className="flex">
        <div className="flex flex-col gap-3 mt-5 ml-5">
          <div className="form-check flex flex-row gap-2">
            <input
              className={styles.radio}
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault1"
              checked={option === "buy"}
              value="buy"
              onChange={(e) => setOption(e.target.value)}
            />
            <div>
              <label className={styles.label} for="flexRadioDefault1">
                Buy USDT
              </label>
              <p className="text-white text-sm">
                Your offer will be listed on the Sell USDT page.
              </p>
            </div>
          </div>
          <div className="form-check flex flex-row gap-2">
            <input
              className={styles.radio}
              type="radio"
              name="flexRadioDefault"
              id="flexRadioDefault2"
              checked={option === "sell"}
              value="sell"
              onChange={(e) => setOption(e.target.value)}
            />
            <div>
              <label className={styles.label} for="flexRadioDefault2">
                Sell USDT
              </label>
              <p className="text-white text-sm">
                Your offer will be listed on the Buy USDT page.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-row justify-center mt-5">
        <button
          className="px-8 py-2 bg-[#98F18A] rounded font-semibold"
          onClick={() => onNext()}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default SellBuyOption;
