import React from "react";
import ClientFeedback from "../../common/ClientFeedback";

const OfferFeedBack = ({ data }) => {
  return (
    <div className="flex flex-col gap-2">
      <p className="text-white text-xl">Feedback</p>
      <ClientFeedback offer={data} />
    </div>
  );
};

export default OfferFeedBack;
