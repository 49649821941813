import { GET_BUYER } from './types';

const initialState = {
  buyer: []
};

const BuyerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BUYER:
      return {
        ...state,
        buyer: action.payload
      };

    default:
      return state;
  }
};

export default BuyerReducer;
