import { GET_OFFERS } from './types';

const initialState = {
  offer: []
};

const OfferReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_OFFERS:
      return {
        ...state,
        offer: action.payload
      };

    default:
      return state;
  }
};

export default OfferReducer;
