import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { Bids } from "../../../services";

import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";


const History = () => {
  const [history, setHistory] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [buyer, setBuyer] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [seller, setSeller] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    Bids.getTransactions(user.id).then((res) => {
      if (res.status === 200) {
        setHistory(res.data);
        const buyer = res.data.filter(
          (transaction) => transaction.isBuy && transaction.from._id === user.id
        );
        const seller = res.data.filter((transaction) => !transaction.isBuy);
        setBuyer(buyer);
        setSeller(seller);
      }
    });
    // .catch((e) => console.log("e my offer", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

 

  return (
    <div>
      <ProfileBlock>
        <Header>
          <p className="text-xl text-[#6DDABD] font-semibold py-2">
            Transaction History
          </p>
       
        </Header>
        <Body>
          <div className="min-h-[300px] max-h-[300px] overflow-auto scrollbar-hide">
            <div className="w-full overflow-auto scrollbar-hide">
              <table className="w-full overflow-auto scrollbar-hide">
                <thead className="text-xs text-white bg-[#404759] dark:bg-gray-700 dark:text-gray-400">
                  <tr className="h-[45px] text-left">
                    <th scope="col" className="pl-2">
                      Sender
                    </th>
                    <th scope="col" className=" mt-4">
                      Receiver
                    </th>
                    <th scope="col" className="">
                      Amount
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full text-left relative">
                  {history.length ? (
                    history.map((data, index) => (
                      <tr
                        className={
                          index % 2 !== 0 ? "bg-[#404759]" : "bg-[#9FA3AC]"
                        }
                      >
                        <td className=" text-[12px] md:text-sm text-white pl-2">
                          <span>
                          {data.from.firstName} {data.from.lastName}
                           
                          </span>
                        </td>
                        <td className="text-[12px] md:text-sm text-white">
                          <span>
                          {data.to.firstName} {data.to.lastName}
                           
                          </span>
                        </td>
                        <td className="p-2 text-[12px] md:text-sm text-white ">
                          {data.amount}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <div className="min-h-[300px] w-full flex flex-col justify-center items-center gap-2 py-3 absolute">
                      <p className="text-white">
                        This user don,t have any Buy history
                      </p>
                      <img src="/images/profile/empty.png" alt="img" />
                    </div>
                  )}
                  
                </tbody>
              </table>
            </div>
          </div>
        </Body>
      </ProfileBlock>
    </div>
  );
};

export default History;
