import React, { useState } from "react";
import Modal from "react-modal";
import {
  AiOutlineClose,
  AiFillStar,
  AiOutlineStar,
  AiOutlineLike,
  AiOutlineDislike,
} from "react-icons/ai";
import { UserReviews } from "../../../services";
import { toast } from "react-toastify";

const TransactionReviews = ({
  data,
  loginUser,
  modalIsOpen,
  afterOpenModal,
  closeModal,
  onUpdate,
  setOnUpdate
}) => {
  const [selectedStars, setelectedStars] = useState([
    { value: 0, state: false },
    { value: 1, state: false },
    { value: 2, state: false },
    { value: 3, state: false },
    { value: 4, state: false },
  ]);
  const [count, setCount] = useState(0);
  const [isPositive, setIsPositive] = useState(true);
  const [comment, setComment] = useState("");

  const handleUnfill = (index) => {
    const res = selectedStars.map((star) =>
      star.value > index ? { ...star, state: false } : star
    );
    const count = res.filter((star) => star.state);
    setCount(count.length);
    setelectedStars(res);
  };

  const handleFill = (index) => {
    const res = selectedStars.map((star) =>
      star.value <= index ? { ...star, state: true } : star
    );
    const count = res.filter((star) => star.state);
    setCount(count.length);
    setelectedStars(res);
  };

  const handlePost = () => {
    const receiverId = loginUser.id === data.from ? data.to : data.from;
    const isBuyer = loginUser.id === data.from && data.isBuy;

    UserReviews.postReview(
      data.offerId,
      loginUser.id,
      receiverId,
      comment,
      count,
      isBuyer,
      isPositive,
      data._id,
    )
      .then((res) => {
        // console.log("ress", res.data);
        if (res.status === 200) {
          toast.success("Review posted successfully");
          setOnUpdate(!onUpdate);
          closeModal();
        }
      })
      .catch((e) => {
        console.log("ee", e);
        toast.error(e.response.data);
      });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: '320px',
          maxWidth: '400px',
          minHeight: 'max-content',
          transform: "translate(-50%, -50%)",
          background: "white",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          borderRadius: "20px",
        },
      }}
    >
      <div className="App flex flex-col justify-between gap-2 h-full">
        <div className="w-full relative">
          <p className="text-center text-[28px]">Add Feedback</p>
          <AiOutlineClose
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              right: 0,
              cursor: "pointer",
            }}
            onClick={closeModal}
          />
        </div>
        <div className="flex flex-col justify-start">
          <p>Add Rating</p>
          <div className="flex flex-row justify-center gap-2">
            {selectedStars.map((star, index) =>
              star.state ? (
                <AiFillStar
                  onClick={() => handleUnfill(index)}
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "yellow",
                    cursor: "pointer",
                  }}
                />
              ) : (
                <AiOutlineStar
                  onClick={() => handleFill(index)}
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "yellow",
                    cursor: "pointer",
                  }}
                />
              )
            )}
          </div>
        </div>
        <div className="flex flex-col justify-start gap-1">
          <div className="flex flex-row justify-between">
            <p>Add Comment</p>
            <div className="flex flex-row gap-2">
              <button
                onClick={() => setIsPositive(true)}
                className={`px-3 py-1 bg-[#98F18A] rounded ${
                  isPositive && "border border-black"
                }`}
              >
                <AiOutlineLike />
              </button>
              <button
                onClick={() => setIsPositive(false)}
                className={`px-3 py-1 bg-[#FF7272] rounded ${
                  !isPositive && "border border-black"
                }`}
              >
                <AiOutlineDislike />
              </button>
            </div>
          </div>
          <textarea
            className="outline-none border border-[#10192F] p-1"
            rows={3}
            value={comment}
            onChange={(e) => setComment(e.target.value)}
          />
        </div>
        <div className="flex flex-row justify-center items-center bottom-5">
          <button
            className="send-coins text-[#10192F] rounded px-10 py-1 mt-3 bg-[#98F18A]"
            disabled={count === 0 || !comment}
            onClick={() => handlePost()}
          >
            Post
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default TransactionReviews;
