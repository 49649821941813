import React, { useState, useEffect } from "react";
import { BiSearch } from "react-icons/bi";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Pagination from "../components/common/CoinsTable/Pagination";
import Page from "../components/common/Page";
import { UserAuth } from "../services";

const tabs = ["All", "Blocked", "Unblocked"];

const AllUsers = () => {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [selected, setSelected] = useState(0);
  const [search, setSearch] = useState("");
  const [data, setData] = useState([]);
  const [filterdUser, setFilterdUser] = useState(data);

  const [isBlock, setIsBLock] = useState(false);
  const user = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(5);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = filterdUser?.slice(indexOfFirstPost, indexOfLastPost);


  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const movePrevious = () => setCurrentPage(currentPage - 1);
  const moveNext = () => setCurrentPage(currentPage + 1);

  useEffect(() => {
    if (!user.isAdmin) {
      navigate("/");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    UserAuth.getAllUser()
      .then((res) => {
        setData(res.data);
        setFilterdUser(res.data);
      })
      // .catch((e) => console.log("e", e));
  }, [isBlock]);

  const handleBlock = (id) => {
    setIsLoading(true)
    UserAuth.blockUser(id)
      .then((res) => {
        if (res.status === 200) {
          setIsBLock(!isBlock);
          toast.success(res.data);
        } else {
          toast.error(res.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setIsLoading(false))
  };

  const handleUnBlock = (id) => {
    setIsLoading(true)
    UserAuth.unBlockUser(id)
      .then((res) => {
        if (res.status === 200) {
          setIsBLock(!isBlock);
          toast.success(res.data);
        } else {
          toast.error(res.data);
        }
      })
      .catch((e) => {
        toast.error(e.response.data.message);
      })
      .finally(() => setIsLoading(false))
  };

  const handleTab = (index) => {
    setSelected(index);
    if (index === 0) {
      setFilterdUser(data);
    }
    if (index === 1) {
      const unBlockUser = data.filter((user) => user.isBlock);
      setFilterdUser(unBlockUser);
    }
    if (index === 2) {
      const blockUser = data.filter((user) => !user.isBlock);
      setFilterdUser(blockUser);
    }
  };

  const handleSearch = (event) => {
    const e = event.target.value;
    setSearch(e);
    // const result = email.filter((mail) => mail.includes(e))
    const result = data.filter((user) => user.email.match(e));
    setFilterdUser(result);
  };

  return (
    <Page title="P2Pways – All Users">
      <div className="container">
        <p className="text-center text-2xl text-[#78FFD6] pt-10">
          All Accounts
        </p>
        <div className="mt-5 px-5">
          <div className="flex flex-col sm:flex-row sm:justify-between sm:items-center">
            <div className="flex flex-row items-center gap-3">
              {tabs.map((tab, index) => (
                <p
                  key={index}
                  className={
                    selected === index
                      ? "text-[#78FFD6] cursor-pointer"
                      : "text-white cursor-pointer"
                  }
                  onClick={() => handleTab(index)}
                >
                  {tab}
                </p>
              ))}
            </div>
            <div className="flex flex-row items-center gap-2 border border-white rounded-md p-2">
              <BiSearch style={{ color: "white " }} />
              <input
                type="text"
                placeholder="search by email"
                className="text-white bg-transparent focus:none focus:outline-none placeholder:white"
                value={search}
                onChange={(e) => handleSearch(e)}
              />
            </div>
          </div>
          <div className="bg-[#404759] rounded mt-5">
            <div className="overflow-auto rounded-md min-h-[350px]">
              <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 ">
                <thead className="text-xs text-white uppercase bg-[#404759] dark:bg-gray-700 dark:text-gray-400 py-2">
                  <tr>
                    <th scope="col" className="p-3">
                      Account Name
                    </th>
                    <th scope="col" className="p-3">
                      Gmail
                    </th>
                    <th scope="col" className="p-3">
                      Country
                    </th>
                    {/* <th scope="col" className="p-3">
                      Reviews
                    </th> */}
                  </tr>
                </thead>
                <tbody className="bg-[#404759]">
                  {currentPosts.map((user, index) =>
                    !user.isAdmin ? (
                      <tr
                        className={
                            " w-full border-b border-t"
                        }
                      >
                        <th
                          scope="row"
                          className="p-3 text-sm font-medium text-white dark:text-white whitespace-nowrap"
                        >
                          <Link to="/view-profile" state={{ userData: user }}>
                            {user.firstName
                              ? (user.firstName + user.lastName).toUpperCase()
                              : "User Name"}
                          </Link>
                        </th>
                        <td className="p-3 text-sm text-white">{user.email}</td>
                        <td className="p-3 text-sm text-white">
                          {user.country}
                        </td>
                        <td className="p-3 text-sm">
                          {/* <Reviews reviews={user._id} /> */}
                        </td>
                        <td className="p-3 text-sm text-right">
                          {!user.isBlock ? (
                            <button
                              className="w-[120px] font-medium text-white bg-black rounded-md px-5 py-2 cursor-pointer hover:bg-blue-600 disabled:cursor-not-allowed"
                              onClick={() => handleBlock(user._id)}
                              disabled={isLoading}
                            >
                              Ban
                            </button>
                          ) : (
                            <button
                              className="w-[120px] font-medium text-white bg-[#FF0000] rounded-md px-5 py-2 cursor-pointer hover:bg-blue-600 disabled:cursor-not-allowed"
                              onClick={() => handleUnBlock(user._id)}
                              disabled={isLoading}
                            >
                              UnBanned
                            </button>
                          )}
                        </td>
                      </tr>
                    ) : null
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="flex justify-center mt-5">
            <Pagination
              postsPerPage={postsPerPage}
              totalPosts={data.length}
              paginate={paginate}
              currentPage={currentPage}
              movePrevious={movePrevious}
              moveNext={moveNext}
            />
          </div>
        </div>
      </div>
    </Page>
  );
};

export default AllUsers;
