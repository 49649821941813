import React, { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import Modal from "react-modal";
import OtpInput from "react-otp-input";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { phoneVerification } from "../../../redux/user/action";
import Auth from "../../../services/auth";

const PhoneVerification = ({
  userId,
  modalIsOpen,
  afterOpenModal,
  closeModal,
  isOtpInput,
  setisOtpInput,
}) => {
  const dispatch = useDispatch();

  const [otp, setOtp] = useState("");
  const [phone, setPhone] = useState("+");
  const [loading, setLoading] = useState(false);

  const generateOtp = () => {
    setLoading(true);
    Auth.generatePhoneOtp(userId, phone)
      .then((res) => {
        if (res.status === 200) {
          setisOtpInput(true);
          toast.success("OTP successfully send to your phone");
          localStorage.setItem('num', phone)
        }
      })
      .catch((e) => console.log("eee", e))
      .finally(() => setLoading(false));
  };

  const VerifyOtpOtp = () => {
    const number = localStorage.getItem('num')
    setLoading(true);
    Auth.phoneOtpVerification(userId, otp, number)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Successfully verified");
          dispatch(phoneVerification());
          setisOtpInput(false);
          closeModal();
        }
      })
      .catch((e) => {
        toast.error("Invalid code");
      })
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "320px",
          maxWidth: "400px",
          minHeight: "max-content",
          transform: "translate(-50%, -50%)",
          background: "#10192F",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          borderRadius: "8px",
        },
      }}
    >
      <div className="App flex flex-col justify-between gap-2 h-full">
        <div className="w-full relative">
          <p className="text-[#78FFD6] text-center text-[20px]">
            Phone Verification
          </p>
          <AiOutlineClose
            style={{
              position: "absolute",
              top: "50%",
              transform: "translate(-50%, -50%)",
              right: 0,
              cursor: "pointer",
              color: "white",
            }}
            onClick={closeModal}
          />
        </div>
        {/*  */}
        <div className="h-32 flex flex-col justify-center items-center mt-8">
          {!isOtpInput ? (
            <div>
              <p className="text-white py-2">Enter your phone</p>
              <input
                className="p-2 rounded-md text-black focus:outline-none placeholder:text-gray-500 placeholder:text-[13px]"
                type="text"
                placeholder="Enter your number"
                value={phone}
                onChange={({ target: { value } }) => {
                  if (
                    value.length &&
                    value[0] === "+" &&
                    !isNaN(Number(value.slice(1)))
                  )
                    setPhone(value);
                }}
              />
            </div>
          ) : (
            <div>
              <p className="text-white py-2">Enter your OTP</p>
              <OtpInput
                containerStyle="gap-3 sm:gap-5"
                inputStyle=" min-w-[36px] min-h-[40px] rounded-xl focus:outline-none"
                value={otp}
                onChange={setOtp}
                numInputs={6}
                isInputNum={true}
                isInputSecure={false}
              />
            </div>
          )}
          <div className="flex flex-row justify-center items-center  mt-10 mb-3">
            {!isOtpInput ? (
              <button
                className="send-coins text-[#10192F] rounded px-10 py-1 bg-[#98F18A] disabled:bg-gray-400"
                onClick={() => generateOtp()}
                disabled={!phone || phone.length < 7 || loading}
              >
                Submit
              </button>
            ) : (
              <button
                className="send-coins text-[#10192F] rounded px-10 py-1 bg-[#98F18A] disabled:bg-gray-400"
                onClick={() => VerifyOtpOtp()}
                disabled={!otp || loading}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default PhoneVerification;
