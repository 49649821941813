import React from "react";
import { APP_URL } from "../../../lib/config";
import { sellData } from "../Data/SellData";

const HowToBuy = ({ header }) => {
  return (
    <div className="py-5 my-5">
      <div className="mx-5 lg:mx-10">
        <div className="container">
          {/* header */}
          <div className="flex flex-col text-center gap-3">
            <p className="text-xl sm:text-3xl font-bold text-[#78FFD6]">
              {header}
            </p>
            <div className="flex flex-row justify-center">
              <p className="text-sm sm:text-base text-center text-white md:w-4/5">
                It's now easy to sell USDT as a P2Pways vendor. You have the
                freedom to set your own rates, and also the luxury of over 100+
                payment options to get paid for the USDT you sell. As P2Pways is
                a peer-to-peer marketplace, you can sell your USDT directly to
                over 1 million users worldwide. Our platform makes it extremely
                easy for beginners and veterans alike to make a profit.
              </p>
            </div>
            <p className="text-lg sm:text-xl text-center font-bold text-[#78FFD6]">
              To buy USDT instantly, create a P2Pways account or log in to your
              existing one. Once logged in, just follow these steps:
            </p>
          </div>
          {/* points */}
          <div className="mt-3">
            {sellData.map((d, index) => (
              <div key={index} className="mt-4">
                <p className="text-[#78FFD6]">{d.heading}</p>
                <p className="text-sm text-justify text-white mt-1">
                  {d.detail}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
      {/* important note */}
      <div className="py-4 bg-[#26D1A0] mt-5 relative flex flex-row justify-center">
        <p className="text-center text-sm text-white w-4/5 lg:w-3/5 mt-2">
          If you have any questions, please contact us at 
          <a href={`${APP_URL}/contact-us`} target="_blank" rel="noreferrer">
          <strong className="text-blue-800"> help@p2pways.com </strong>
          </a>
           to get in touch with our support team. We're
          here for you 24/7—even on holidays!
        </p>
        <div>
          <img
            style={{ marginTop: "-15px" }}
            className="w-10 h-10 absolute right-5 md:right-10 top-0"
            src="/images/important.png"
            alt="img"
          />
        </div>
      </div>
    </div>
  );
};

export default HowToBuy;
