const API_URL = "https://api.p2pways.com";
const APP_URL = "https://p2pways.com";
const usdt_contract_address = "0x55d398326f99059fF775485246999027B3197955";
const binance_provider = "https://bsc-dataseed.binance.org";


// const API_URL = "http://localhost:5001"
// const APP_URL = "http://localhost:3000"
// const usdt_contract_address = "0x337610d27c682e347c9cd60bd4b3b107c9d34ddd";
// const binance_provider = "https://data-seed-prebsc-1-s1.binance.org:8545/";
// const quick_node_provider =
//   "https://powerful-practical-daylight.bsc-testnet.discover.quiknode.pro/9ba30e5acd3f18da4256209943eaad559170451f/";

export {
  API_URL,
  APP_URL,
  binance_provider,
  usdt_contract_address,
};
