import React from "react";

const TextArea = ({ label, type, value, onChange, error }) => {
  const styles = {
    select:
      "form-select block w-full px-3 py-1 text-base font-normal text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  };
  return (
    <div className="w-full">
      <p className="text-[#78FFD6]">{label}</p>
      <textarea
        className={styles.select}
        type={type}
        placeholder="Any amount"
        rows="3"
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <p className="text-[#FF0000] text-[12px]">{error}</p>
    </div>
  );
};

export default TextArea;
