import React, { useState } from "react";
import Reviews from "../Reviews";
import Wrapper from "../Wrapper";

import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Pagination from "./Pagination";

const CoinsTable = ({ heading, isSell, data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line no-unused-vars
  const [postsPerPage, setPostsPerPage] = useState(5);

  const user = useSelector((state) => state.user);

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPost = [...data]
    .reverse()
    .slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const movePrevious = () => setCurrentPage(currentPage - 1);
  const moveNext = () => setCurrentPage(currentPage + 1);

  return (
    <Wrapper>
      <div className="flex flex-row justify-between items-center px-5 py-3 border-b border-[#E7E8EA]">
        <p className="text-xl sm:text-2xl text-[#78FFD6] font-semibold">
          {heading}
        </p>
        {/* <FilterButton /> */}
      </div>
      {/* table */}
      <div className="overflow-auto min-h-[350px]">
        {data.length ? (
          <table className="w-full table-fixed md:table-auto overflow-auto text-sm text-left text-gray-500 dark:text-gray-400 bg-[#404759]">
            <thead className="w-full text-[10px] sm:text-xs text-white uppercase bg-[#404759] dark:bg-gray-700 dark:text-gray-400">
              <tr className="">
                <th scope="col" className="p-3 w-20 md:w-auto">
                  {isSell ? "Buyer" : "Seller"}
                </th>
                <th scope="col" className="p-3 w-32 sm:w-20 md:w-auto">
                  Payment <span className="hide md:block">Method</span>
                </th>
                <th scope="col" className="p-3 w-32 md:w-auto">
                  Country
                </th>
                <th scope="col" className="p-3 h-full w-16 md:w-auto">
                  Reviews
                </th>
                <th scope="col" className="p-3 w-16 md:w-auto">
                  Usd
                </th>
                <th scope="col" className="p-3 w-16 md:w-auto">
                  Limits
                </th>
                <th scope="col" className="p-3 w-20 md:w-auto"></th>
              </tr>
            </thead>
            <tbody className="bg-[#404759] text-[10px] sm:text-sm">
              {currentPost.map((offer, index) => (
                <tr
                  key={index}
                  className={`${
                    index % 2 !== 0 ? "bg-[#404759]" : "bg-[#9FA3AC]"
                  } hover:bg-[#10192F]`}
                >
                  <th
                    scope="row"
                    className="p-3 truncate md:text-clip font-medium text-white dark:text-white whitespace-nowrap"
                  >
                    <Link
                      className="cursor-pointer"
                      to="/view-profile"
                      state={{ userData: offer.userId }}
                    >
                      {offer.userId.firstName} {offer.userId.lastName}
                    </Link>
                  </th>
                  <td className="p-3 text-white">
                    <div className="methods-body max-h-10">
                      {/* {offer.paymentMethod[0].toUpperCase()} */}
                      {offer.paymentMethod.map((method, index) => (
                        <p key={index}>{method}</p>
                      ))}
                    </div>
                  </td>
                  <td className="p-3 text-white">
                    {offer.country.toUpperCase()}
                  </td>
                  <td className="p-3">
                    <Reviews reviews={offer.userId} />
                  </td>
                  <td className="p-3 text-white font-semibold">
                    {offer.price
                      // .toFixed(2)
                      .toString()
                      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}{" "}
                  </td>
                  <td className="p-3 text-white">
                    {Math.abs(offer.limitFrom).toLocaleString(undefined, {
                      maximumFractionDigits: 12,
                    })}
                    -{" "}
                    {Math.abs(offer.limitTo).toLocaleString(undefined, {
                      maximumFractionDigits: 12,
                    })}
                  </td>
                  <td className="p-3 text-right">
                    <Link to="/offer" state={{ data: offer }}>
                      <button
                        className="send-coins font-medium text-white bg-green-500 rounded-md px-5 py-2 cursor-pointer"
                        disabled={offer.userId._id === user.id ? true : false}
                      >
                        {!isSell ? "Buy" : "Sell"}
                      </button>
                    </Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <div className="min-h-[350px] flex flex-col justify-center items-center p-5 text-white">
            <img src="/images/empty.png" alt="img" />
            <p>No offer found</p>
          </div>
        )}
      </div>
      {!!data.length && (
        <div className="flex justify-center">
          <Pagination
            postsPerPage={postsPerPage}
            totalPosts={data.length}
            paginate={paginate}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            movePrevious={movePrevious}
            moveNext={moveNext}
          />
        </div>
      )}
    </Wrapper>
  );
};

export default CoinsTable;
