/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import Page from "../components/common/Page";
import { Offer, UserAuth } from "../services";
import Auth from "../services/auth";
import { withdraw_coins } from "../utils/bep_20";
import { getTokenBalance } from "../utils/getBalance";

const Withdraw = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);
  const [amount, setAmount] = useState("");
  const [balance, setBalance] = useState("");
  const [address, setAddress] = useState("");
  const [sender, setSender] = useState("");
  const [loading, setLoading] = useState(false);
  const [onUpdateCoin, setOnUpdateCoin] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [postTotalCoins, setPostTotalCoins] = useState(0);
  const [remainingCoins, setRemainingCoins] = useState(balance);

  const styles = {
    label: "text-white",
    input: "w-full outline-none p-2 bg-white",
  };

  useEffect(() => {
    if (!user.id) {
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (user.address) {
      getTokenBalance(setBalance, user.address);
    }
  }, []);

  useEffect(() => {
    UserAuth.getWallet(user.id, user.token).then((res) => {
      setSender(res.data);
    });
  }, []);

  useEffect(() => {
    Offer.getMyAllSellingOfferSum(user.id)
      .then((res) => {
        setPostTotalCoins(res.data.sum);
        setRemainingCoins(Number(balance) - Number(res.data.sum));
      })
      .catch((e) => {
        setRemainingCoins(Number(balance) - 0);
      });
  }, [balance]);

  const handleWithdraw = () => {
    setLoading(true);
    if (amount > remainingCoins) {
      toast.error(
        "You dont have enough coins, delete your selling offer to release your coins"
      );
      if (remainingCoins > 0) {
        toast.error(`You can withdraw upto ${remainingCoins.toFixed(2)} USDT`);
      }
    } else if (amount === 0) {
      toast.error(`Minimum coins should be 1 USD`);
    } else {
      withdraw_coins(
        amount,
        address,
        sender.address,
        sender.privateKey,
        (status) => {
          if (status) {
            handleEmailOnTransaction();
          }
        }
      );
      setAmount("");
      setAddress("");
    }

    setTimeout(() => {
      setOnUpdateCoin(!onUpdateCoin);
    }, 15000);
    setLoading(false);
  };

  const handleEmailOnTransaction = () => {
    Auth.withdrawCoins(user.email, amount)
      .then((res) => console.log("res"))
      .catch((e) => toast.error( e.response.data.message));
  };

  const handleOnChange = (e) => {
    const reg = /^[0-9][.\d]*(,\d+)?$/;
    if (e.target.value === "" || reg.test(e.target.value)) {
      setAmount(e.target.value);
    }
  };

  return (
    <Page title="P2Pways – Withdraw" onUpdateCoin={onUpdateCoin}>
      <div className="flex justify-center items-center min-h-[90vh] my-10">
        <div className="w-full sm:w-[500px] bg-[#404759] rounded p-2 sm:p-5 mx-2">
          <p className="text-xl text-center text-[#78FFD6]">Withdraw Coins</p>
          <div className="flex flex-col gap-3 my-5">
            <div>
              <p className={styles.label}>Your Coins</p>
              <input
                className={styles.input}
                type="number"
                value={amount}
                onChange={(e) => handleOnChange(e)}
              />
            </div>
            <div>
              <p className={styles.label}>From Address</p>
              <input
                className={styles.input}
                type="text"
                placeholder="Your wallet address"
                disabled={true}
                value={sender.address}
              />
            </div>
            <div>
              <p className={styles.label}>To Address</p>
              <input
                className={styles.input}
                type="text"
                placeholder="Paste your USDT address (BEP20) to send coins"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            {!!(amount.length && address.length) && (
              <p className="text-white text-[12px]">*Transaction Fees: 0.4 USDT</p>
            )}
            <div className="flex justify-center mt-5">
              <button
                className="send-coins px-10 py-3 bg-[#78FFD6] rounded"
                disabled={
                  !address.length ? true : false || !amount > 0 || loading
                }
                onClick={() => handleWithdraw()}
              >
                Withdraw
              </button>
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Withdraw;
