import axios from "axios";
import { API_URL } from "../lib/config";

const Favourite = {
  get: (id) => {
    return axios
      .get(`${API_URL}/v1/favourite/getFavourite/${id}`)
      .then((res) => res);
  },
  add: (userId, offerId) => {
    console.log("add", userId, offerId);
    return axios
      .post(`${API_URL}/v1/favourite/add/${userId}`, {
        offerId,
      })
      .then((res) => res);
  },
  remove: (userId, offerId) => {
    console.log("remove", userId, offerId);
    return axios
      .delete(`${API_URL}/v1/favourite/remove/${userId}/${offerId}`)
      .then((res) => res);
  },
};

export default Favourite;
