import axios from "axios";
import { API_URL } from "../lib/config";

const Buyer = {
  createBuyer: (
    userId,
    name,
    country,
    price,
    limitFrom,
    limitTo,
    terms,
    paymentMethod = "Stripe",
  ) => {
    return axios
      .post(`${API_URL}/v1/buyer/create`, {
        userId,
        name,
        paymentMethod,
        country,
        price,
        limitFrom,
        limitTo,
        terms
      })
      .then((res) => res);
  },

  getAllBuyer: () => {
    return axios.get(`${API_URL}/v1/buyer/getAll`).then((res) => res);
  },

  getSingleBuyer: (id) => {
    console.log('idd', id)
    return axios.get(`${API_URL}/v1/buyer/get/${id}`).then((res) => res);
  },

};

export default Buyer;
