import React, { useState, useEffect } from "react";
import Auth from "../../common/Auth";
import SearchButton from "../../common/SearchButton";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as yup from "yup";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ForgotPasswordOtp = () => {
  const navigate = useNavigate();

  const [state, setState] = useState(false);
  const [timer, setTimer] = useState(60);

  const user = useSelector((state) => state.user.email);

  // const handleChange = (otp) => {
  //   setOtp(otp);
  // };

  useEffect(() => {
    if (timer > 0 && state) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setState(false);
    }
  }, [timer, state]);

  return (
    <Auth>
      <div className="flex flex-col justify-center items-center p-5 h-full">
        <div className="flex flex-col justify-evenly items-center p-3 h-5/6 md:mx-10">
          <p className="font-bold text-xl mt-5 text-blue-600">
            Verify your Email
          </p>
          <p className="text-center text-[13px] text-red-600">
            please check spam or junk email if you did not receive in your inbox
          </p>

          <Formik
            validateOnChange={false}
            initialValues={{
              OTP: "",
            }}
            onSubmit={(values) => {
              UserAuth.verification(user, values.OTP)
                .then((res) => {
                  console.log("res", res);
                  if (res.status === 200) {
                    toast.success("Verified successfully.");
                    navigate("/new-password");
                  } else {
                    toast.error("Invalid OTP");
                  }
                })
                .catch((e) => {
                  toast.error("Enter valid code");
                });
            }}
            validationSchema={yup.object().shape({
              OTP: yup.string().min(6).required("OTP is required"),
            })}
          >
            {({ handleChange, handleSubmit, ...props }) => (
              <>
                <div className="min-h-[80px]">
                  <div className="w-full py-5">
                    <p className="text-left font-bold">Enter Code</p>
                  </div>
                  <OtpInput
                    containerStyle="gap-3 sm:gap-5"
                    inputStyle="border-2 border-[#00739D] min-w-[36px] min-h-[40px] rounded-xl focus:outline-none"
                    value={props.values.OTP}
                    onChange={handleChange("OTP")}
                    numInputs={6}
                    isInputNum={true}
                    isInputSecure={false}
                  />
                  {props.touched.OTP && props.errors.OTP && (
                    <div className="text-red-600 mt-2">{props.errors.OTP}</div>
                  )}
                  {state && (
                    <div className="w-full text-center">
                      <p className="text-[#FF6262] mt-3">00:{timer}</p>
                      <p className="text-[#FF6262] mt-3 cursor-pointer">
                        Send Again
                      </p>
                    </div>
                  )}
                </div>
                <div>
                  <SearchButton text="Confirm" onClick={handleSubmit} />
                </div>
              </>
            )}
          </Formik>
        </div>
      </div>
    </Auth>
  );
};

export default ForgotPasswordOtp;
