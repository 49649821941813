import React from "react";

const CreateOfferWrapper = ({ children }) => {
  return (
    <div className="w-full p-2 sm:p-10 bg-[#404759]">
      <p className="text-center text-2xl font-semibold py-3 text-[#78FFD6]">
        Create an Offer to Sell/Buy USDT
      </p>
      <div className="mt-5">
        {children}
      </div>
    </div>
  );
};

export default CreateOfferWrapper;
