import React from "react";
import ReceiverProfile from "./ReceiverProfile";

const ChatList = ({
  myChatGroups,
  activeChat,
  setActiveChat,
  setReceiverId,
  isDeleted,
  setIsDeleted,
  setIsView
}) => {
  return (
    <div className="">
      {myChatGroups?.map((group, index) => (
        <ReceiverProfile
          key={index}
          group={group}
          activeChat={activeChat}
          setActiveChat={setActiveChat}
          setReceiverId={setReceiverId}
          isDeleted={isDeleted}
          setIsDeleted={setIsDeleted}
          setIsView={setIsView}
        />
      ))}
    </div>
  );
};

export default ChatList;
