/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CoinsTable from "../components/common/CoinsTable";
import HowToSell from "../components/common/HowToSell";
import Page from "../components/common/Page";
import Trade from "../components/screens/Trade";
import { Offer } from "../services";
import { getOffer } from "../redux/offer/action";

const SellUSDT = () => {
  const dispatch = useDispatch()
  
  const [buyer, setBuyer] = useState([]);
  const [seller, setSeller] = useState([]);

  const offer = useSelector((state) => state.offer.offer);
  const sellerData = offer.filter((item, index) => item.isBuy);
  const [pakistaniSeller, setPakistaniSeller] = useState([]);

  const user = useSelector((state) => state.user);
  const { country } = user;

  useEffect(() => {
    Offer.getAllOffer()
      .then((res) => {
        dispatch(getOffer(res.data));
      })
      // .catch((e) => console.log("e", e));
  }, []);

  useEffect(() => {
    setSeller(sellerData);
  }, []);

  useEffect(() => {
    if (country?.length) {
      const data = seller.filter(
        (item, index) => item.country.toLowerCase() === country.toLowerCase()
      );
      setPakistaniSeller(data);
    }
  }, [seller]);

  return (
    <Page title="P2Pways – Sell USDT">
      <div className="mx-5 lg:mx-10">
        <Trade page="sell" setBuyer={setBuyer} setSeller={setSeller} />

        {country?.length && (
          <CoinsTable
            heading={`Sell USDT online in ${country}`}
            isSell={true}
            data={pakistaniSeller}
          />
        )}
        <CoinsTable
          heading="Sell USDT online in WorldWide"
          isSell={true}
          data={seller}
        />
      </div>
      <HowToSell header="How to Sell USDT on P2Pways" />
    </Page>
  );
};

export default SellUSDT;
