import React from "react";
import OfferBlock from "../../common/OfferBlock";
import Header from "../../common/ProfileBlock/header";
import Body from "../../common/ProfileBlock/Body";
import { AiOutlineStar } from "react-icons/ai";
import moment from "moment";

const Verification = ({ isVerify }) => {
  return (
    <div className="flex flex-row items-center gap-2">
      <img className="w-8 h-8" src="/images/verify.svg" alt="img" />
      <p className={isVerify ? "text-white text-base" : "text-[#FF0000] text-base"}>{isVerify ? "Account verified" : 'Unverified Account'}</p>
    </div>
  );
};

const AboutSeller = ({ user, data, location }) => {
  const lastSeen = moment(user.lastSeen).fromNow();

  return (
    <div>
      <OfferBlock heading={`About this ${data.isBuy ? "Buyer" : "Seller"}`}>
        <div className="min-h-[220px] relative">
        <Header>
          <div className="flex flex-row justify-between items-center py-2">
            <div className="flex flex-row items-center gap-2">
              <img
                className="w-10 h-10 rounded-full"
                src={user.image ? `${user.image}` :"/images/user_profile.png"}
                alt="img"
              />
              <div>
                <p className="text-base text-[#78FFD6]">
                  {user.firstName} {user.lastName}
                </p>
                <p
                  className={
                    user.isOnline
                      ? "text-sm text-[#78FFD6]"
                      : "text-sm text-white"
                  }
                >
                  {user.isOnline ? "online" : `seen ${lastSeen}`}
                </p>
              </div>
            </div>
            <AiOutlineStar
              style={{ width: "25px", height: "25px", color: "yellow" }}
            />
          </div>
        </Header>
        <Body>
          <div className="flex flex-row justify-between h-full">
            <div className="w-1/2 flex flex-col justify-between gap-2">
              <div>
                <p className="text-[#78FFD6] text-xl">Location</p>
                <p className="text-white text-xl">{location ? location : 'country'}</p>
              </div>
              <div className="absolute bottom-10 left-1/2 transform -translate-x-1/2">
                <Verification isVerify={user.isVerified} />
              </div>
            </div>
            <div className="w-1/2 flex flex-col justify-between gap-2">
              <div className="flex flex-row gap-2">
                {/* <LikeButton />
                <DislikeButton /> */}
              </div>
              <div>
                {/* <Verification text="Number verified" />
                <Verification text="Address verified" /> */}
              </div>
            </div>
          </div>
        </Body>
        </div>
      </OfferBlock>
    </div>
  );
};

export default AboutSeller;
