import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Chat, UserAuth } from "../../../services";
import { io } from "socket.io-client";
import { API_URL } from "../../../lib/config";

const socket = io.connect(API_URL);
// const socket = io.connect("ws://localhost:5001");

const ReceiverProfile = ({ group, activeChat, setActiveChat, setIsView }) => {
  const [user, setUser] = useState({});
  const [chatMessage, setChatMessage] = useState([]);
  const [unread, setUnread] = useState(null);
  const [state, setState] = useState(false);
  const loginUser = useSelector((state) => state.user);
  
  useEffect(() => {
    UserAuth.getSingleUser(
      group.senderId === loginUser.id ? group.receiverId : group.senderId
    )
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
        // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Chat.getMyChat(group._id)
      .then((res) => {
        if (res.status === 200) {
          setChatMessage(res.data);
          const receiver = res.data.filter((chat) => chat.receiverId === loginUser.id)
          const result = receiver.filter(
            (chat) =>
              new Date(chat.createdAt) >
              (group.lastSeen[0].id === loginUser.id
                ? new Date(group.lastSeen[0].time)
                : new Date(group.lastSeen[1].time))
          );
          setUnread(result.length);
        }
      })
      // .catch((e) => console.log("e"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat, state, chatMessage]);

  useEffect(() => {
    socket.on("chat", (payload) => {
      if(payload.groupId === group._id)
      setChatMessage([...chatMessage, payload]);
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat, state, chatMessage]);

  const toPascal = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  useEffect(() => {
    Chat.lastView(group._id, loginUser.id)
    .then((res) => {
    })
    // .catch((e) => console.log('ee', e))
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state])

  const handleChange = (group) => {
    setActiveChat(group);
    setIsView(true);
    setState(!state);

  };

  return (
    <div
      className="w-full min-w-[300px] h-16 flex flex-row justify-between items-center p-2 rounded border-b border-[#222d34] cursor-pointer"
      onClick={() => handleChange(group)}
    >
      <div className="flex flex-row items-center gap-3">
        <img
          className="w-8 h-8 rounded-full border border-white"
          src={
            user.image ? `${user.image}` : "/images/user_profile.png"
          }
          alt="img"
        />
        <div className="text-white">
          <p className="text-base font-bold">
            {user.firstName
              ? `${toPascal(user.firstName)}${" "}${toPascal(user.lastName)}`
              : "User Name"}
          </p>
          <p className="w-[150px] truncate text-sm text-gray-400">
            {chatMessage.length && chatMessage[chatMessage.length - 1]?.message}
          </p>
        </div>
      </div>
      <div className="h-full flex flex-col justify-start items-end gap-1">
        <p className="min-w-[65px] float-right text-[10px] text-gray-400">
          {chatMessage.length &&
            moment(chatMessage[chatMessage.length - 1]?.createdAt).fromNow()}
        </p>
        {!!unread && (
          <div className="w-5 h-5 flex justify-center items-center rounded-full text-white bg-green-800">
            <p className="text-[12px]">{unread > 0 ? unread : null}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default ReceiverProfile;
