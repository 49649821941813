import React, { useState } from "react";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";

const QrCodeModal = ({
  modalIsOpen,
  openModal,
  closeModal,
  afterOpenModal,
  qr,
}) => {
  const navigate = useNavigate()
  const [isTerm, setIsTerm] = useState(false)

  const onNavigate = () => {
    navigate("/login")
  }

  const styles = {
    input:
      "min-w-[15px] h-[15px] form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#78FFD6] checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
    label: "form-check-label inline-block text-white text-[13px]",
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "320px",
          maxWidth: "400px",
          minHeight: "max-content",
          transform: "translate(-50%, -50%)",
          background: "#10192F",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          borderRadius: "8px",
        },
      }}
    >
      <div className=" flex flex-col justify-center items-center gap-2 h-full">
        <div className="flex flex-col justify-center items-center py-8">
        <img className="w-24 h-24" src={qr} alt="qr" />
        <p className="text-center text-[13px] text-white py-4">
          Scan this qr code to get the code that will use in login verification
          otherwise you can't login to your account
        </p>
        </div>
        {/*  */}
        <div className="w-full flex flex-row justify-start gap-2 form-check mx-5">
          <input
            className={styles.input}
            type="checkbox"
            value={isTerm}
            id="flexCheckDefault"
            onChange={() => setIsTerm(!isTerm)}
            checked={isTerm}
          />
          <label className={styles.label} for="flexCheckDefault">
            Download the Google Authenticator app and scan the Qr code
          </label>
        </div>
        <div className=" flex flex-col justify-center items-center">
       
          <div className="flex flex-row justify-center items-center  mt-10 mb-3">
            <button className="send-coins text-[#10192F] rounded px-10 py-1 bg-[#98F18A] disabled:bg-gray-400"
            onClick={() => onNavigate()}
            disabled={!isTerm}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default QrCodeModal;
