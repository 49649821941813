import moment from "moment";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Bids, Offer, UserAuth } from "../../../services";

import { sendBepToken } from "../../../utils/bep_20";

import OfferBlock from "../../common/OfferBlock";
import Page from "../../common/Page";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";
import AboutOffer from "../Offer/AboutOffer";
import Chat from "./Chat";
import TransactionReviews from "./TransactionReviews";
import { getTokenBalance } from "../../../utils/getBalance";
import { getBnbBalance } from "../../../utils/getBnbBalance";
import { depositBNB } from "../../../utils/depositBNB";

const Transaction = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [onUpdateCoin, setOnUpdateCoin] = useState(false);

  const [data, setData] = useState({});
  const [user, setUser] = useState({});
  const loginUser = useSelector((state) => state.user);
  const [senderProfile, setSenderProfile] = useState({});
  const [offer, setOffer] = useState();
  const [isSelected, setIsSelected] = useState(false);
  const [isCoinsReceived, setIsCoinsReceived] = useState(false);
  const [sender, setSender] = useState("");
  const [receiver, setReceiver] = useState("");
  const [modalIsOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [showChat, setShowChat] = useState(true);
  // eslint-disable-next-line no-unused-vars
  const [balance, setBalance] = useState("");
  const [onUpdate, setOnUpdate] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userLastSeen = moment(user.lastSeen).fromNow();
  const senderLastSeen = moment(senderProfile.lastSeen).fromNow();
  const address = useSelector((state) => state.user.address);

  useEffect(() => {
    if (!loginUser.id) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let send_token_amount = data.pay;

  useEffect(() => {
    if (address) {
      getTokenBalance(setBalance, address);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //
  useEffect(() => {
    Bids.getTransactionBid(id).then((res) => {
      setData(res.data);
    });
    // .catch((e) => console.log("e"));
  }, [id, onUpdate, isCoinsReceived]);

  useEffect(() => {
    UserAuth.getSingleUser(data.isBuy ? data.from : data.to).then((res) => {
      setUser(res.data);
    });
    // .catch((e) => console.log("e"));
  }, [data]);

  useEffect(() => {
    UserAuth.getSingleUser(data.isBuy ? data.to : data.from).then((res) => {
      setSenderProfile(res.data);
    });
    // .catch((e) => console.log("e"));
  }, [data]);

  useEffect(() => {
    Offer.getSingleOffer(data.offerId).then((res) => {
      if (res.status === 200) {
        setOffer(res.data);
      }
    });
    // .catch((e) => console.log("e"));
  }, [data.offerId, onUpdate]);

  useEffect(() => {
    UserAuth.getWallet(loginUser.id, loginUser.token)
      .then((res) => {
        setSender(res.data);
      })
      .catch((e) => console.log("e"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loginUser.id === data.from) {
      UserAuth.getWallet(data.to, loginUser.token)
        .then((res) => {
          setReceiver(res.data);
        })
        .catch((e) => console.log("e"));
    } else {
      UserAuth.getWallet(data.from, loginUser.token).then((res) => {
        setReceiver(res.data);
      });
      // .catch((e) => console.log("e"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.from]);

  const giveReceiverBNB = async (item) => {
    const receiverBnbBalance = await getBnbBalance(receiver.address);
    if (receiverBnbBalance < 0.0008) {
      depositBNB(receiver.address);
    }
  };

  const createTransaction = () => {
    giveReceiverBNB();

    Offer.updateOfferOnTransaction(data.offerId, data.pay)
      .then((res) => console.log("offer updated"))
      .catch((e) => {
        console.log("error in updating offer", e);
      });

    Bids.createTransaction(
      data._id,
      sender.userId,
      receiver.userId,
      sender.address,
      receiver.address,
      data.isBuy,
      data.pay
    )
      .then((res) => {
        setOnUpdate(!onUpdate);
      })
      .catch((e) => console.log("t e"))
      .finally(() => setIsLoading(false));

    Bids.emailOnTransaction(senderUser, receiverUser, data.pay)
      .then((res) => {
        console.log("email res");
      })
      .catch((e) => console.log("email err"));

    setTimeout(() => {
      setOnUpdateCoin(!onUpdateCoin);
    }, 15000);
  };

  const handleSend = async () => {
    setIsLoading(true);
    sendBepToken(
      send_token_amount,
      receiver.address,
      sender.address,
      sender.privateKey,
      (status) => {
        if (status) {
          createTransaction();
        } else {
          setIsLoading(false);
        }
      }
    );
  };

  const styles = {
    input:
      "min-w-[15px] h-[15px] form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#78FFD6] checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
    label: "form-check-label inline-block text-white",
  };

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  const senderUser = senderProfile.email;
  const receiverUser = user.email;

  // console.log("receiver", user);

  return (
    <Page onUpdateCoin={onUpdateCoin}>
      <div className="container">
        <div className="p-3 lg:p-10">
          <div className="bg-[#404759]">
            {offer ? (
              <div className="px-5 sm:px-20 py-10">
                <div
                  className={`${
                    showChat
                      ? "flex flex-col md:flex-row  gap-14 justify-between"
                      : "w-full"
                  }`}
                >
                  <div
                    className={`w-full md:w-1/2 flex flex-col justify-between flex-wrap md:flex-nowrap gap-14`}
                  >
                    <div className={`w-full}`}>
                      {!(
                        (loginUser.id === data.from && !data.isBuy) ||
                        (loginUser.id === data.to && data.isBuy)
                      ) ? (
                        <OfferBlock heading="About this Seller">
                          <div className="min-h-[220px] relative">
                            <Header>
                              <div className="flex flex-row justify-between items-center py-2">
                                <div className="flex flex-row items-center gap-2">
                                  <img
                                    className="w-10 h-10 rounded-full"
                                    src={
                                      senderProfile.image
                                        ? `${senderProfile.image}`
                                        : "/images/user_profile.png"
                                    }
                                    alt="img"
                                  />
                                  <div>
                                    <p className="text-base text-[#78FFD6]">
                                      {senderProfile.firstName}{" "}
                                      {senderProfile.lastName}
                                    </p>
                                    <p
                                      className={
                                        senderProfile.isOnline
                                          ? "text-sm text-[#78FFD6]"
                                          : "text-sm text-white text-[12px]"
                                      }
                                    >
                                      {senderProfile.isOnline
                                        ? "online"
                                        : `seen ${senderLastSeen}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Header>
                            <Body>
                              <div className="text-white flex flex-col gap-5">
                                <p className="text-[#78FFD6] text-[18px]">
                                  Wanna Sell{" "}
                                  <span className="text-white">
                                    {data.pay} USDT
                                  </span>
                                </p>
                                <p>Seller will get {offer.price}$ for 1 USDT</p>
                                <p>
                                  Total you will pay{" "}
                                  {(data.pay * offer.price).toFixed(2)} USD
                                </p>
                              </div>
                            </Body>
                          </div>
                        </OfferBlock>
                      ) : (
                        <OfferBlock heading="About this Buyer">
                          <div className="min-h-[220px] relative">
                            <Header>
                              <div className="flex flex-row justify-between items-center py-2">
                                <div className="flex flex-row items-center gap-2">
                                  <img
                                    className="w-10 h-10 rounded-full"
                                    src={
                                      user.image
                                        ? `${user.image}`
                                        : "/images/user_profile.png"
                                    }
                                    alt="img"
                                  />
                                  <div>
                                    <p className="text-base text-[#78FFD6]">
                                      {user.firstName} {user.lastName}
                                    </p>
                                    <p
                                      className={
                                        user.isOnline
                                          ? "text-sm text-[#78FFD6]"
                                          : "text-sm text-white text-[12px]"
                                      }
                                    >
                                      {user.isOnline
                                        ? "online"
                                        : `seen ${userLastSeen}`}
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Header>
                            <Body>
                              <div className="text-white flex flex-col gap-5">
                                <p className="text-[#78FFD6] text-[18px]">
                                  Wanna Buy{" "}
                                  <span className="text-white">
                                    {data.pay} USDT
                                  </span>
                                </p>
                                <p>Buyer will pay {offer.price}$ for 1 USDT</p>
                                <p>
                                  Total you will get{" "}
                                  {(data.pay * offer.price).toFixed(2)} USD
                                </p>
                              </div>
                            </Body>
                          </div>
                        </OfferBlock>
                      )}
                    </div>
                    <div className={`w-full`}>
                      <AboutOffer data={offer} />
                    </div>
                  </div>
                  <div className="w-full md:w-1/2">
                    <Chat
                      user={user}
                      senderProfile={senderProfile}
                      data={data}
                    />
                  </div>
                </div>
                {/*  */}
                <div className="mt-10">
                  {((loginUser.id === data.from && data.isBuy) ||
                    (loginUser.id === data.to && !data.isBuy)) &&
                  !data.isSenderPostedReview ? (
                    <div>
                      <p className="text-white font-bold">
                        Please wait you will recevie coins within 10 mint
                      </p>

                      <div className="flex flex-row gap-2 form-check">
                        <input
                          className={styles.input}
                          type="checkbox"
                          value={isCoinsReceived}
                          id="flexCheckDefault"
                          onChange={() => setIsCoinsReceived(!isCoinsReceived)}
                          checked={isCoinsReceived}
                        />
                        <label className={styles.label} for="flexCheckDefault">
                          Coins have been received
                        </label>
                      </div>
                    </div>
                  ) : (
                    <>
                      {!data.isSenderPostedReview && (
                        <div className="flex flex-row gap-2 form-check">
                          <input
                            className={styles.input}
                            type="checkbox"
                            value={isSelected}
                            id="flexCheckDefault"
                            onChange={() => setIsSelected(!isSelected)}
                            checked={isSelected}
                          />
                          <label
                            className={styles.label}
                            for="flexCheckDefault"
                          >
                            Payment has been received
                          </label>
                        </div>
                      )}
                    </>
                  )}
                </div>
                {/*  */}
                <div className="mt-10">
                  {isSelected && !data.isCompleted && (
                    <p className="text-white">
                      You are{" "}
                      {(loginUser.id === data.from && !data.isBuy) ||
                      (loginUser.id === data.to && data.isBuy)
                        ? "sending"
                        : "receiving"}{" "}
                      {data.pay} USDT
                    </p>
                  )}
                </div>
                {/*  */}
                <div className="mt-10 flex flex-row justify-center">
                  {((loginUser.id === data.from && !data.isBuy) ||
                    (loginUser.id === data.to && data.isBuy)) &&
                  !data.isCompleted ? (
                    <button
                      disabled={!isSelected || isLoading}
                      className="send-coins text-white px-8 py-2 rounded bg-blue-400"
                      onClick={() => handleSend()}
                    >
                      Send Coins
                    </button>
                  ) : ((loginUser.id === data.from && !data.isBuy) ||
                      (loginUser.id === data.to && data.isBuy)) &&
                    data.isCompleted ? (
                    <div className="mt-10 flex flex-col justify-center items-center gap-4">
                      {/* <p className="text-white text-lg text-center">
                        Transaction Completed
                      </p> */}
                      {!data.isSenderPostedReview && (
                        <button
                          className="send-coins px-8 py-2 rounded bg-green-600"
                          onClick={openModal}
                        >
                          Add Review
                        </button>
                      )}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                {/*  */}
                {/* {data.isCompleted && (
                  <p className="text-white text-lg text-center">
                    Transaction Completed
                  </p>
                )} */}
                {/*  */}
                {data.isCompleted && (
                  <div className="mt-10 flex flex-col justify-center items-center gap-4">
                    <p className="text-white text-lg text-center">
                      Transaction Completed
                    </p>
                  </div>
                )}
                {isCoinsReceived &&
                  data.isCompleted &&
                  !data.isReceiverPostedReview && (
                    <div className="mt-10 flex flex-col justify-center items-center gap-4">
                      {
                        <button
                          className="send-coins px-8 py-2 rounded bg-green-600"
                          onClick={openModal}
                        >
                          Add Review
                        </button>
                      }
                    </div>
                  )}
              </div>
            ) : (
              <div className="h-full text-white flex justify-center items-center">
                Loading....
                {/* <img className="w-20 h-20" src="/images/loader.gif" alt="img" /> */}
              </div>
            )}
          </div>
        </div>
        <TransactionReviews
          data={data}
          loginUser={loginUser}
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
          afterOpenModal={afterOpenModal}
          onUpdate={onUpdate}
          setOnUpdate={setOnUpdate}
        />
      </div>
    </Page>
  );
};

export default Transaction;
