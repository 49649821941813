import React, { useState } from "react";

const Pagination = ({
  postsPerPage,
  totalPosts,
  paginate,
  currentPage,
  setCurrentPage,
  // movePrevious,
  // moveNext,
}) => {
  const [startingPage, setStartingPage] = useState(0);
  const [endingPage, setEndingPage] = useState(5);

  const pageNumbers = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const movePrevious = () => {
    if (startingPage >= 5) {
      setStartingPage(startingPage - 5);
      setEndingPage(endingPage - 5);
      setCurrentPage(currentPage - 5)
    }
  };

  const moveNext = () => {
  
    if (endingPage < pageNumbers.length) {
      setStartingPage(startingPage + 5);
      setEndingPage(endingPage + 5);
      setCurrentPage(endingPage + 1)
    }
  };

  const styles = {
    selected:
      "z-10 bg-indigo-100 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer",
    unSelected:
      "bg-white border-gray-500 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium cursor-pointer",
    previous:
      "relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-500 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
    next: "relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-500 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
  };

  return (
    <ul className="max-w-[300px] flex flex-row justify-center items-center flex-wrap sm:max-w-full relative z-0 rounded-md shadow-sm -space-x-px">
      <li
        className={
          (currentPage === 1 || startingPage === 0)
            ? `${styles.previous} cursor-not-allowed`
            : `${styles.previous} cursor-pointer`
        }
        onClick={() => movePrevious()}
      >
        <button
          className={(currentPage === 1 || startingPage === 0) && "cursor-not-allowed"}
          disabled={currentPage === 1}
          
        >
          <span className="sr-only">Previous</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
      {pageNumbers.slice(startingPage, endingPage).map((number, index) => (
        <li
          key={index}
          onClick={() => paginate(number)}
          className={
            number === currentPage ? styles.selected : styles.unSelected
          }
        >
          {number}
        </li>
      ))}
      <li
        className={
          endingPage >= pageNumbers.length
            ? `${styles.next} cursor-not-allowed`
            : `${styles.next} cursor-pointer`
        }
        onClick={() => moveNext()}
      >
        <button
          className={endingPage >= pageNumbers.length && "cursor-not-allowed"}
          disabled={endingPage >= pageNumbers.length}
        >
          <span className="sr-only">Next</span>
          <svg
            className="h-5 w-5"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </li>
    </ul>
  );
};

export default Pagination;
