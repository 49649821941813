import React, { useState, useEffect } from "react";

import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";

import { UserReviews } from "../../../services";
import { useSelector } from "react-redux";
import UserDetail from "../../common/ClientFeedback/UserDetail";

const options = ["All", "Positive", "Negative"];

const Feedback = () => {
  const [option, setOption] = useState(0);
  const [reviews, setReviews] = useState([]);
  const [filterdReviews, setFilteredReviews] = useState([]);

  const user = useSelector((state) => state.user);

  useEffect(() => {
    UserReviews.getAllReview(user.id)
      .then((res) => {
        if (res.status === 200) {
          setReviews(res.data.reviews);
          setFilteredReviews(res.data.reviews);
        }
      })
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleProperties = (index) => {
    setOption(index);
    if (index === 0) {
      setFilteredReviews(reviews);
    }
    if (index === 1) {
      const positive = reviews.filter((review) => review.isPositive);
      setFilteredReviews(positive);
    }
    if (index === 2) {
      const negative = reviews.filter((review) => !review.isPositive);
      setFilteredReviews(negative);
    }
  };

  return (
    <div>
      <div className="">
        <ProfileBlock>
          <Header>
            <div className="flex flex-row justify-between items-center ">
              <div className="flex flex-row items-center gap-6 sm:gap-14 my-2 text-[#6DDABD] font-semibold text-xl">
                Feedback
              </div>
              <div className="hide flex-row items-center gap-3 sm:flex sm:gap-8">
                {options.map((item, index) => (
                  <p
                    className={
                      option === index
                        ? "text-[#6DDABD] font-bold cursor-pointer"
                        : "text-white cursor-pointer"
                    }
                    onClick={() => handleProperties(index)}
                  >
                    {item}
                  </p>
                ))}
              </div>
            </div>
          </Header>
          <Body>
            <div className="max-h-[300px] overflow-scroll scrollbar-hide">
              {!filterdReviews.length ? (
                <div className="flex flex-col justify-center items-center gap-2 py-3">
                  <p className="text-white">This user don,t have any Reviews</p>
                  <img src="/images/profile/empty.png" alt="img" />
                </div>
              ) : (
                <div className="w-full overflow-auto scrollbar-hide">
                  {filterdReviews.map((review, index) => (
                    <div className="w-full flex flex-col sm:flex-row items-start gap-4 mb-6 border-b py-5">
                      <UserDetail review={review} />
                      <p className="text-white">{review.comment}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Body>
        </ProfileBlock>
      </div>
    </div>
  );
};

export default Feedback;
