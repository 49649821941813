import React from "react";

const MainSection = () => {
  return (
    <div className="flex-1 relative py-10">
      <div className="container z-10">
        <div className="flex flex-row justify-between items-center min-h-[300px]">
          <div className="md:w-1/2 text-white flex flex-col gap-5">
            <p className="text-4xl sm:w-4/5 sm:text-5xl text-[#78FFD6] font-bold">
              Buy and Sell USDT Everywhere
            </p>
            <p className="sm:w-9/12 leading-relaxed">
              Buy and sell USDT using over 100+ different payment methods. Get
              your P2Pways account to start accepting payments and sending money
              with 1 million people just like you.
            </p>
            <p>Trade USDT person-to-person in an easy, fast, and secure way.</p>
          </div>
          <div className="hide md:flex md:w-1/2 flex justify-center items-center">
            <img src="/images/main-img.png" alt="img" />
          </div>
        </div>
      </div>
      <div className="screen-circle" />
    </div>
  );
};

export default MainSection;
