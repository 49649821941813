import React from "react";
import { Link } from "react-router-dom";
import { APP_URL } from "../../../lib/config";

const Footer = () => {
  const year = new Date().getFullYear();

  const styles = {
    link: "hover:text-[#D3D3D3]",
  };

  return (
    <div className="relative bg-[#404759]">
      <div className="container py-10">
        <div className="w-full flex flex-row flex-wrap justify-between mb-10">
          <div className="w-full md:w-1/3 flex flex-col items-center">
            <img
              className="w-48 h-40"
              src="/images/footer/logo.png"
              alt="img"
            />
          </div>
          <div className="w-2/5 md:w-1/3 flex flex-col items-center mt-10 md:mt-0">
            <div>
              <h3 className="text-[16px] sm:text-[18px] font-bold text-[#26D1A0]">
                Quick Links
              </h3>
              <div className="flex flex-col gap-4 mt-5 text-[14px] sm:text-[16px] text-white">
                <a
                  className={styles.link}
                  href={`${APP_URL}/buy-usdt`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Buy USDT
                </a>
                <a
                  className={styles.link}
                  href={`${APP_URL}/sell-usdt`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Sell USDT
                </a>
                <a
                  className={styles.link}
                  href={`${APP_URL}/create-offer`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Create Offer
                </a>
                <a
                  className={styles.link}
                  href={`${APP_URL}/contact-us`}
                  target="_blank"
                  rel="noreferrer"
                >
                  Contact Us
                </a>
              </div>
            </div>
          </div>
          <div className="w-3/5 md:w-1/3 flex flex-col mt-10 md:mt-0">
            <div>
              <h3 className="text-[16px] sm:text-[18px] font-bold text-[#26D1A0]">
                Mobile app{" "}
                <span className="text-[12px] text-white">(coming soon)</span>
              </h3>
              <div className="max-w-[320px] my-3">
                <div className="flex flex-col lg:flex-row justify-between gap-2">
                  <a
                    href="https://play.google.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[120px] sm:w-[150px] sm:h-[50px]"
                      src="/images/footer/google-play.png"
                      alt="img"
                    />
                  </a>
                  <a
                    href="https://www.apple.com/app-store"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[120px] sm:w-[150px] sm:h-[50px]"
                      src="/images/footer/app-store.png"
                      alt="img"
                    />
                  </a>
                </div>
                <div className="flex flex-row lg:justify-center mt-2">
                  <a
                    href="https://appgallery.huawei.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      className="w-[120px] sm:w-[150px] sm:h-[50px]"
                      src="/images/footer/app-gallary.png"
                      alt="img"
                    />
                  </a>
                </div>
              </div>
              <h3 className="text-[16px] sm:text-[18px] font-bold text-[#26D1A0]">
                Follow us on
              </h3>
              <div className="flex flex-row gap-4 mt-5">
                <a
                  href="https://www.facebook.com/P2Pways"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="cursor-pointer"
                    src="/images/footer/facebook.svg"
                    alt="img"
                  />
                </a>{" "}
                <a
                  href="https://twitter.com/P2Pways"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="cursor-pointer"
                    src="/images/footer/twitter.svg"
                    alt="img"
                  />
                </a>{" "}
                <a
                  href="https://www.instagram.com/p2pways"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="cursor-pointer"
                    src="/images/footer/instagram.svg"
                    alt="img"
                  />{" "}
                </a>
                <a
                  href="https://www.youtube.com/@P2Pways"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    className="cursor-pointer"
                    src="/images/footer/youtube.svg"
                    alt="img"
                  />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full flex justify-center items-center py-3 mt-10 text-white text-[12px] sm:text-sm absolute left-0 right-0 bottom-0">
        Copyright &copy; {year} P2Pways.
        <a className="underline mx-2">
          <Link to="/terms-conditions">Terms & Conditions</Link>
        </a>
      </div>
    </div>
  );
};

export default Footer;
