import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../components/common/Page";
import AboutOffer from "../components/screens/Offer/AboutOffer";
import AboutSeller from "../components/screens/Offer/AboutSeller";
import BuyQuantity from "../components/screens/Offer/BuyQuantity";
import Condition from "../components/screens/Offer/Condition";
import OfferFeedBack from "../components/screens/Offer/OfferFeedBack";
import { UserAuth } from "../services";

const Offer = () => {
  const navigate = useNavigate()

  const location = useLocation();
  const data = location.state.data;
  const [user, setUser] = useState({});
  const loginUser = useSelector((state) => state.user);

  useEffect(() => {
    if (!loginUser.id) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    UserAuth.getSingleUser(data?.userId?._id)
      .then((res) => {
        setUser(res.data);
      })
      .catch((e) => console.log("e", e.response.data));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <Page title="P2Pways – Offer">
      <div className="container py-10 lg:p-10">
        <div className="bg-[#404759] py-5">
          {(loginUser.id !== data.userId && loginUser.id !== '') && (
            <p className="text-center text-3xl sm:text-4xl text-[#78FFD6]">
              How much do you want to Buy or Sell?
            </p>
          )}
          <div className="flex flex-col gap-10 px-4 sm:px-10 mt-10">
            {(loginUser.id !== data.userId._id && loginUser.id !== '') && (
              <div>
                <BuyQuantity data={data} />
              </div>
            )}
            <div className="flex flex-row flex-wrap md:flex-nowrap justify-between gap-14">
              <div className="w-full md:w-1/2">
                <AboutOffer data={data} />
              </div>
              <div className="w-full md:w-1/2">
                <AboutSeller user={user} data={data} location={data.country} />
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Condition terms={data.terms} />
            </div>
            <div className="flex flex-col gap-2">
              <OfferFeedBack data={data} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Offer;
