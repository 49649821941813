import React from "react";
import OfferBlock from "../../common/OfferBlock";
import Body from "../../common/ProfileBlock/Body";
import { AiOutlineStar } from "react-icons/ai";

const AboutOffer = ({ data }) => {


  return (
    <div>
      <OfferBlock heading="About this Offer">
        <Body>
          <div className="flex flex-col gap-3">
            <div className="flex flex-row justify-between">
              <div>
                <p className="text-[#78FFD6] text-xl">Rate</p>
                <p className="text-white text-[16px]">
                  {data.price.toFixed(2)} USD{" "}
                  <span className="text-[12px] lg:text-[18px] ml-5">
                    {((data.price - 1 / 1) * 100).toFixed(1)}%{" "}
                    {(data.price - 1 / 1) * 100 ? "higher" : "lower"} than
                    market
                  </span>
                </p>
              </div>
              <AiOutlineStar
                style={{ width: "25px", height: "25px", color: "yellow" }}
              />
            </div>
            <div>
              <p className="text-[#78FFD6] text-xl">Limits</p>
              <p className="text-white text-[16px]">
                Min {data.limitFrom} <span className="text-[#78FFD6]">USDT</span>{" "}
                - Max {data.limitTo} <span className="text-[#78FFD6]">USDT</span>
              </p>
            </div>
            <div className="flex flex-row gap-12">
              <div>
                <p className="text-[#78FFD6] text-base sm:text-xl">Trade Time</p>
                <p className="text-white text-[16px]">30 min</p>
              </div>
              <div>
                <p className="text-[#78FFD6] text-base sm:text-xl">P2Pways Fee</p>
                <p className="text-white text-[16px]">1 %</p>
              </div>
            </div>
          </div>
        </Body>
      </OfferBlock>
    </div>
  );
};

export default AboutOffer;
