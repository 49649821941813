import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";
import moment from "moment";
import PhoneVerification from "./PhoneVerification";
import Auth from "../../../services/auth";
import { authenticatorStatus } from "../../../redux/user/action";
import { toast } from "react-toastify";


const LeftBlock = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.user);
  const joining = moment(user.createdAt).fromNow();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isOtpInput, setisOtpInput] = useState(false);

  function openModal() {
    setIsOpen(true);
    // setisOtpInput(false)
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
    // setisOtpInput(false)
  }

  const handleAuthenticatorStatus = () => {
    Auth.changeAuthenticatorStatus(
      user.email,
      user.authenticatorStatus ? false : true
    )
      .then((res) => {
        console.log("res", res);
        if (res.data.isAuthenticatorEnable) {
          dispatch(authenticatorStatus(true));
          toast.success("Authenticator verification Enabled");
        } else {
          dispatch(authenticatorStatus(false));
          toast.success("Authenticator verification Disabled");
        }
      })
      .catch((e) => {
        console.log('e', e)
        toast.error("Error");
      });
  };

  return (
    <div className="max-w-[425px] flex flex-row flex-wrap md:flex-col gap-8">
      <ProfileBlock>
        <Header>
          <p className="text-xl py-2 text-[#6DDABD] font-semibold">
            Verification
          </p>
        </Header>
        <Body>
          <div className="flex flex-row gap-2 mb-2">
            <img src="/images/profile/phone.svg" alt="img" />
            <p
              className={
                user.isPhoneVerified
                  ? "text-white text-sm"
                  : "text-[#FF7A7A] text-sm"
              }
            >
              {user.isPhoneVerified ? (
                <span>Phone verified</span>
              ) : (
                <span className="cursor-pointer" onClick={openModal}>
                  Verify your phone
                </span>
              )}
            </p>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <img src="/images/profile/mail.svg" alt="img" />
            <p
              className={
                !user.isVerified
                  ? "text-[#FF7A7A] text-sm"
                  : "text-white text-sm"
              }
            >
              {user.isVerified ? "Email verified" : "Verify your email"}
            </p>
          </div>
          <div className="flex flex-row gap-2 mb-2">
            <img src="/images/profile/mail.svg" alt="img" />
            <p
              className={
                !user.authenticatorStatus
                  ? "text-[#FF7A7A] text-sm "
                  : "text-white text-sm "
              }
            >
              {user.authenticatorStatus
                ? "Disable Authenticator"
                : "Enable Authenticator"}
            </p>
            <label
              for="small-toggle"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                onClick={() => handleAuthenticatorStatus()}
                id="small-toggle"
                className="sr-only peer"
              />
              <div className="w-9 h-5 absolute bg-gray-200 peer-focus:outline-none peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:bg-[#4e4444] peer-checked:after:bg-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-[#6DDABD]"></div>
            </label>
          </div>
        </Body>
      </ProfileBlock>
      <ProfileBlock>
        <Header>
          <div className="flex flex-row justify-between items-center py-2">
            <p className="text-xl text-[#6DDABD] font-semibold">Info</p>
            <p className="text-[#ABABAB] text-[13px] font-normal">
              Joined {joining}
            </p>
          </div>
        </Header>
        <Body>
          <div className="flex flex-row gap-10 mb-2">
            <p className="text-white text-md w-[100px]">Location: </p>
            <span className="text-[#ABABAB] text-[13px]">
              {user?.country.toUpperCase()}
            </span>
          </div>
          <div className="flex flex-row gap-10 mb-2">
            <p className="text-white text-md w-[100px]">Language: </p>
            <span className="text-[#ABABAB] text-[13px]">ENGLISH</span>
          </div>
          <div className="flex flex-row gap-10 mb-2">
            <p className="text-white text-md w-[100px]">Trade: </p>
            <span className="text-[#ABABAB] text-[13px]">USDT</span>
          </div>
        </Body>
      </ProfileBlock>
      <PhoneVerification
        userId={user.id}
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        afterOpenModal={afterOpenModal}
        isOtpInput={isOtpInput}
        setisOtpInput={setisOtpInput}
      />
    </div>
  );
};

export default LeftBlock;
