import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsBell } from "react-icons/bs";
import { useSelector, useDispatch } from "react-redux";
import {
  logout,
  onHideBalance,
  onHideBnbBalance,
  onRefresh,
} from "../../../redux/user/action";
import { Bids, Chat, UserAuth } from "../../../services";
import { toast } from "react-toastify";
import Notification from "../Notification";
import { getTokenBalance } from "../../../utils/getBalance";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import Auth from "../../../services/auth";
import { getBnbBalance } from "../../../utils/getBnbBalance";
import { admin_address, admin_private_key } from "../../../config/admin";

const data = [
  { icon: "/images/navbar/profile.svg", label: "Profile", link: "/profile" },
  {
    icon: "/images/navbar/setting.svg",
    label: "Account Setting",
    link: "/account-setting",
  },
  {
    icon: "/images/navbar/security.svg",
    label: "Account Security",
    link: "/account-security",
  },
];

const Navbar = ({ onUpdateCoin }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [isMenu, setIsMenu] = useState(false);
  const [balance, setBalance] = useState("");
  const [notification, setNotification] = useState([]);
  const [notify, setNotify] = useState(0);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(true);
  const [unread, setUnread] = useState([]);
  const [bnbBalance, setBnbBalance] = useState("");

  const user = useSelector((state) => state.user);
  const { email, token, isHide, isHideBnb } = user;
  localStorage.setItem("token", token);


  useEffect(() => {
    Auth.getUserByEmail(email, token)
      .then((res) => {
        dispatch(onRefresh(res.data));
      })
      .catch((e) => {
        dispatch(logout());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const closeHandler = () => {
    setIsOpen(false);
  };

  const closeNotifyHandler = () => {
    setIsNotificationOpen(false);
  };

  const wrapperRef = useRef(null);
  const notifyRef = useRef(null);
  const menuRef = useRef(null);

  useOutsideAlerter(wrapperRef);
  useOutsideNotifyAlerter(notifyRef);
  useOutsideMenuAlerter(menuRef);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeHandler();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideNotifyAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closeNotifyHandler();
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  function useOutsideMenuAlerter(ref) {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          setIsMenu(false);
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth > 1024) {
        setShow(false);
      } else {
        setShow(true);
        setIsOpen(false);
      }
    }
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    function getUserBalance() {
      if (user.address) {
        getTokenBalance(setBalance, user.address);
      }
    }
    getUserBalance();
    const interval = setInterval(() => getUserBalance(), 5000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onUpdateCoin]);

  const onReadNotification = () => {
    Bids.readNotification()
      .then((res) => setUpdate(!update))
      .catch((err) => console.log("e"));
  };

  useEffect(() => {
    function getNotify() {
      Bids.getAllOffer(user.id).then((res) => {
        const result = res.data.filter(
          (item) =>
            (user.id === item.to &&
              !item.isReceiverDeleted &&
              !item.isCompleted &&
              !item.isRejected) ||
            (user.id === item.from &&
              !item.isSenderDeleted &&
              !item.isCompleted &&
              !item.isSenderViewRejectedPost &&
              (item.isApproved || item.isRejected))
        );
        const notific = res.data.filter(
          (res) => user.id === res.to || user.id === res.from
        );
        setNotify(result.length);
        setNotification([...notific].reverse());
      });
      // .catch((e) => console.log("e", e));
    }
    getNotify();
    const interval = setInterval(() => getNotify(), 10000);
    return () => {
      clearInterval(interval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [update]);

  useEffect(() => {
    Chat.unreadMessages(user.id).then((res) => {
      if (res.status === 200) {
        const result = res.data.filter(
          (chat) =>
            new Date(chat.createdAt) >
            (chat?.groupId?.lastSeen[0]?.id === user.id
              ? new Date(chat?.groupId?.lastSeen[0]?.time)
              : new Date(chat?.groupId?.lastSeen[1]?.time))
        );
        setUnread(result);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLogout = () => {
    UserAuth.logout(user.id).then((res) => {
      if (res.status === 200) {
        dispatch(logout());
        toast.success("logout successfully");
        navigate("/login");
      }
    });
    // .catch((e) => console.log("e", e));
  };

  const handleHideBalance = () => {
    dispatch(onHideBalance());
  };

  const handleHideBnbBalance = () => {
    dispatch(onHideBnbBalance());
  };

  const styles = {
    link: "hide text-white md:text-[12px] lg:text-[16px] lg:block hover:text-[#78FFD6]",
    linkText: "text-white-700 block px-4 py-2 text-sm",
  };

  async function getBNB() {
    const bal = await getBnbBalance(admin_address);
    setBnbBalance(bal);
  }
  getBNB();

  return (
    <div className="w-full h-16 min-h-14 px-5 py-1 bg-[#10192F] border-b border-[#E7E8EA] relative">
      <div className="container w-full h-full flex flex-row justify-between items-center">
        <div className="w-1/2 flex flex-row items-center gap-5">
          <Link to="/">
            <div className="flex justify-center items-center font-bold text-2xl md:mr-10 ">
              <img
                className="app-logo w-[180px] h-[60px] "
                src="/images/navbar/logo.png"
                alt="img"
              />
              {/* P2Pways */}
            </div>
          </Link>
          {/* <button className="text-white" onClick={() => deposit()}>
            abc
          </button> */}
          <Link className={styles.link} to="/buy-usdt">
            Buy USDT
          </Link>
          <Link className={styles.link} to="/sell-usdt">
            Sell USDT
          </Link>
          {!!user.id && (
            <>
              <Link className={styles.link} to="/create-offer">
                Create Offer
              </Link>
              <Link className={styles.link} to="/withdraw">
                Withdraw
              </Link>
            </>
          )}
          {user.isAdmin && (
            <Link className={styles.link} to="/all-users">
              All Users
            </Link>
          )}
        </div>
        <div className="w-1/2 flex flex-row justify-end items-center gap-5 sm:gap-10 h-full">
          {!user.id ? (
            <div className="hidden lg:flex flex-row items-center gap-5 sm:gap-10">
              <Link to="/signup">
                <button className="bg-transparent text-[12px] sm:text-[18px] text-white">
                  Register
                </button>
              </Link>
              <Link to="/login">
                <button className="border text-white text-[12px] sm:text-[18px] px-3 sm:px-5 py-1">
                  Log in
                </button>
              </Link>
            </div>
          ) : (
            <div className="flex flex-row items-center gap-5 sm:gap-10 h-full">
              {/* BNB */}
              {!!user.isAdmin && (
                <div className="coin-block flex flex-row items-center gap-2 text-[16px] text-white">
                  {isHideBnb ? `${bnbBalance}` : "0"} BNB
                  {!isHideBnb ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBnbBalance()}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBnbBalance()}
                    />
                  )}
                </div>
              )}
              {/* coins */}
              <div className="coin-block flex flex-row items-center gap-2 text-[16px] text-white">
                {isHide ? `${Number(balance).toFixed(4)}` : ""} USDT
                {!isHide ? (
                  <AiOutlineEye
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHideBalance()}
                  />
                ) : (
                  <AiOutlineEyeInvisible
                    style={{ cursor: "pointer" }}
                    onClick={() => handleHideBalance()}
                  />
                )}
              </div>
              {/* inbox */}
              <div className="h-full flex justify-center items-center relative cursor-pointer">
                <Link to="/inbox">
                  <img className="w-7 h-7" src="/images/inbox.svg" alt="img" />
                  {!!unread.length && (
                    <div className="absolute top-1 -right-1 sm:-right-2 bg-[#FF0000] rounded-full w-4 h-4 sm:w-5 sm:h-5 flex justify-center items-center">
                      <p className="text-white text-sm">{unread.length}</p>
                    </div>
                  )}
                </Link>
              </div>
              {/* notification */}
              <div
                ref={notifyRef}
                className="mr-10 sm:mr-14 lg:mr-0 notify h-full flex justify-center items-center relative"
              >
                <div
                  onClick={() => {
                    setIsNotificationOpen(!isNotificationOpen);
                    onReadNotification();
                  }}
                  className="cursor-pointer"
                >
                  <BsBell
                    className="w-7 h-7"
                    style={{
                      // width: "26px",
                      // height: "30px",
                      color: "white",
                      cursor: "pointer",
                    }}
                  />
                  {notify > 0 && (
                    <div className="absolute top-1 -right-1 sm:-right-2 bg-[#FF0000] rounded-full w-4 h-4 sm:w-5 sm:h-5 flex justify-center items-center">
                      <p className="text-white text-sm">{notify}</p>
                    </div>
                  )}
                </div>
                {isNotificationOpen && (
                  <div className="notification-block flex w-80 max-h-[350px] overflow-auto flex-col bg-white absolute top-[60px] -right-10 border border-[#78FFD6] z-50">
                    <div className="flex justify-center items-center p-5 text-white bg-[#104A51] ">
                      Notification
                    </div>
                    <div className="bg-[#10192F]">
                      {notification.length > 0 ? (
                        notification.map((item, index) => (
                          <div key={index}>
                            <Notification
                              item={item}
                              update={update}
                              setUpdate={setUpdate}
                              setIsNotificationOpen={setIsNotificationOpen}
                            />
                          </div>
                        ))
                      ) : (
                        <div className="text-center text-[#9D9D9D] py-10 text-[12px]">
                          No notification
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* profile */}
              <div
                ref={wrapperRef}
                className="hide lg:flex profile h-full flex justify-center items-center"
              >
                <div
                  onClick={() => setIsOpen(!isOpen)}
                  className=" flex flex-row items-center text-sm font-medium  focus:outline-none cursor-pointer"
                >
                  <img
                    className="w-8 h-8 rounded-full border border-[#78FFD6]"
                    src={
                      user.image ? `${user.image}` : "/images/user_profile.png"
                    }
                    alt="img"
                  />
                  <svg
                    className="-mr-1 ml-2 h-5 w-5"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fill="white"
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                {isOpen && (
                  <div className="flex min-w-[250px] bg-rose-500 absolute right-0 top-[65px] z-10">
                    <div className="w-full flex flex-col justify-between gap-4 p-3 bg-[#10192F] border border-[#78FFD6]">
                      <div className="flex flex-row items-center gap-2">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            user.image
                              ? `${user.image}`
                              : "/images/user_profile.png"
                          }
                          alt="img"
                        />
                        <div>
                          <p className="text-base text-white">
                            {user.firstName ? user.firstName : "User"}{" "}
                            {user.lastName ? user.lastName : "Name"}
                          </p>
                          <p className="text-sm text-[#9D9D9D] truncate">
                            {user.email.length ? user.email : "user@gmail.com"}
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-col my-3 gap-2">
                        {data.map((d, index) => (
                          <Link className={styles.link} to={d.link}>
                            <div className="flex flex-row items-center gap-3">
                              <img className="w-5 h-5" src={d.icon} alt="img" />
                              {d.label}
                            </div>
                          </Link>
                        ))}
                      </div>
                      <div>
                        <div
                          className="flex flex-row items-center gap-3 text-white hover:text-[#78FFD6] cursor-pointer"
                          onClick={() => handleLogout()}
                        >
                          <img
                            className="w-4 h-4"
                            src="/images/navbar/logout.svg"
                            alt="img"
                          />
                          Logout
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {/* menu icon */}
        <div ref={menuRef}>
          <div
          //  ref={menuRef}
          >
            {!isMenu ? (
              <img
                className="w-6 h-6 absolute right-5 lg:hidden cursor-pointer top-1/2  transform -translate-y-1/2"
                src="/images/menu.svg"
                alt="icon"
                onClick={() => setIsMenu(true)}
              />
            ) : (
              <img
                // ref={menuRef}
                className="w-6 h-6 absolute right-5 lg:hidden cursor-pointer top-1/2  transform -translate-y-1/2"
                src="/images/cross.svg"
                alt="icon"
                onClick={() => setIsMenu(false)}
              />
            )}
          </div>
          {/* )} */}
          {/* mobile menu */}
          {isMenu && show && (
            <div
              // ref={menuRef}
              className="origin-top-right top-[55px] bg-white z-50 text-black absolute right-0 mt-2 w-[250px] flex flex-col gap-4 pl-5 p-2 min-h-fit"
            >
              {/*  */}
              {!user.id && (
                <div className="flex flex-row items-center gap-5">
                  <Link to="/signup">
                    <button className="bg-transparent text-[12px] sm:text-[18px] text-black">
                      Register
                    </button>
                  </Link>
                  <Link to="/login">
                    <button className="border text-black text-[12px] sm:text-[18px] px-3 sm:px-5 py-1">
                      Log in
                    </button>
                  </Link>
                </div>
              )}
              {/* coins */}
              {user.id && (
                <div className="coin-block-mobile flex flex-row items-center gap-2 text-[16px] py-1 text-black">
                  {isHide ? `${Number(balance).toFixed(4)}` : ""}
                  USDT
                  {!isHide ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBalance()}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBalance()}
                    />
                  )}
                </div>
              )}
              {/* BNB */}
              {!!user.isAdmin && (
                <div className="coin-block-mobile flex flex-row items-center gap-2 border-b  text-[16px] py-1 text-black">
                  {isHideBnb ? `${bnbBalance}` : "0"} BNB
                  {!isHideBnb ? (
                    <AiOutlineEye
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBnbBalance()}
                    />
                  ) : (
                    <AiOutlineEyeInvisible
                      style={{ cursor: "pointer" }}
                      onClick={() => handleHideBnbBalance()}
                    />
                  )}
                </div>
              )}
              <Link to="/buy-usdt">Buy USDT</Link>
              <Link to="/sell-usdt">Sell USDT</Link>
              {!!user.id && (
                <>
                  <Link to="/create-offer">Create Offer</Link>
                  <Link to="/withdraw">Withdraw</Link>
                  <Link to="/profile">Profile</Link>
                  <Link to="/account-setting">Account Setting</Link>
                  <Link to="/account-security">Account Security</Link>
                </>
              )}
              {user.isAdmin && <Link to="/all-users">All Users</Link>}
              {!!user.id && (
                <div
                  className="flex flex-row items-center gap-3 text-black cursor-pointer"
                  onClick={() => handleLogout()}
                >
                  Logout
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Navbar;

// const result = res.data.filter(
//   (res) => {
//     if (
//       user.id === res.to &&
//       !res.isReceiverDeleted &&
//       !res.isCompleted
//     ) {
//       return;
//     } else if (
//       user.id === res.from &&
//       !res.isSenderDeleted &&
//       res.isApproved &&
//       !res.isCompleted
//     ) {
//     } else {
//       return;
//     }
//   }
// );
