import axios from "axios";
import { API_URL } from "../lib/config";

const Seller = {
  createSeller: (
    userId,
    name,
    country,
    price,
    limitFrom,
    limitTo,
    paymentMethod = "Stripe"
  ) => {
    return axios
      .post(`${API_URL}/v1/seller/create`, {
        userId,
        name,
        paymentMethod,
        country,
        price,
        limitFrom,
        limitTo,
      })
      .then((res) => res);
  },

  getAllSeller: () => {
    return axios.get(`${API_URL}/v1/seller/getAll`).then((res) => res);
  },

  getSingleSeller: (id) => {
    return axios.get(`${API_URL}/v1/seller/get/${id}`).then((res) => res);
  },
};

export default Seller;
