import React, { useState, useEffect } from "react";
import Auth from "../../common/Auth";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as yup from "yup";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import { login } from "../../../redux/user/action";
import ReCAPTCHA from "react-google-recaptcha";

// 6LdRVeUhAAAAAKCGdbge0Jxaf9zCDvbqGnDC_eZB
// 6LdRVeUhAAAAACn1Jsg1fgMNNsaobl9-514fBvuj

const VerifyAuthenticator = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [state, setState] = useState(false);
  const [timer, setTimer] = useState(60);
  const code = localStorage.getItem("qr");
  const email = localStorage.getItem("email");
  const token = localStorage.getItem("token");
  // eslint-disable-next-line no-unused-vars
  const [qr, setQr] = useState(code);
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginUser = useSelector((state) => state.user);

  useEffect(() => {
    if(loginUser.id){
      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    if (timer > 0 && state) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setState(false);
    }
  }, [timer, state]);

  useEffect(() => {
    QRCode.toDataURL(code)
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (value) => {
    if (value !== null) setIsCaptchaVerified(true);
    else setIsCaptchaVerified(false);
  };

  const handleRecoverKey = () => {
    UserAuth.recoveryKey(email)
      .then((res) => {
        toast.success("Secret key send to your email");
        toast.success(
          "Please check spam or junk email if you did not receive in your inbox"
        );
      })
      .catch((e) => {
        toast.error("Error in getting recovery key");
      });
  };


  return (
    <Auth>
      <div className="flex flex-col justify-center items-center p-5 h-full">
        <div className="flex flex-col justify-evenly items-center p-3 h-5/6 md:mx-10">
          <p className="font-bold text-xl mt-5 text-blue-600">
            Verify Your Authenticator Code
          </p>
          {/* <img className="w-24 h-24" src={qr} alt="qr" /> */}

          <Formik
            validateOnChange={false}
            initialValues={{
              OTP: "",
            }}
            onSubmit={(values) => {
              setIsLoading(true);
              UserAuth.verifyToken(email, values.OTP)
                .then((res) => {
                  console.log("res", res);
                  if (res.status === 200) {
                    // dispatch(login(res.data.updateUser));
                    dispatch(login(res.data.updateUser, token));

                    toast.success("Login successfully.");
                    navigate("/");
                  } else {
                    toast.error("Invalid OTP");
                  }
                })
                .catch((e) => {
                  toast.error("Enter valid code");
                })
                .finally(() => setIsLoading(false));
            }}
            validationSchema={yup.object().shape({
              OTP: yup.string().min(6).required("Code is required"),
            })}
          >
            {({ handleChange, handleSubmit, ...props }) => (
              <form onSubmit={handleSubmit}>
                <div className="min-h-[80px]">
                  <div className="w-full py-8">
                    <p className="text-left font-bold">Enter Code</p>
                  </div>
                  <OtpInput
                    containerStyle="gap-3 sm:gap-5"
                    inputStyle="border-2 border-[#00739D] min-w-[36px] min-h-[40px] rounded-xl focus:outline-none"
                    value={props.values.OTP}
                    onChange={handleChange("OTP")}
                    numInputs={6}
                    isInputNum={true}
                    isInputSecure={false}
                  />
                  {props.touched.OTP && props.errors.OTP && (
                    <div className="text-red-600 mt-2 text-[12px]">
                      {props.errors.OTP}
                    </div>
                  )}
                  <div className="w-full text-center">
                    <p
                      className="text-blue-600 text-[13px] mt-3 cursor-pointer"
                      onClick={() => handleRecoverKey()}
                    >
                      Get recovery key
                    </p>
                  </div>
                </div>
                <ReCAPTCHA
                  sitekey="6LdRVeUhAAAAAKCGdbge0Jxaf9zCDvbqGnDC_eZB"
                  onChange={onChange}
                />
              <div className="flex justify-center items-center mt-20">
                  <input
                    type="submit"
                    className="flex flex-row items-center text-black rounded-lg gap-3 px-20 py-2 bg-[#78FFD6] disabled:bg-slate-400 disabled:cursor-not-allowed"
                    disabled={isLoading || !isCaptchaVerified}
                    value="Confirm"
                  />
                  {/* <SearchButton
                    text="Confirm"
                    onClick={handleSubmit}
                    disabled={!isCaptchaVerified}
                  /> */}
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </Auth>
  );
};

export default VerifyAuthenticator;
