import React from "react";
import {  useNavigate } from "react-router-dom";
import Auth from "../../common/Auth";
import SearchButton from "../../common/SearchButton";
import { Formik } from "formik";
import * as yup from "yup";
import InputField from "../../common/InputField";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import {  register } from "../../../redux/user/action";
import { AiOutlineArrowLeft } from "react-icons/ai";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loginSchema = yup.object().shape({
    Email: yup.string().required("Email is required"),
  });

  const handleBack = () => navigate("/login");

  return (
    <Auth>
      <div className="flex flex-col justify-center p-5 h-full">
        <Formik
          validateOnChange={false}
          initialValues={{
            Email: "",
          }}
          onSubmit={(values) => {
            UserAuth.forgotPassword(values.Email)
              .then((res) => {
                if (res.status === 200) {
                  dispatch(register(values.Email));
                  toast.success(
                    "Verification code has been sent to your email."
                  );
                  navigate("/forgot-password-otp");
                } else {
                  toast.error(res.data);
                }
              })
              .catch((e) => {
                toast.error(e.response.data);
              });
          }}
          validationSchema={loginSchema}
        >
          {({ handleChange, handleSubmit, ...props }) => (
            <div className="flex flex-col justify-evenly items-center h-5/6 mx-0 sm:mx-5 lg:mx-10">
              <div className="w-full relative">
                <p className="font-bold text-xl mt-5 text-blue-600 text-center">
                  Forgot Password
                </p>
                <AiOutlineArrowLeft
                  onClick={handleBack}
                  style={{
                    color: "black",
                    width: "50px",
                    cursor: "pointer",
                    position: "absolute",
                    left: 10,
                    bottom: 0,
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
              <div className="w-full max-w-[500px]">
                <div className="flex flex-col gap-3 w-full">
                  <InputField
                    type="Email"
                    placeholder="Email"
                    value={props.values.Email}
                    onChange={handleChange("Email")}
                    isInvalid={props.touched.Email && props.errors.Email}
                    error={props.errors.Email}
                  />
                </div>
              </div>
              <div>
                <SearchButton text="Submit" onClick={handleSubmit} />
              </div>
            </div>
          )}
        </Formik>
      </div>
    </Auth>
  );
};

export default ForgotPassword;
