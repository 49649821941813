import { GET_SELLER } from './types';

const initialState = {
  seller: []
};

const SellerReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELLER:
      return {
        ...state,
        seller: action.payload
      };

    default:
      return state;
  }
};

export default SellerReducer;
