import React, { useEffect } from "react";
import { Link } from "react-router-dom";

const OtherSetting = ({
  terms,
  setTerms,
  // country,
  // setCountry,
  isTerm,
  setIsTerm,
  setSelected,
}) => {
  const styles = {
    input:
      "min-w-[15px] h-[15px] form-check-input h-4 w-4 border border-gray-300 rounded-sm bg-white checked:bg-[#78FFD6] checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer",
    label: "form-check-label inline-block text-white",
  };

  useEffect(() => {
    //  👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  return (
    <div className="flex flex-col gap-10">
      <div className="flex flex-col gap-2">
        <p className="text-xl text-[#78FFD6]">Offer Terms</p>
        <textarea
          className="w-full rounded p-2 focus:bg-white focus:outline-none"
          placeholder="Offer Terms"
          value={terms}
          onChange={(e) => setTerms(e.target.value)}
          rows={2}
        />
      </div>
      <div className="flex flex-col gap-2">
        <p className="text-xl text-[#78FFD6]">Terms & Conditions</p>
        <div className="flex flex-row gap-2 form-check mx-5">
          <input
            className={styles.input}
            type="checkbox"
            value={isTerm}
            id="flexCheckDefault"
            onChange={() => setIsTerm(!isTerm)}
            checked={isTerm}
          />
          <label className={styles.label} for="flexCheckDefault">
          I am accepting P2Pways 
          <a className="underline mx-2">
          <Link to="/terms-conditions">Terms & Conditions</Link>
        </a>          
          </label>
        </div>
      </div>
    </div>
  );
};

export default OtherSetting;
