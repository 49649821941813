import React from "react";

const TransferWays = ({ index, heading, detail }) => {
  return (
    <div key={index} className="card relative w-80 h-80  rounded-[20px] border-2 border-[#78FFD6] p-2 bg-blocks ">
     <div className="content h-full flex flex-col justify-center items-center gap-3">
      <img className="w-20 h-20" src={`/images/ways/${index + 1}.svg`} alt='img' />
      <p className="text-2xl font-bold text-center text-white">{heading}</p>
      <p className="text-center text-white">{detail}</p>
      </div>
    </div>
  );
};

export default TransferWays;
