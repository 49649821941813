import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Page from "../components/common/Page";
import AboutUser from "../components/screens/ViewProfile";
import ProfileRightBlock from "../components/screens/ViewProfile/ProfileRightBlock";

const ViewProfile = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userData = location?.state?.userData;
  const user = useSelector((state) => state.user);

  const name = userData.firstName + " " + userData.lastName;

  useEffect(() => {
    if (!user.id) {
      navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="P2Pways – Profile">
      <div className="flex-1 min-h-screen px-5 bg-[#10192F]">
        <div className="container">
          {/* profile */}
          <div className="flex flex-row items-center flex-wrap sm:flex-nowrap gap-5 pt-14">
            <div className="flex flex-row items-center flex-wrap sm:flex-nowrap gap-5 pt-14">
              <div className="min-w-full flex justify-start sm:min-w-[120px] md:min-w-[220px]">
                <img
                  className="w-[120px] h-[120px] md:w-[220px] md:h-[220px] rounded-full border border-[#78FFD6]"
                  src={
                    userData.image
                      ? `${userData.image}`
                      : "/images/user_profile.png"
                  }
                  alt="img"
                />
              </div>
              <div>
                <p className="text-2xl text-[#78FFD6] font-bold">
                  {userData.firstName ? name.toUpperCase() : "User Name"}
                </p>
                <p className="text-white">
                  {userData.bio ? userData.bio : `Your message in Bio`}
                </p>
              </div>
            </div>
            <div>
              {!!user.isAdmin && (
                <div>
                  {!userData.isBlock ? (
                    <div
                      className="w-[120px] flex justify-center items-center font-medium text-white bg-black rounded-md px-5 py-2 cursor-pointer"
                      // onClick={() => handleBlock(userData._id)}
                    >
                      UnBanned
                    </div>
                  ) : (
                    <div
                      className="w-[120px] flex justify-center items-center font-medium text-white bg-[#FF0000] rounded-md px-5 py-2 cursor-pointer hover:bg-blue-600"
                      // onClick={() => handleUnBlock(userData._id)}
                    >
                      Banned
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          {/* user */}

          {/* detail */}
          <div className="flex flex-col md:flex-row gap-8 py-20">
            <div className="w-full sm:w-[350px]">
              <AboutUser user={userData} />
            </div>
            <div className="w-full">
              <ProfileRightBlock user={userData} />
            </div>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default ViewProfile;
