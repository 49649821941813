import { binance_provider, usdt_contract_address } from "../lib/config";

const Web3 = require("web3");

// const web3 = new Web3(
//   new Web3.providers.HttpProvider(quick_node_provider)
// );

const web3 = new Web3(new Web3.providers.HttpProvider(binance_provider));

const balanceOfABI = [
  {
    constant: true,
    inputs: [
      {
        name: "_owner",
        type: "address",
      },
    ],
    name: "balanceOf",
    outputs: [
      {
        name: "balance",
        type: "uint256",
      },
    ],
    payable: false,
    stateMutability: "view",
    type: "function",
  },
];

const tokenContract = usdt_contract_address;

const contract = new web3.eth.Contract(balanceOfABI, tokenContract);

export async function getTokenBalance(setState, address) {
  const result = await contract.methods.balanceOf(address).call();
  const formattedResult = web3.utils.fromWei(result, "ether");
  // console.log("bal", formattedResult);
  return setState(formattedResult);
}

// const tokenContract = "0xed24fc36d5ee211ea25a80239fb8c4cfd80f12ee";
// const tokenContract = "0x55d398326f99059fF775485246999027B3197955";
