import React from "react";
import { BiShowAlt, BiHide } from "react-icons/bi";

const InputField = ({
  type,
  placeholder,
  value,
  onChange,
  error,
  state,
  setState,
  isPassword
}) => {
  const styles = {
    input:
      "w-full mt-6 border-b border-[#313131] text-black py-3 focus:outline-none placeholder-black bg-transparent",
    passwordInput:
      "w-full pr-14 text-black focus:outline-none placeholder:text-gray-800 placeholder:text-sm bg-transparent",
  };
  return (
    <div className="w-full h-16">
      <label className={`${styles.input} relative`}>
        <input
          type={type}
          className={styles.passwordInput}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
        {isPassword && (
          <span className="absolute right-0">
            {!state ? (
              <BiShowAlt onClick={() => setState(!state)} />
            ) : (
              <BiHide onClick={() => setState(!state)} />
            )}
          </span>
        )}
      </label>
      <p className="text-red-600 mt-3 text-[12px]">{error}</p>
    </div>
  );
};

export default InputField;
