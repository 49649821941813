import React, { useEffect } from "react";
import { BiInfoCircle } from "react-icons/bi";
import Page from "../components/common/Page";
import AccountInput from "../components/screens/Account/AccountInput";
import { Formik } from "formik";
import * as yup from "yup";
import { toast } from "react-toastify";
import { UserAuth } from "../services";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../redux/user/action";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  Password: yup.string().required("Password is required"),
  NewPassword: yup
    .string()
    .min(8)
    .required("New Password is required")
    .test(
      "regex",
      "A combination of uppercase letters,lowercase letters, numbers and symbols.",
      (val) => {
        const regExp = new RegExp(
          "^(?=.*\\d)(?=.*[!@#$_-])(?=.*[a-z])(?=.*[A-Z]).{8,}$"
        );
        console.log(regExp.test(val), regExp, val);
        return regExp.test(val);
      }
    )
    ,
  ConfirmPassword: yup
    .string()
    .min(8)
    .required("Confirm Password is required")
    .oneOf([yup.ref("NewPassword")], "Confirm Password should be same."),
});

const AccountSecurity = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user.id) {
      navigate("/login");
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Page title="P2Pways – Account Security">
      <Formik
        validateOnChange={false}
        initialValues={{
          Password: "",
          NewPassword: "",
          ConfirmPassword: "",
        }}
        onSubmit={(values) => {
          UserAuth.updatePassword(
            user.email,
            values.Password,
            values.NewPassword
          )
            .then((res) => {
              if (res.status === 200) {
                toast.success("update successfully");
                dispatch(logout());
                navigate("/login");
              }
            })
            .catch((e) => {
              toast.error(e.response.data.message);
            });
        }}
        validationSchema={schema}
      >
        {({ handleChange, handleSubmit, ...props }) => (
          <div className="flex justify-center items-center min-h-[90vh] my-10">
            <div className="m-5 sm:m-5 w-[700px] flex flex-col gap-2">
              <p className="text-3xl text-[#78FFD6]">Account Security</p>
              <div className="sm:min-w-max bg-[#404759] rounded">
                {/* body */}
                <div className="border-b border-[#EBEFFF] p-5 sm:p-8">
                  <div className="max-w-[440px] flex flex-col gap-5">
                    <AccountInput
                      label="Current Password"
                      type="text"
                      placeholder="Enter your current password"
                      value={props.values.Password}
                      onChange={handleChange("Password")}
                      isInvalid={
                        props.touched.Password && props.errors.Password
                      }
                      error={props.errors.Password}
                    />
                    <AccountInput
                      label="New Password"
                      type="text"
                      placeholder="Enter new password"
                      value={props.values.NewPassword}
                      onChange={handleChange("NewPassword")}
                      isInvalid={
                        props.touched.NewPassword && props.errors.NewPassword
                      }
                      error={props.errors.NewPassword}
                    />
                    <AccountInput
                      label="Confirm new password"
                      type="text"
                      placeholder="Enter cofirm password"
                      value={props.values.ConfirmPassword}
                      onChange={handleChange("ConfirmPassword")}
                      isInvalid={
                        props.touched.ConfirmPassword &&
                        props.errors.ConfirmPassword
                      }
                      error={props.errors.ConfirmPassword}
                    />
                  </div>
                  <div className="flex flex-row items-start sm:items-center gap-2 mt-5">
                    <BiInfoCircle
                      style={{ width: "22px", height: "22px", color: "white" }}
                    />
                    <p className="text-[12px] sm:text-[16px] text-white text-base">
                      Changing your password will log you out of all your active
                      sessions.
                    </p>
                  </div>
                </div>
                <div className="flex flex-row justify-end gap-5 p-5">
                  {/* <button className="px-5 py-2 border rounded-lg text-white">
                    Cancel
                  </button> */}
                  <button
                    className="px-5 py-2 border rounded-lg text-white"
                    onClick={handleSubmit}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </Formik>
    </Page>
  );
};

export default AccountSecurity;
