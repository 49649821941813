import React, { useState, useEffect } from "react";
import {
  AiOutlineArrowUp,
  AiOutlineInfoCircle,
  AiOutlineArrowDown,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { Offer } from "../../../services";


import ProfileBlock from "../../common/ProfileBlock";
import Body from "../../common/ProfileBlock/Body";
import Header from "../../common/ProfileBlock/header";

const tabs = ["BUY USDT", "SELL USDT"];


const ActiveOffers = ({ user }) => {
  const [selected, setSelected] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [offers, setOffers] = useState([]);
  const [buy, setBuy] = useState([]);
  const [sell, setSell] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [isBuy, setIsBuy] = useState(false);

  useEffect(() => {
    Offer.getMyAllOffer(user.id)
      .then((res) => {
        if (res.status === 200) {
          const buy = res.data.filter((offer) => offer.isBuy);
          const sell = res.data.filter((offer) => !offer.isBuy);
          setBuy(buy);
          setSell(sell);
          setOffers(res.data);
        }
      })
      .catch((e) => console.log("e my offer", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTab = (index) => {
    setSelected(index);
    if (index === 0) {
      setIsBuy(true);
    } else {
      setIsBuy(false);
    }
  };
  return (
    <div>
      <ProfileBlock>
        <Header>
          <p className="text-xl text-[#6DDABD] font-semibold py-2">
            Active Offers
          </p>
          <div className="flex flex-row items-center gap-14 ml-5">
            {tabs.map((tab, index) => (
              <p
                className={
                  selected === index
                    ? "text-[#6DDABD] border-b-2 border-[#6DDABD] font-bold leading-9 cursor-pointer"
                    : "text-white leading-9 cursor-pointer"
                }
                onClick={() => handleTab(index)}
              >
                {tab}
              </p>
            ))}
          </div>
        </Header>
        <Body>
          <div className="min-h-[300px] max-h-[300px] overflow-auto scrollbar-hide">
            <div className="w-full overflow-auto scrollbar-hide">
              <table className="w-full overflow-auto scrollbar-hide">
                <thead className="text-xs text-white bg-[#404759] dark:bg-gray-700 dark:text-gray-400">
                  <tr className="h-[45px] text-left">
                    <th scope="col" className="pl-2">
                      Pay with
                    </th>
                    <th scope="col" className=" hide md:block mt-4">
                      Avg. Trade Speed
                    </th>
                    <th scope="col" className="">
                      Limits
                    </th>
                    <th scope="col" className=""></th>
                    <th scope="col" className=""></th>
                  </tr>
                </thead>
                <tbody className="w-full text-left relative">
                  {selected === 0 ? (
                    buy.length ? (
                      buy.map((offer, index) => (
                        <tr
                          className={
                            index % 2 !== 0 ? "bg-[#404759]" : "bg-[#9FA3AC]"
                          }
                        >
                          <th
                            scope="row"
                            className=" text-[12px] md:text-sm text-white pl-2"
                          >
                            {offer.paymentMethod[0].toUpperCase()}
                          </th>
                          <td className="p-2 hide md:block text-[12px] md:text-sm text-white mt-2">
                            30 mnts
                          </td>
                          <td className="p-2 text-[12px] md:text-sm text-white ">
                            {offer.limitFrom} - {offer.limitTo}
                          </td>
                          <td className="hide md:block p-2 text-[12px] md:text-sm text-white mt-2">
                            <div className="w-full flex flex-row items-center gap-1">
                              <div className="sm:min-w-[100px] flex flex-row items-center gap-1">
                                {offer.price.toFixed(2)}
                                {offer.price > 0 ? (
                                  <AiOutlineArrowUp />
                                ) : (
                                  <AiOutlineArrowDown />
                                )}
                                {((offer.price - 1 / 1) * 100).toFixed(1)}%
                              </div>
                              <AiOutlineInfoCircle
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "black",
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </td>
                          <td className="p-2 text-[12px] md:text-sm text-right">
                            <Link to="/offer" state={{ data: offer }}>
                              <button
                                className="font-medium text-white bg-green-500 rounded-md px-5 py-2 cursor-pointer hover:bg-blue-600"
                                // onClick={() => console.log()}
                              >
                                View
                              </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="min-h-[300px] w-full flex flex-col justify-center items-center gap-2 py-3 absolute">
                        <p className="text-white">
                          This user don,t have any offer
                        </p>
                        <img src="/images/profile/empty.png" alt="img" />
                      </div>
                    )
                  ) : selected === 1 ? (
                    sell.length ? (
                      sell.map((offer, index) => (
                        <tr
                          className={
                            index % 2 !== 0
                              ? "bg-[#404759]"
                              : "bg-[#9FA3AC]"
                          }
                        >
                          <th scope="row" className="p-2 text-[12px] md:text-sm text-white">
                            {offer.paymentMethod[0].toUpperCase()}
                          </th>
                          <td className="p-2 hide md:block text-[12px] md:text-sm text-white mt-2">30 mnt</td>
                          <td className="p-2 text-[12px] md:text-sm text-white">
                            {offer.limitFrom} - {offer.limitTo}
                          </td>
                          <td className="hide md:block p-2 text-[12px] md:text-sm text-white mt-2">
                            <div className="w-full flex flex-row items-center gap-1">
                              <div className="sm:min-w-[100px] flex flex-row items-center gap-1">
                                {offer.price.toFixed(2)}
                                {offer.price > 0 ? (
                                  <AiOutlineArrowUp />
                                ) : (
                                  <AiOutlineArrowDown />
                                )}
                                {((offer.price - 1 / 1) * 100).toFixed(1)}%
                              </div>
                              <AiOutlineInfoCircle
                                style={{
                                  width: "22px",
                                  height: "22px",
                                  color: "black",
                                  marginLeft: "5px",
                                }}
                              />
                            </div>
                          </td>
                          <td className="p-2 text-[12px] md:text-sm text-right">
                          <Link to="/offer" state={{ data: offer }}>
                            <button
                              className="font-medium text-white bg-green-500 rounded-md px-5 py-2 cursor-pointer hover:bg-blue-600"
                              onClick={() => console.log()}
                            >
                              View
                            </button>
                            </Link>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="min-h-[300px] w-full flex flex-col justify-center items-center gap-2 py-3 absolute">
                        <p className="text-white">
                          This user don,t have any offer
                        </p>
                        <img src="/images/profile/empty.png" alt="img" />
                      </div>
                    )
                  ) : null}
                </tbody>
              </table>
            </div>
          </div>
        </Body>
      </ProfileBlock>
    </div>
  );
};

export default ActiveOffers;
