import React, { useState } from "react";
import LandingHeader from "../../common/LandingHeader";
import Carousal from "./Carousel";

const heading = "What our clients say";

const carouselData = [
  {
    message:
      "Client words will be displayed here as we want to share everyone's experience with us.",
  },
  {
    message: "Your positive review will help us to grow up fast.",
  },
  {
    message: "Even your negative review will push us to improve our services.",
  },
];

const HappyClients = () => {
  const [selected, setSelected] = useState(0);

  return (
    <div className="container py-10">
      <LandingHeader heading={heading} />
      <div className="mt-10 flex justify-center">
        {carouselData.map((data, index) => (
          <Carousal
            key={index}
            index={index}
            message={data.message}
            selected={selected}
            setSelected={setSelected}
          />
        ))}
      </div>
    </div>
  );
};

export default HappyClients;
