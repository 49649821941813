import React, { useEffect, useState } from "react";
import CreateOfferWrapper from "../components/common/CreateOfferWrapper";
import OfferProcessingWrapper from "../components/common/OfferProcessingWrapper";
import Page from "../components/common/Page";
import SellBuyOption from "../components/screens/CreatePost/SellBuyOption";
import PostFields from "../components/screens/CreatePost/PostFields";
import OtherSetting from "../components/screens/CreatePost/OtherSetting";
import { TiTick } from "react-icons/ti";
import { Offer } from "../services";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    step: 1,
    label: "Buy/Sell",
  },
  {
    step: 2,
    label: "Pricing",
  },
  {
    step: 3,
    label: "Other Setting",
  },
];

const CreateOfferPage = () => {
  const navigate = useNavigate()
  const [selected, setSelected] = useState(0);
  const [option, setOption] = useState("buy");
  const [isBuy, setIsBuy] = useState(true);
  const [minimumPrice, setMinimumPrice] = useState("");
  const [maximumPrice, setMaximumPrice] = useState("");
  const [myPrice, setMyPrice] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("");
  const [terms, setTerms] = useState("");
  // const [country, setCountry] = useState("");
  const [isTerm, setIsTerm] = useState(false);

  const user = useSelector((state) => state.user);
  const name = user.firstName +" "+ user.lastName
  const country = user.country;

  useEffect(() => {
    if(!user.id){
      navigate('/login')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  useEffect(() => {
    if (option === "buy") {
      setIsBuy(true);
    } else {
      setIsBuy(false);
    }
  }, [option]);

  const handleSubmit = () => {
    Offer.createOffer(
      user.id,
      name,
      country,
      myPrice,
      minimumPrice,
      maximumPrice,
      terms,
      isBuy,
      paymentMethod
    )
      .then((res) => {
        toast.success("Post created successfully");
        navigate("/");
      })
      .catch((e) => console.log({ e }));
    setSelected(4);
  };

  return (
    <Page title="P2Pways – Create offer">
      <div className="flex flex-row justify-center sm:p-5">
        <div className="container w-full sm:w-11/12">
          <CreateOfferWrapper>
            <OfferProcessingWrapper>
              <div className="py-10">
                {selected > 0 && (
                  <AiOutlineArrowLeft
                    onClick={() => setSelected(selected - 1)}
                    style={{
                      color: "white",
                      width: "50px",
                      cursor: "pointer",
                      position: "absolute",
                    }}
                  />
                )}{" "}
                <div className="border-b border-[#EBEFFF]">
                  <div className="flex flex-row justify-center gap-10 sm:gap-24 p-5">
                    {steps.map((step, index) => (
                      <div key={index} className="flex flex-col items-center gap-2">
                        <div
                          className={
                            selected === index
                              ? "w-8 h-8 flex justify-center items-center rounded-full bg-[#FF6A6A]"
                              : index < selected || selected === 4
                              ? "w-8 h-8 flex justify-center items-center rounded-full bg-[#49A31C]"
                              : "w-8 h-8 flex justify-center items-center rounded-full bg-[#DCDCDC]"
                          }
                        >
                          {index < selected ? (
                            <TiTick style={{ color: "white" }} />
                          ) : (
                            step.step
                          )}
                        </div>
                        <p className="text-sm text-white">{step.label}</p>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="p-5">
                  {selected === 0 ? (
                    <SellBuyOption
                      option={option}
                      setOption={setOption}
                      setSelected={setSelected}
                    />
                  ) : selected === 1 ? (
                    <PostFields
                      option={option}
                      minimumPrice={minimumPrice}
                      setMinimumPrice={setMinimumPrice}
                      maximumPrice={maximumPrice}
                      setMaximumPrice={setMaximumPrice}
                      myPrice={myPrice}
                      setMyPrice={setMyPrice}
                      paymentMethod={paymentMethod}
                      setPaymentMethod={setPaymentMethod}
                      selected={selected}
                      setSelected={setSelected}
                    />
                  ) : selected === 2 ? (
                    <OtherSetting
                      terms={terms}
                      setTerms={setTerms}
                      // country={country}
                      // setCountry={setCountry}
                      isTerm={isTerm}
                      setIsTerm={setIsTerm}
                      setSelected={setSelected}
                    />
                  ) : (
                    ""
                  )}
                </div>
                {!!(terms.length && isTerm && country.length && selected === 2 ) && (
                  <div className="flex flex-row justify-center mt-5">
                    <button
                      className="px-8 py-2 bg-[#98F18A] rounded font-semibold"
                      onClick={() => handleSubmit()}
                    >
                      NEXT
                    </button>
                  </div>
                )}
              </div>
            </OfferProcessingWrapper>
          </CreateOfferWrapper>
        </div>
      </div>
    </Page>
  );
};

export default CreateOfferPage;

// if (option === "buy") {
//   Buyer.createBuyer(
//     user.id,
//     name,
//     country,
//     myPrice,
//     minimumPrice,
//     maximumPrice,
//     terms,

//   )
//     .then((res) => {
//       toast.success("Post created successfully");
//       setTimeout(() => {
//         window.location.href = "/";
//       }, 3000);
//     })
//     .catch((e) => console.log({ e }));
// } else {
//   Seller.createSeller(
//     user.id,
//     name,
//     country,
//     myPrice,
//     minimumPrice,
//     maximumPrice
//   )
//     .then((res) => {
//       toast.success("Post created successfully");
//     })
//     .catch((e) => console.log({ e }));
// }
