import React from "react";
import Page from "../components/common/Page";

const Terms = () => {
  return (
    <Page title="P2Pways – Terms & Conditions">
      <div className="w-full min-h-screen bg-black py-20">
        <div className="container flex flex-col gap-4 text-white helvet">
          <div className="flex flex-col justify-center items-center">
            <h1 className="terms-heading">Terms and Conditions</h1>
            <p className="max-w-fit text-center text-[12px] px-2 bg-white text-black">
              Issuing date on 22/06/2022.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Hello from P2Pways.com
            </h3>
            <p className="text-[14px]">
              It’s important to read carefully and accept the
              <span className="underline px-2">Terms & Conditions</span>
              as legal agreement before you start using P2Pways services.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">What is P2Pways?</h3>
            <p className="text-[14px]">
              P2Pways is an online trading service for buying and selling USDT
              between users by posting an offer “Seller” to sell their USDT or
              by “buyer” to buy USDT under their own responsibility.
            </p>
            <p>
              P2Pways will help resolving disputes if any arise between buyers
              and sellers.
            </p>
            <p>
              {" "}
              P2Pways does not become a party to any trade or transaction
              concluded by its users. Users are natural persons.
            </p>
            <p>
              These Terms and conditions is for the services and the
              relationship between the user and P2Pways.
            </p>
            <p>
              To the extent that those laws and regulations provide more
              extensive protection than the protection set forth in these Terms,
              all mandatory statutory consumer protection laws and regulations
              applicable in the country of your residence apply.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Acceptance of the Terms
            </h3>
            <p className="text-[14px]">
              All users “you” should agree on P2Pways terms and conditions
              before start using our services provided to you. By accessing or
              using our services, you confirm that you agree to these terms, you
              agree to follow our terms and agree to be bound by them. You also
              agree that your personal data and electronic communications on our
              platform will be processed in accordance with our terms, which are
              incorporated here by reference.
            </p>
            <p>
              Under eighteen (18) years old they are not allowed to use P2Pways
              services.
            </p>
            <p>
              You confirm that you have the right, power, capacity and authority
              to lawfully enter into this agreement.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Terms Changing Possibility
            </h3>
            <p className="text-[14px]">
              P2Pways has the right to make modification or revisions of the
              terms of this agreement according to the changes happened in
              technology, standards, law, and other changes related in direct
              way to our services provided for registration and cost structure
              for the services provided.
            </p>
            <p>
              Users will be notified about the modification before 30 days of
              making those modification effective.
            </p>
            <p>
              Users have the possibility to check the terms modification and
              able to accepting it in order to continuing to use our services,
              if not the agreement will be terminated and users should complete
              any open transaction and withdrawing the remaining balances within
              30 days only, as their account will be automatically closed. If
              your account is closed and still you have balance remaining in
              your account, P2Pways will not be able to providing any support to
              get back your balance.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Registration and User Account
            </h3>
            <p className="text-[14px]">
              Users “you” must register with us by creating a user account
              ("account") to be able to use our services.
            </p>
            <p>
              To create and account on P2Pways you need to provide your personal
              email ID active (for verification), personal mobile phone active
              (for OTP verification) and Google Authenticator for verfriciation
              purpose.
            </p>
            <p>
              Make sure that you are using your real name, and current location
              where you are living, to avoid any problem with your account
              verification later on when P2Pways is requesting you to provide
              your personal ID and address proof (Creating fake reputation
              information for your account, faking your country of origin is
              prohibited and will lead to termination or suspension of your
              account).
            </p>
            <p>
              Users may have only one account and make sure that you are not
              creating an account on behalf of someone else than yourself or
              giving your account information to other in order to use it under
              your name (your information is private do not share it with anyone
              else).
            </p>
            <p>
              When you want to delete your account and cancel the agreement, you
              should contact P2Pways customer care (help@P2Pways.com). In order
              to be deleted you should complete all the open trades not yet
              completed and withdraw your coins before contacting our team.
            </p>
            <p>
              If you are not in compliance with all applicable federal and state
              laws and regulations governing Money Service Businesses or
              Crypto-asset Businesses or Virtual Asset Service Providers or
              similar applicable regulation of the jurisdiction you are located
              in, please do not register or use our services.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Account usage and verification
            </h3>
            <p className="text-[14px]">
              To start using our services after the registration completed,
              P2Pways has the right to request users to submit their personal ID
              to confirm that the registered details are correct to avoid any
              fake usage and to keep our platform secure.
            </p>
            <p>
              Also P2Pways has the right to request the source of the funds and
              crypto-assets for the huge transaction is going between users
              using our services.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">P2Pways Wallet</h3>
            <p className="text-[14px]">
              After the verification you will have a P2Pways wallets support
              only USDT. Transactions sent to P2Pways receiving wallet addresses
              that contain different Network that the specified on your profile
              will be lost.
            </p>
            <p>
              P2Pways does not support any kind of criminal activity and our
              services must not be used for criminal purposes. It is not allowed
              to use P2Pways trading platform or wallet services for receiving
              or sending funds related to money laundering, financing of
              terrorism, fundraising, acquisition of illicit items, and other
              criminal activity.
            </p>
            <p>
              P2Pways wallet is for USDT trading purpose to buy and sell USDT
              using the offer created by “you” on our platform and it is not
              allowed to use P2Pways wallet as USDT holders for a long time.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">IS P2Pways safe?</h3>
            <p className="text-[14px]">
              We tried our best to maximize the security on P2Pways to not have
              any fake dealers and fake offer.
              <br /> 1- The Sellers could not publish or creating any offer
              unless they have enough balance at P2Pways wallet to create the
              offer.
              <br /> 2- The users cannot withdraw their coins from P2Pways if
              they have any active offers, the offer should be deleted before to
              make the withdrawal of their coins.
              <br /> 3- The transaction will not be marked as completed unless
              both parties confirm that they received the full payment and full
              USDT amount (the total USDT that reaches the Buyer’s wallet
              deducts the platform fee from it) <br />
              4- Once the Seller completed the offer by selling the full amount
              offered, the offer will be deleted automatically after the Seller
              released the USDT to the Buyer.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">USDT Trading</h3>
            <p className="text-[14px]">
              After you complete the steps above, now you will be able to trade
              USDT on P2Pway, by creating offers to buy or sell USDT.
            </p>
            <p>
              To be able to create offer you must verify your mobile number and
              receiving OTP on the registered mobile number.
            </p>
            <p>
              Buyer is able to choose the payment method that available on our
              platform and seller should accept the payment method requested by
              buyer.
            </p>
            <p>
              For selling offer also the seller able to specify the payment
              method available on our platform want to receiving from buyer and
              buyer should accept the seller payment method condition.
            </p>
            <p>
              When you are selling USDT it is your responsibility to check that
              you have received payment for the full amount.
            </p>
            <p>
              After you have released a trade to the buyer it is not possible to
              cancel, reverse, dispute or otherwise recover or return the USDT
              to you.
            </p>
            <p>Payments made by buyers must be sent by the same person.</p>
            <p className="max-w-fit px-2 bg-red-800">Important notes:</p>
            <p>
              P2Pways using BNB smart chain (BEP20) for USDT deposit, make sure
              that you are using the right network to not lose your coins.
            </p>
            <p>
              Minimum amount to create an offer is 50 USDT. You should make sure
              that you have more than &gt;50 USDT in your wallet to set an
              offer, and sell complete coins (eg. Minimum amount is 50 USDT and
              Maximum amount is 50.1 USDT) otherwise your 50 USDT will be
              remained in your account and you don`t have the possibility to
              withdraw it out of P2Pways.
            </p>
            <p>
              The communication relevant to the trade must happen in the trade
              chat on our platform in such a way that it is readily available
              for P2Pways support staff to review. Communications on the
              platform are not considered private communications between you and
              other users, but P 2Pways is also a party to such communications.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Disputing USDT Trades
            </h3>
            <p className="text-[14px]">
              For any trade issue between the seller and buyer, you can open a
              dispute ticket by contacting the P2Pways staff to be reviewed and
              resolved.
            </p>
            <p>
              Disputes are allowed on the open trades not completed by one of
              the users (When the buyer has been proceed with payment and USDT
              not released by seller)
            </p>
            <p>
              Disputes are not allowed when the transaction is completed that is
              mean the buyer confirmed that received the payment and seller
              confirmed that received the USDT (our platform is perfectly
              maintained in order to be check by buyer and seller to confirm
              that they receive what they should receive “payments” or “USDT”)
            </p>
            <p>
              P2Pways does not become a guarantor of any party’s performance of
              a trade.
            </p>
            <p>
              P2Pways helps in resolving disputes, you assume the risk that any
              party with whom you trade, but not responsible to recoup funds or
              USDT, Users are trading on their own responsibility using our
              services.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">Unresponsiveness</h3>
            <p className="text-[14px]">
              Buyer and seller should be active while they have an active order
              on P2Pways until the transaction is completed, payment and USDT
              have been released.
            </p>
            <p>
              Also in Dispute situation the Seller should be available on the
              platform to responds P2Pways support team in order to solve the
              issue, if the Seller did not response to our team within 24 hours
              the dispute will be resolved against you.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">Dispute review</h3>
            <p className="text-[14px]">
              Our team will review the dispute and users should follow the
              instruction given by our team, it could be by submitting a proof
              of payment for received or non-received payment, and sometimes
              bank transaction history if required. Failure to follow the
              instructions may lead to the dispute being resolved against you.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Criteria for resolving disputes
            </h3>
            <p className="text-[14px]">
              When the dispute resolution criteria are met, P2Pways support will
              typically move the escrowed USDT to the buyer or seller of the
              disputed trade.
            </p>
            <p>
              In rare cases where neither party meets the criteria, or it is
              unclear or impossible to determine which party has met the dispute
              resolution criteria, P2Pways may decide to settle the dispute by
              splitting the escrowed USDT evenly or unevenly between the buyer
              and seller.
            </p>
            <p>
              When one of the following conditions is satisfied, P2Pways can
              settle a contentious deal in favor of a USDT buyer:
              <br /> • The buyer has provided sufficient proof that the payment
              was paid in accordance with the instructions provided by the
              seller in the trade chat, the offer terms of trade, or the offer
              payment information;
              <br /> • The seller has stopped responding.
            </p>
            <p>
              When one of the following conditions is satisfied, P2Pways can
              settle a contentious deal in favor of a USDT Seller: <br />• The
              buyer has not provided payment or not provided full payment;
              <br /> • The payment made by the buyer has been held or stopped by
              the third party payment provider, <br />• The buyer has not
              provided payment in accordance with the instructions provided to
              them by the seller in the trade chat, the offer terms of trade, or
              the offer payment details. <br />
              •The payment is made by an unauthorized third party to the trade{" "}
              <br />• The seller has stopped responding. The disagreement may be
              handled against the user right away if the buyer or seller of a
              disputed trade offers incorrect information, false papers, false
              claims, or makes any other attempts to influence the conclusion of
              the disputed sale. Incorrect dispute resolution You have the right
              to ask for a review if you think P2Pways handled a disagreement
              you were a party to in a manner inconsistent with these
              conditions. You must tell us and expressly request a review by
              getting in touch with customer service no later than 120 days
              after the dispute resolution has been delivered in order to do so.
              Limitation of Liability regarding dispute resolution You agree and
              accept that we assume no responsibility for the outcome thereof.
              In any event our liability for dispute resolution is limited to
              the amount of the fee payable to us. In the event of an incorrect
              dispute resolution which is corrected in accordance with the terms
              of this Agreement, we assumes no liability for any damage, loss,
              cost or inconvenience. Note: P2Pways will not be responsible to
              compensate any USDT amount to the buyer in case the seller is not
              responding to our communication (all the responsibility will be
              taken by users under their own responsibility){" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">Advertisements</h3>
            <p className="text-[14px]">
              P2Pways doesn’t encourage offers for buying with or selling
              against cash. You can create USDT trade offer to advertise that
              you want to buy or sell USDT. Other users can send trade requests
              to you from the offers that you’ve created. After initiation of a
              trade the price is locked and cannot be changed. Advertisements
              without having an active balance on P2Pways wallets are not
              allowed.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Limitation, Suspension, and Termination of Your User Account
            </h3>
            <p className="text-[14px]">
              P2Pways has the right to limit, suspend, or terminate your account
              if illegal situation was found (money laundry, source of money
              proof failed, law restriction at the user country). Account
              limitation results in a temporary or permanent loss of access to a
              certain specific part or parts of our Services. Account
              termination results in permanent lost access to all Services.
              P2Pways will notify the user about their account status and the
              action should be taken before / during 30 days to withdraw the
              available balance at P2Pways wallet. If there are USDT
              transactions made to the wallet addresses associated with your
              terminated or deleted account after the termination or the
              deletion, you agree that the USDT become the property of P2Pway.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Limiting Access to Your P2Pways Wallet
            </h3>
            <p className="text-[14px]">
              This limitation may be applied specifically to your wallet. It is
              intended to protect your account if a third party has gained
              unauthorized access to it. It may also be applied during service
              outages or other situations where there is a high risk to your
              account. We are under no obligation to disclose the details of our
              security procedures to you.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              P2Pways fees and Pricing
            </h3>
            <p className="text-[14px]">
              You agree to pay the fees and charges for the use of the Services
              according to the price list which is available on our site where
              is applicable. Such fees may include fees for incoming and
              outgoing transactions and percentage based fees for trades
              completed. We reserve the right to change the fees or the
              principles of pricing from time to time in accordance with the
              terms of this Agreement Administration fees will be applicable on
              accounts have been deleted or terminated for more than one year
              and still have balance on it. How will P2Pways calculate the
              administration fees? • Yearly fees will be charged monthly on the
              last day of each month for 10% from the total amount available at
              P2Pways wallet. The fees will be paid in USDT at the current
              market rate. Administration fees will be applicable on accounts
              have not been used for any trades or transactions for more than
              two years and still have balance on it. How will P2Pways calculate
              the administration fees? • Yearly fees will be charged monthly on
              the last day of each month for 10% from the total amount available
              at P2Pways wallet not been used for more than two years. The fees
              will be paid in USDT at the current market rate.{" "}
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Termination of P2Pways Business
            </h3>
            <p className="text-[14px]">
              We have the right to cease the Services altogether and terminate
              this Agreement in case our business is terminated for whatever
              reason or if the business is transferred, sold to or merged with a
              third party. We will give a notice to our users and inform them of
              the procedure to complete outstanding trades and unresolved
              disputes. The users are guaranteed at least one (1) year’s time to
              withdraw their USDT.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              P2Pways Intellectual Property Rights
            </h3>
            <p className="text-[14px]">
              P2Pways grant you a revocable, non-exclusive and limited license.
              You agree not to copy, transmit, distribute, sell, license,
              reverse engineer, modify, publish, or participate in the transfer
              or sale of, create derivative works from, or in any other way
              exploit any of the Content, in whole or in part.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Disclaimer of Warranties and Limitation of Liability
            </h3>
            <p className="text-[14px]">
              P2Pways is not associated with or does not itself support or claim
              to be in partnership with any of the payment methods, services or
              companies which may appear visible in the Online Payment method
              lists or offers details. This site and the Services are provided
              on an as it is and as it is available basis.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              Why P2Pways collecting your information and how we use it?
            </h3>
            <p className="text-[14px]">
              P2Pways ask for your personal information when you create and
              account on our platform, for verification and to confirm that the
              entered information are correct as we do not accept any fake
              profiles. Your information will not be shared with anyone unless
              we have been requested by your government from the country you are
              living in.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">
              P2Pways using Cookies:
            </h3>
            <p className="text-[14px]">
              Cookies are text files with small pieces of data like a username
              and password that are used to identify your computer as you use a
              computer network. Specific cookies known as HTTP cookies are used
              to identify specific users and improve your web browsing
              experience. Data stored in a cookie is created by the server upon
              your connection. This data is labeled with an ID unique to you and
              your computer. When the cookie is exchanged between your computer
              and the network server, the server reads the ID and knows what
              information to specifically serve to you. Disabling cookies may
              result in some functionalities and features of this site being
              disabled.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">Terms Conclusion:</h3>
            <p className="text-[14px]">
              This Agreement (as amended from time to time) and any document
              expressly referred to in them contains the entire agreement and
              understanding between you and us. We may transfer or assign any of
              our rights and obligations arising under this Agreement to any
              party at any time, but this will not affect your rights or our
              obligations. You confirm that, in agreeing to accept this
              Agreement, you have not relied on any representation that is not
              expressly included herein.
            </p>
          </div>
          <div>
            <h3 className="text-[15px] font-bold mb-1">How to Contact us: </h3>
            <p className="text-[14px]">
              1. You can contact us using the form available on our website
              <a className="px-2 text-blue-700"
              href="https://p2pways.com/contact-us" target="_blank" rel="noreferrer"
              >https://p2pways.com/contact-us</a> 2. If you have any questions about
              our terms, you have the right to contact our customer care
              department by email: help@P2Pways.com
            </p>
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Terms;
