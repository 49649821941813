import React from "react";
import LandingHeader from "../../common/LandingHeader";
import TransferWays from "../../common/TransferWays";

const heading = ` Ways to buy and sell USDT`;
const detail =
  "Excel your USDT trading by following the given payment gateways. ";

const data = [
  {
    heading: "Bank Transfer",
    detail:
      "With some easy steps, initiate a bank transfer for processing your USDT buying at the best price with us. ",
  },
  {
    heading: "Cash Payment",
    detail:
      "If you don’t have a bank account, don’t worry. We give you an offer to pay cash and get your USDT now. ",
  },
  {
    heading: "Online Wallets",
    detail:
      "Without stepping out to your place, utilize the key of your digital wallet securely to make your USDT purchase. ",
  },
];

const Ways = () => {
  return (
    <div className="container py-10">
      <LandingHeader heading={heading} detail={detail} />
      <div className="flex flex-row justify-center flex-wrap gap-14 mt-10">
        {data.map((item, index) => (
          <TransferWays
            key={index}
            index={index}
            heading={item.heading}
            detail={item.detail}
          />
        ))}
      </div>
    </div>
  );
};

export default Ways;
