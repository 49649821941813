export const PaymentMethods = [
    { value: "Air TM", label: "Air TM" },
    { value: "Airtel Money", label: "Airtel Money" },
    { value: "Ali Pay", label: "Ali Pay" },
    { value: "Apple Pay", label: "Apple Pay" },
    { value: "AstroPay", label: "AstroPay" },
    { value: "Axie Infinity (AXS) altcoin", label: "Axie Infinity (AXS) altcoin" },
    { value: "Bhim", label: "Bhim" },
    { value: "Bitcoin Cash (BCH) altcoin", label: "Bitcoin Cash (BCH) altcoin" },
    { value: "Bizum", label: "Bizum" },
    { value: "Bluebird Card", label: "Bluebird Card" },
    { value: "Boleto Bancario (Boleto)", label: "Boleto Bancario (Boleto)" },
    { value: "Stripe", label: "Stripe" },
    { value: "Bunq", label: "Bunq" },
    { value: "Card to card", label: "Card to card" },
    { value: "Cardano (ADA) altcoin", label: "Cardano (ADA) altcoin" },
    { value: "Cash App", label: "Cash App" },
    { value: "Cash at ATM", label: "Cash at ATM" },
    { value: "Cash deposit", label: "Cash deposit" },
    { value: "CashU", label: "CashU" },
    { value: "Cashier's Check", label: "Cashier's Check" },
    { value: "Chainlink (link) altcoin", label: "Chainlink (link) altcoin" },
    { value: "Chase Quickpay", label: "Chase Quickpay" },
    { value: "Chime", label: "Chime" },
    { value: "Chipper cash", label: "Chipper cash" },
    { value: "Credit card", label: "Credit card" },
    { value: "Dogecoin altcoin", label: "Dogecoin altcoin" },
    { value: "Dwolla", label: "Dwolla" },
    { value: "Easypaisa", label: "Easypaisa" },
    { value: "Ecocash", label: "Ecocash" },
    { value: "Efecty", label: "Efecty" },
    { value: "Etherium altcoin", label: "Etherium altcoin" },
    { value: "Eversend", label: "Eversend" },
    { value: "Freecharge", label: "Freecharge" },
    { value: "GCash", label: "GCash" },
    { value: "GEO Pay", label: "GEO Pay" },
    { value: "Google pay", label: "Google pay" },
    { value: "GreenDot Card", label: "GreenDot Card" },
    { value: "Hal-cash", label: "Hal-cash" },
    { value: "IMPS Bank Transfer India", label: "IMPS Bank Transfer India" },
    { value: "Interac e-transfer", label: "Interac e-transfer" },
    { value: "International Wire (SWIFT)", label: "International Wire (SWIFT)" },
    { value: "JazzCash", label: "JazzCash" },
    { value: "Litecoin altcoin", label: "Litecoin altcoin" },
    { value: "Lydia", label: "Lydia" },
    { value: "M-PESA Kenya (Safaicom)", label: "M-PESA Kenya (Safaicom)" },
    { value: "M-PESA Tanzania (Vodacom)", label: "M-PESA Tanzania (Vodacom)" },
    { value: "MBWay", label: "MBWay" },
    { value: "MOVII", label: "MOVII" },
    { value: "MTN Mobile Money", label: "MTN Mobile Money" },
    { value: "Mach", label: "Mach" },
    { value: "Mastercard - Mastercard", label: "Mastercard - Mastercard" },
    { value: "MercadoPago", label: "MercadoPago" },
    { value: "Mir", label: "Mir" },
    { value: "Mobile-top-up", label: "Mobile-top-up" },
    { value: "MobilePay", label: "MobilePay" },
    { value: "MobilePay FI", label: "MobilePay FI" },
    { value: "MobilePay NO", label: "MobilePay NO" },
    { value: "MonCash", label: "MonCash" },
    { value: "Monese", label: "Monese" },
    { value: "Moneybookers / Skrill", label: "Moneybookers / Skrill" },
    { value: "Moneygram", label: "Moneygram" },
    { value: "Mukuru", label: "Mukuru" },
    { value: "N26", label: "N26" },
    { value: "National bank transfer", label: "National bank transfer" },
    { value: "Nequi", label: "Nequi" },
    { value: "Neteller", label: "Neteller" },
    { value: "OKB altcoin", label: "OKB altcoin" },
    { value: "OKPay", label: "OKPay" },
    { value: "OneMoney", label: "OneMoney" },
    { value: "Orange Money", label: "Orange Money" },
    { value: "Other Online Wallet", label: "Other Online Wallet" },
    { value: "Other Online Wallet (Global)", label: "Other Online Wallet (Global)" },
    { value: "Other Remittance", label: "Other Remittance" },
    { value: "Other online Payment", label: "Other online Payment" },
    { value: "PIX instant payment", label: "PIX instant payment" },
    { value: "PSE", label: "PSE" },
    { value: "PYC", label: "PYC" },
    { value: "PagoFacil", label: "PagoFacil" },
    { value: "PayID", label: "PayID" },
    { value: "PaySera", label: "PaySera" },
    { value: "PayTM", label: "PayTM" },
    { value: "Payeer", label: "Payeer" },
    { value: "Paym", label: "Paym" },
    { value: "Payoneer", label: "Payoneer" },
    { value: "Paypal", label: "Paypal" },
    { value: "Paysend", label: "Paysend" },
    { value: "Payza", label: "Payza" },
    { value: "PhonePe", label: "PhonePe" },
    { value: "PicPay", label: "PicPay" },
    { value: "Perfect Money", label: "Perfect Money" },
    { value: "Pingit", label: "Pingit" },
    { value: "Pingit", label: "Pingit" },
    { value: "Polkadot (DOT) altcoin", label: "Polkadot (DOT) altcoin" },
    { value: "PostePay", label: "PostePay" },
    { value: "QIWI", label: "QIWI" },
    { value: "RIA Money Transfer", label: "RIA Money Transfer" },
    { value: "Rapipago", label: "Rapipago" },
    { value: "Remitly", label: "Remitly" },
    { value: "Revolut", label: "Revolut" },
    { value: "Ripple altcoin", label: "Ripple altcoin" },
    { value: "SBP - Fast Bank Transfer", label: "SBP - Fast Bank Transfer" },
    { value: "SEPA (EU) bank transfer", label: "SEPA (EU) bank transfer" },
    { value: "Sendwave", label: "Sendwave" },
    { value: "Serve2Serve", label: "Serve2Serve" },
    { value: "Smooth Love Potion (SPL) altcoin", label: "Smooth Love Potion (SPL) altcoin" },
    { value: "Stellar altcoin", label: "Stellar altcoin" },
    { value: "Superflash", label: "Superflash" },
    { value: "Swish", label: "Swish" },
    { value: "TWINT", label: "TWINT" },
    { value: "Bitcoin (BTC) altcoin", label: "Bitcoin (BTC) altcoin" },
    { value: "Tigo-Pesa Tanzania", label: "Tigo-Pesa Tanzania" },
    { value: "Tinkoff", label: "Tinkoff" },
    { value: "Transfers with specific bank", label: "Transfers with specific bank" },
    { value: "Transferwise", label: "Transferwise" },
    { value: "UPI Transfer", label: "UPI Transfer" },
    { value: "USD Coin (USDC) altcoin", label: "USD Coin (USDC) altcoin" },
    { value: "Uphold", label: "Uphold" },
    { value: "Venmo", label: "Venmo" },
    { value: "Verge altcoin", label: "Verge altcoin" },
    { value: "Via Baloto", label: "Via Baloto" },
    { value: "Vipps", label: "Vipps" },
    { value: "Visa - Visa", label: "Visa - Visa" },
    { value: "Vodafone cash", label: "Vodafone cash" },
    { value: "Walmart 2 Walmart", label: "Walmart 2 Walmart" },
    { value: "WeChat", label: "WeChat" },
    { value: "WebMoney", label: "WebMoney" },
    { value: "Western Union", label: "Western Union" },
    { value: "Worldremit", label: "Worldremit" },
    { value: "Xoom", label: "Xoom" },
    { value: "YAPE", label: "YAPE" },
    { value: "Zelle", label: "Zelle" },
    { value: "Zipit", label: "Zipit" },
    { value: "advcash", label: "advcash" },
    { value: "hyperWALLET", label: "hyperWALLET" },
    { value: "ЕРИП 'Settlement' system (ERIP)", label: "ЕРИП 'Settlement' system (ERIP)" },
    { value: "Facebook Pay", label: "Facebook Pay" },
    { value: "Amazon Pay", label: "Amazon Pay" },
    { value: "YooMoney", label: "YooMoney" },
    { value: "Mobiamo", label: "Mobiamo" },
    { value: "Azimo", label: "Azimo" },
    { value: "InstaPay", label: "InstaPay" },
    { value: "We Pay", label: "We Pay" },
    { value: "Sahl", label: "Sahl" },
    { value: "NBE Phone Cash", label: "NBE Phone Cash" },
    { value: "CIB Smart Wallet", label: "CIB Smart Wallet" },
    { value: "Fawry", label: "Fawry" },
    { value: "Poga", label: "Poga" },
    { value: "Fawry Sudan", label: "Fawry Sudan" },
    { value: "BM Wallet", label: "BM Wallet" },
    { value: "Orange cash", label: "Orange cash" },
    { value: "Sobflous", label: "Sobflous" },
    { value: "RunPay", label: "RunPay" },
    { value: "AmenPay", label: "AmenPay" },
    { value: "Bank-e", label: "Bank-e" },
    { value: "Pocket Bank", label: "Pocket Bank" },
    { value: "ipaymix", label: "ipaymix" },
    { value: "Payit", label: "Payit" },
    { value: "NBK Mobile Banking", label: "NBK Mobile Banking" },
    { value: "SiDi", label: "SiDi" },
    { value: "Weyay", label: "Weyay" },
    { value: "Og Money", label: "Og Money" },
    { value: "KFH Online", label: "KFH Online" },
    { value: "Thawani Pay", label: "Thawani Pay" },
    { value: "NBO", label: "NBO" },
    { value: "OAB Online", label: "OAB Online" },
    { value: "Doha Pay", label: "Doha Pay" },
    { value: "QIB Mobile", label: "QIB Mobile" },
    { value: "Sadad QA", label: "Sadad QA" },
    { value: "Ooredoo Money", label: "Ooredoo Money" },
    { value: "Qatar Pay Lite", label: "Qatar Pay Lite" },
    { value: "STC Pay", label: "STC Pay" },
    { value: "Urpay", label: "Urpay" },
    { value: "Alinma Pay", label: "Alinma Pay" },
    { value: "Meem", label: "Meem" },
    { value: "Whish", label: "Whish" },
    { value: "Arabi-Mobile", label: "Arabi-Mobile" },
  ];

 
  
  
  
  
