import React, { useState, useEffect } from "react";
import { UserAuth } from "../../../services";
import { AiOutlineLike, AiOutlineDislike } from "react-icons/ai";

const PositiveButton = () => {
  return (
    <button className="flex flex-row items-center gap-1 text-sm rounded-md bg-[#98F18A] px-2 py-1">
      <AiOutlineLike />
      Positive
    </button>
  );
};

const NegativeButton = () => {
  return (
    <button className="flex flex-row items-center gap-1 text-sm rounded-md bg-[#FF7272] px-2 py-1">
      <AiOutlineDislike />
      Negative
    </button>
  );
};

const UserDetail = ({ review }) => {
  const [user, setUser] = useState({});
 
  useEffect(() => {
    UserAuth.getSingleUser(review.senderId)
      .then((res) => {
        if (res.status === 200) {
          setUser(res.data);
        }
      })
      .catch((e) => console.log("-e-", e));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-row items-start gap-2">
      <img
        className="w-10 h-10 rounded-full"
        src={
          user.image ? `${user.image}` : "/images/user_profile.png"
        }
        alt="img"
      />
      <div className="min-w-[100px] flex flex-col items-start gap-1">
        <p className="text-white text-base font-semibold">
          {user.firstName ? `${user.firstName} ${user.lastName}` : "User Name"}
        </p>
        {/* <p className="text-white text-sm">{review.date}</p> */}
        {review.isPositive ? <PositiveButton /> : <NegativeButton />}
      </div>
    </div>
  );
};

export default UserDetail;
