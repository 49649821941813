import axios from "axios";
import { API_URL } from "../lib/config";

const token = localStorage.getItem("token");

const reqInstance = axios.create({
  headers: {
    Authorization: `Bearer ${token}`,
  },
});

const Offer = {
  createOffer: (
    userId,
    name,
    country,
    price,
    limitFrom,
    limitTo,
    terms,
    isBuy,
    paymentMethod
  ) => {
    return reqInstance
      .post(`${API_URL}/v1/offer/create`, {
        userId,
        name,
        paymentMethod,
        country,
        price,
        limitFrom,
        limitTo,
        terms,
        isBuy,
      })
      .then((res) => res);
  },

  getAllOffer: () => {
    return axios.get(`${API_URL}/v1/offer/getAll`).then((res) => res);
  },

  getAllCountryOffer: (country) => {
    return axios.get(`${API_URL}/v1/offer/getAllCountryOffer`, {
      country
    }).then((res) => res);
  },

  getMyAllOffer: (id) => {
    return axios.get(`${API_URL}/v1/offer/getMyAll/${id}`).then((res) => res);
  },

  getSingleOffer: (id) => {
    return axios.get(`${API_URL}/v1/offer/get/${id}`).then((res) => res);
  },

  updateOffer: (id, price, limitFrom, limitTo, paymentMethod) => {
    return reqInstance
      .put(`${API_URL}/v1/offer/updateOffer/${id}`, {
        price,
        limitFrom,
        limitTo,
        paymentMethod,
      })
      .then((res) => res);
  },

  updateOfferOnTransaction: (id, price) => {
    return reqInstance
      .put(`${API_URL}/v1/offer/updateOfferOnTransaction/${id}`, {
        price
      })
      .then((res) => res);
  },

  deleteOffer: (id) => {
    return reqInstance
      .put(`${API_URL}/v1/offer/deleteOffer/${id}`)
      .then((res) => res);
  },

  getMyAllSellingOfferSum: (id) => {
    return axios
      .get(`${API_URL}/v1/offer/getMyAllSellingOfferSum/${id}`)
      .then((res) => res);
  },
};

export default Offer;


// getAllOffer: (limit, skip) => {
//   return axios.get(`${API_URL}/v1/offer/getAll`, {
//     params: {
//       limit,
//       skip
//     },
//   }).then((res) => res);
// },

// getAllCountryOffer: (country, limit, skip) => {
//   const data = {country}
//   return axios.get(`${API_URL}/v1/offer/getAllCountryOffer`, data, {
//     params: {
//       limit,
//       skip
//     },
//   }).then((res) => res);
// },