import {
  LOGIN,
  LOGOUT,
  REGISTER,
  ADDRESS,
  UPDATE,
  REFRESH,
  PHONE_VERIFICATION,
  AUTHENTICATOR_STATUS,
  IS_HIDE,
  IS_HIDE_BNB,
} from "./types";

const initialState = {
  token: null,
  email: "",
  id: "",
  address: "",
  createdAt: "",
  firstName: "",
  lastName: "",
  bio: "",
  image: "",
  usd: "",
  country: "",
  isVerified: false,
  isPhoneVerified: false,
  isAdmin: false,
  authenticatorStatus: true,
  isHide: false,
  isHideBnb: false,
};

const UserReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        id: action.payload.user._id,
        email: action.payload.user.email,
        isVerified: action.payload.user.isVerified,
        createdAt: action.payload.user.createdAt,
        firstName: action.payload.user.firstName,
        lastName: action.payload.user.lastName,
        bio: action.payload.user.bio,
        country: action.payload.user.country,
        image: action.payload.user.image,
        usd: 204.97,
        isAdmin: action.payload.user.isAdmin,
        isPhoneVerified: action.payload.user.isPhoneVerified,
        authenticatorStatus: action.payload.user.isAuthenticatorEnable,
        token: action.payload.token,
      };

    case REGISTER:
      return {
        ...state,
        email: action.payload,
      };

    case ADDRESS:
      return {
        ...state,
        address: action.payload,
      };

    case LOGOUT:
      return {
        ...state,
        id: "",
        email: "",
        isVerified: "",
        createdAt: "",
        firstName: "",
        lastName: "",
        bio: "",
        image: "",
      };

    case UPDATE:
      return {
        ...state,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        bio: action.payload.bio,
        image: action.payload.image,
        country: action.payload.country,
      };

    case PHONE_VERIFICATION:
      console.log("reducer");
      return {
        ...state,
        isPhoneVerified: true,
      };

    case AUTHENTICATOR_STATUS:
      return {
        ...state,
        authenticatorStatus: action.payload,
      };

    case REFRESH:
      return {
        ...state,
        id: action.payload._id,
        email: action.payload.email,
        isVerified: action.payload.isVerified,
        createdAt: action.payload.createdAt,
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        bio: action.payload.bio,
        country: action.payload.country,
        image: action.payload.image,
        usd: 204.97,
        isAdmin: action.payload.isAdmin,
        isPhoneVerified: action.payload.isPhoneVerified,
        authenticatorStatus: action.payload.isAuthenticatorEnable,
      };

    case IS_HIDE:
      return {
        ...state,
        isHide: !state.isHide,
      };

    case IS_HIDE_BNB:
      return {
        ...state,
        isHideBnb: !state.isHideBnb,
      };

    default:
      return state;
  }
};

export default UserReducer;
