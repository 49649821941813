import React from "react";
import {
  AiFillStar,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
} from "react-icons/ai";

const Carousel = ({ index, message, selected, setSelected }) => {
  const handlePrevious = () => {
    setTimeout(() => {
      if (selected === 0) {
        setSelected(2);
      } else {
        setSelected(index - 1);
      }
    }, 500);
  };

  const handleNext = () => {
    setTimeout(() => {
      if (selected === 2) {
        setSelected(0);
      } else {
        setSelected(index + 1);
      }
    }, 500);
  };

  return (
    <div
      className={`relative ${
        index === selected
          ? "visible translate-x-1 duration-700 ease-in-out"
          : "hide duration-700 ease-in-out"
      }`}
    >
      <div className="flex flex-col relative gap-3 w-full h-[350px] sm:w-[440px] sm:h-[300px] rounded-[20px] bg-white/[.2] p-10">
        <img className="w-9 h-8" src="/images/commas.svg" alt="img" />
        <p className="text-white py-5">{message}</p>
        <div className="w-full flex flex-row justify-between items-center px-10 flex-wrap gap-2 absolute left-0 bottom-10">
          <div className="flex flex-row items-center gap-2">
            <img src="/images/profile.svg" alt="img" />
            <div className="flex flex-col gap-1">
            <p className="text-[#78FFD6] text-[13px] sm:text-[16px] font-bold">Admin message {index + 1}</p>
            <div className="flex flex-row items-center gap-2">
            {[...Array(5)].map((i, index) => (
              <AiFillStar key={index} style={{ width: "13px", height: "13px", color: "white" }} />
            ))}
          </div>
            </div>
          </div>
          
        </div>
      </div>
      <div
        className="w-9 h-9 flex flex-row justify-center items-center cursor-pointer absolute left-0 inset-y-1/2 bg-[#FFC93E] rounded-full -ml-4"
        onClick={() => handlePrevious()}
      >
        <AiOutlineArrowLeft />
      </div>
      <div
        className="w-9 h-9 flex flex-row justify-center items-center cursor-pointer absolute right-0 inset-y-1/2 bg-[#FFC93E] rounded-full -mr-4"
        onClick={() => handleNext()}
      >
        <AiOutlineArrowRight />
      </div>
    </div>
  );
};

export default Carousel;
