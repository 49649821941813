import React from "react";

const SearchButton = ({ text, isSearch, onClick, disabled }) => {
  return (
    <button
      className="flex flex-row items-center text-black rounded-lg gap-3 px-20 py-2 bg-[#78FFD6] disabled:bg-slate-400 disabled:cursor-not-allowed"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {isSearch && <img src="/images/search.svg" alt="img" />}
    </button>
  );
};

export default SearchButton;
