import React, { useEffect, useState } from "react";
import { io } from "socket.io-client";
import Picker from "emoji-picker-react";
import { BsEmojiSmile } from "react-icons/bs";
import { Chat, UserAuth } from "../../../services";
import { useSelector } from "react-redux";
import { API_URL } from "../../../lib/config";
import moment from "moment";
import {  AiOutlineArrowLeft } from "react-icons/ai";
import { MdCancel } from "react-icons/md";
import ConfirmationModal from "./Modal";

const socket = io.connect(API_URL);
// const socket = io.connect("ws://localhost:5001");

const Conversation = ({ activeChat, setIsView }) => {
  const [message, setMessage] = useState("");
  const [chat, setChat] = useState([]);
  const [showEmoji, setShowEmoji] = useState(false);
  const [user, setUser] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);

  const loginUser = useSelector((state) => state.user);

  useEffect(() => {
    if (activeChat !== null) {
      Chat.getMyChat(activeChat._id)
        .then((res) => {
          if (res.status === 200) {
            setChat(res.data);
          }
        })
        .catch((e) => console.log("e", e));
    }
  }, [activeChat]);

  const onEmojiClick = (event, { emoji }) => {
    console.log({ event, emoji });
    setMessage(message + emoji);
  };

  useEffect(() => {
    socket.on("chat", (payload) => {
      console.log("payload", payload);
      setChat([...chat, payload]);
    });
  }, [chat]);

  useEffect(() => {
    if (activeChat !== null) {
      UserAuth.getSingleUser(
        activeChat.senderId === loginUser.id
          ? activeChat.receiverId
          : activeChat.senderId
      )
        .then((res) => {
          if (res.status === 200) {
            setUser(res.data);
          }
        })
        .catch((e) => console.log("e", e));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat]);

  const handleSend = (event) => {
    event.preventDefault();
    socket.emit("chat", {
      message,
      senderId: loginUser.id,
      receiverId:
        activeChat.senderId === loginUser.id
          ? activeChat.receiverId
          : activeChat.senderId,
    });
    setMessage("");
    setShowEmoji(false); 
  };

  const toPascal = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  // const deleteChat = () => {
  //   setIsOpen(true);
  // };

  return (
    <>
      {activeChat !== null ? (
        <div className="relative h-full">
          {/* header */}
          <div className="w-full h-14 px-3 flex flex-row justify-between items-center bg-[#202C33]">
            <div className="flex flex-row justify-between items-center gap-3">
              <div className="mobile-chat">
                <AiOutlineArrowLeft
                  style={{ color: "white" }}
                  onClick={() => setIsView(false)}
                />
              </div>
              <img
                className="w-8 h-8 rounded-full border border-white"
                src={user.image ? `${user.image}` : "/images/user_profile.png"}
                alt="img"
              />
              <div className="text-white">
                <p className="text-base font-bold">
                  {user.firstName
                    ? `${toPascal(user.firstName)}${" "}${toPascal(
                        user.lastName
                      )}`
                    : "User Name"}
                </p>
                <p className="text-sm text-gray-400">
                  {user.isOnline
                    ? "online"
                    : `last seen ${moment(user.lastSeen).fromNow()}`}
                </p>
              </div>
            </div>
            {/* <div>
              <AiFillDelete
                style={{
                  color: "white",
                  width: "20px",
                  height: "30px",
                  cursor: "pointer",
                }}
                onClick={() => deleteChat()}
              />
            </div> */}
          </div>
          {/*  */}
          <div className="h-full max-h-[400px] overflow-auto scrollbar-hide flex flex-col gap-2 p-3">
            {chat.length ? (
              chat.map((payload, index) => (
                <div
                  key={index}
                  className={
                    loginUser.id === payload.senderId
                      ? "flex flex-row justify-end"
                      : "flex flex-row justify-start"
                  }
                >
                  <div
                    className={
                      loginUser.id === payload.senderId
                        ? `max-w-[60%] flex flex-row justify-end px-4 py-2 rounded-md bg-purple-600`
                        : `max-w-[60%] px-4 py-2 rounded-md bg-gray-300`
                    }
                  >
                    <p
                      className={
                        loginUser.id === payload.senderId
                          ? `text-white`
                          : `text-black`
                      }
                    >
                      {payload.message}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div className="flex justify-center items-center">
                <p>No Messages Found</p>
              </div>
            )}
          </div>
          {/* footer */}
          <form onSubmit={handleSend}>
            <div className="w-full flex flex-row justify-center absolute bottom-0 bg-[#202C33]">
              <div className="w-[100%] flex flex-row justify-between gap-3 p-2">
                <input
                  className="w-4/5 bg-transparent outline-none text-white"
                  type="text"
                  placeholder="Type your message here..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
                {showEmoji && (
                  <div className="emoji-picker">
                    <Picker onEmojiClick={onEmojiClick} />
                  </div>
                )}
                <div className=" flex flex-row items-center gap-2 sm:gap-3">
                  {!showEmoji ? (
                    <BsEmojiSmile
                      style={{
                        color: "white",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowEmoji(true)}
                    />
                  ) : (
                    <MdCancel
                      style={{
                        color: "white",
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setShowEmoji(false)}
                    />
                  )}
                  <button
                    className="px-6 py-1 rounded bg-white"
                    type="submit"
                    disabled={!message.length}
                    onKeyDown={(e) => {
                      if (e.key === "Enter" && !e.shiftKey) {
                        handleSend();
                        e.preventDefault();
                      }
                    }}
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="h-full flex flex-col justify-center items-center gap-5">
          <img className="w-2/5" src="/images/chat.png" alt="img" />
          <p className="text-xl text-center text-white">
            P2Pways Inbox messages
          </p>
        </div>
      )}
      <ConfirmationModal
        modalIsOpen={modalIsOpen}
        setIsOpen={setIsOpen}
        group={activeChat !== null && activeChat._id}
        user={loginUser.id}
      />
    </>
  );
};

export default Conversation;
