import React, { useState, useEffect } from "react";
import moment from "moment";
import { Bids, Offer, UserAuth } from "../../../services";
import Modal from "react-modal";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from "react-toastify";

const DetailModal = ({
  item,
  user,
  offer,
  modalIsOpen,
  afterOpenModal,
  closeModal,
}) => {

  const handleAccept = (id) => {
    closeModal();
    Bids.approveBid(id)
      .then((res) => console.log("+++", res))
      .catch((e) => console.log("---", e));
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 30,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },
        content: {
          position: "absolute",
          top: "50%",
          left: "50%",
          minWidth: "320px",
          maxWidth: "400px",
          minHeight: "300px",
          transform: "translate(-50%, -50%)",
          border: "1px solid #78FFD6",
          background: "#10192F",
          overflow: "auto",
          WebkitOverflowScrolling: "touch",
          outline: "none",
          zIndex: 40,
        },
      }}
    >
      <div className="App flex flex-col justify-evenly items-center py-5 z-50">
        <p className="text-[#78FFD6] text-2xl">
          {`${item.isBuy ? "Buyer" : "Seller"}`} Details
        </p>
        <p className="text-white mt-5"></p>
        <p className="text-white mt-3">1 USDT = {offer.price} USD</p>
        <p className="text-white mt-5">
          {item.pay} USDT = {(item.pay * offer.price).toFixed(2)} USD
        </p>
        <div className="w-[100%] px-10 flex flex-row justify-between items-center gap-20 absolute bottom-10">
          <button
            className="text-white rounded px-5 py-1 bg-[#FF5050]"
            onClick={closeModal}
          >
            Close
          </button>
          <Link
            to={`/transaction/${item._id}`}
            state={{ data: item, user: user }}
          >
            <button
              className="text-[#10192F] rounded px-5 py-1 bg-[#37FF57]"
              onClick={() => handleAccept(item._id)}
            >
              Next
            </button>
          </Link>
        </div>
      </div>
    </Modal>
  );
};

const NotifyFrom = ({ item, update, setUpdate, setIsNotificationOpen }) => {
  const [user, setUser] = useState({});
  const [offer, setOffer] = useState({});
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const time = moment(item.createdAt).fromNow();

  const userId = useSelector((state) => state.user.id);

  useEffect(() => {
    UserAuth.getSingleUser(item.to)
      .then((res) => setUser(res.data))
      .catch((e) => console.log("e", e));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

  useEffect(() => {
    Offer.getSingleOffer(item.offerId).then((res) => {
      if (res.status === 200) {
        setOffer(res.data);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

 

  const deleteNotification = (offerId) => {
    setIsLoading(true);
    Bids.deleteNotification(offerId, userId)
      .then((res) => {
        setUpdate(!update);
        toast.success("Notification deleted successfully");
      })
      .catch((e) => console.log("e", e))
      .finally(() => setIsLoading(false));
  };


  return (
    <div className="border-b border-white relative">
      <div className="p-2">
        <div className="flex flex-row justify-between">
          <div className="flex flex-row items-center gap-2">
            <img
              className="w-[38px] h-[38px] rounded-full border border-[#78FFD6]"
              src={!user.image ? "/images/user_profile.png" : `${user.image}`}
              alt=""
            />
            <div>
              <p className="text-[16px] text-[#78FFD6]">
                {user.firstName
                  ? `${user.firstName} ${user.lastName}`
                  : "User Name"}
              </p>
              <p className="text-[10px] text-white">
                {item.isBuy
                  ? `Your request to buy ${item.pay} coins has been ${
                      item.isRejected ? "rejected" : "accepted"
                    }`
                  : `Your request to sell ${item.pay} coins has been ${
                      item.isRejected ? "rejected" : "accepted"
                    }`}
              </p>
            </div>
          </div>
          <p className="min-w-[60px] float-right text-white text-[10px]">{time}</p>
        </div>
        <div className="flex flex-row justify-center items-center gap-4 mt-2">
          {!item.isCompleted && !item.isRejected ? (
            <Link to={`/transaction/${item._id}`}>
              <button className="text-white rounded px-5 py-1 bg-[#37FF57]">
                View
              </button>
            </Link>
          ) : item.isRejected ? (
            <button
              className="text-white rounded px-5 py-1 bg-red-900"
              disabled={true}
            >
              Rejected
            </button>
          ) : (
            <Link to={`/transaction/${item._id}`}>
              <button className="text-white rounded px-5 py-1 bg-red-900">
                Completed
              </button>
            </Link>
          )}
          {
            <button
              className="text-white rounded-full p-2 bg-gray-500 cursor-pointer disabled:cursor-not-allowed absolute bottom-[10px] right-[10px]"
              onClick={() => deleteNotification(item._id)}
              disabled={isLoading}
            >
              <AiOutlineDelete style={{ color: "white" }} />
            </button>
          }
        </div>
        {/* {!item.isAccepted ? (
            <button
              className="text-white rounded px-5 py-1 bg-[#37FF57]"
              onClick={() => handleModal()}
            >
              {item.isAccepted ? 'Accepted' : 'Accept'}
            </button>
          ) : ( */}
        {/* )} */}
        {/* {!item.isAccepted && (
            <button
              className="text-white rounded px-5 py-1 bg-[#FF5050]"
              onClick={() => handleDecline(item._id)}
            >
              Decline
            </button>
          )} */}
      </div>
      <DetailModal
        item={item}
        user={user}
        offer={offer}
        modalIsOpen={modalIsOpen}
        openModal={openModal}
        closeModal={closeModal}
        afterOpenModal={afterOpenModal}
      />
    </div>
  );
};

export default NotifyFrom;


