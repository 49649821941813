import axios from "axios";
import { API_URL } from "../lib/config";

const UserReviews = {
  postReview: (offerId, userId, receiverId, comment, rating, isBuyer, isPositive, offerBidId) => {
    // console.log('RRRRRR', userId, receiverId, comment, rating, isBuyer, isPositive)
    return axios
      .post(`${API_URL}/v1/review/postReview/${userId}`, {
        offerId, receiverId, comment, rating, isBuyer, isPositive, offerBidId
      })
      .then((res) => res);
  },

  getAllReview: (id) => {
    return axios.get(`${API_URL}/v1/review/getUserReviews/${id}`).then((res) => res);
  },

  getSingleReview: (id) => {
    return axios.get(`${API_URL}/v1/review/getSingleReview/${id}`).then((res) => res);
  },
};

export default UserReviews;
