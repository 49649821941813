import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getTokenBalance } from "../../../utils/getBalance";
import InputGroup from "../../common/InputGroup";
import Select from "react-select";
import { PaymentMethods } from "../../common/PaymentOptions";
import LimitInputGroup from "../../common/LimitInputGroup";
import Offer from "../../../services/offer";

const PostFields = ({
  option,
  minimumPrice,
  maximumPrice,
  myPrice,
  setMinimumPrice,
  setMaximumPrice,
  setMyPrice,
  paymentMethod,
  setPaymentMethod,
  selected,
  setSelected,
}) => {
  const [balance, setBalance] = useState("");
  const user = useSelector((state) => state.user);
  const [postTotalCoins, setPostTotalCoins] = useState(0);
  const [remainingCoins, setRemainingCoins] = useState(balance);

  useEffect(() => {
    if (user.address) {
      getTokenBalance(setBalance, user.address);
    }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    Offer.getMyAllSellingOfferSum(user.id).then((res) => {
      setPostTotalCoins(res.data.sum);
      setRemainingCoins(Number(balance) - Number(res.data.sum));
    })
    .catch((e) => setRemainingCoins(Number(balance) - 0) )
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balance, selected]);

  const handleChange = () => {
    if (option === "sell" && Number(postTotalCoins) > Number(balance)) {
      toast.error(`You don't have enough coins to sell, delete your selling offer to create a new offer`);
    } 
    else if (Number(minimumPrice) < 50) {
      return toast.error("Minimum price should be equal to or greater than 50");
    } else if (Number(minimumPrice) > Number(maximumPrice)) {
      return toast.error("Minimum price should be less than maximum price");
    } else if (Number(minimumPrice) === Number(maximumPrice)) {
      return toast.error("Maximum price should be greater than minimum price");
    } else if (
      Number(maximumPrice) > Number(remainingCoins) &&
      option === "sell"
    ) {
      toast.error(
        `You don't have enough coins to sell, your coins limit are ${remainingCoins.toFixed(2)}`
      );
    } else {
      setSelected(2);
    }
  };

  const changeHandler = (value) => {
    setPaymentMethod(value);
  };

  const style = {
    valueContainer: (base, state) => {
      const minWidth = "200px";
      const maxWidth = "420px";
      const Width = "100%";
      const minHeight = "50px";
      const borderColor = "none";
      const fontSize = "12px";
      return {
        ...base,
        minWidth,
        maxWidth,
        Width,
        minHeight,
        fontSize,
        borderColor,
      };
    },
  };

  return (
    <div className="flex flex-col items-center gap-10">
      <div className="w-11/12">
        <p className="text-xl text-[#78FFD6]">Offer Trade Limits</p>
        <div className="flex flex-row justify-between flex-wrap sm:mx-10 mt-5 gap-5">
          <LimitInputGroup
            label="Minimum USDT"
            placeholder="Enter Min amount 50"
            value={minimumPrice}
            onChange={setMinimumPrice}
            description="Lorem ipsum dolor sit amet, consect etur adipiscing elit. Vestibulum ultri cies mi vel."
            info={true}
            trade="USDT"
          />
          <LimitInputGroup
            label="Maximum USDT"
            placeholder="Enter Max amount (eg. 50.1 and above)"
            value={maximumPrice}
            onChange={setMaximumPrice}
            description="Lorem ipsum dolor sit amet, consect etur adipiscing elit. Vestibulum ultri cies mi vel."
            info={true}
            trade="USDT"
          />
        </div>
        <div className="flex flex-row justify-between flex-wrap mt-5"></div>
        <div className="mt-14">
          <p className="text-xl text-[#78FFD6]">
            Fixed price market rate your offer will list at
          </p>
          <div className="flex flex-row justify-between items-center gap-5 flex-wrap sm:mx-10 mt-5">
            <InputGroup
              placeholder="Enter 
              your offer, exp: 1.01 price is 1% above market price"
              value={myPrice}
              onChange={setMyPrice}
              trade="USD"
            />
            <div className="w-full sm:max-w-[360px] lg:max-w-[420px]">
              <Select
                isMulti={true}
                placeholder="All payment methods"
                styles={style}
                options={PaymentMethods}
                value={paymentMethod}
                onChange={changeHandler}
              />
            </div>
          </div>

          {!!myPrice.length && (
            <p className="text-white sm:mx-10">
              Your price is{" "}
              <span className="text-[#FF0000]">
                {((myPrice - 1 / 1) * 100).toFixed(1)}%
              </span>{" "}
              {(myPrice - 1 / 1) * 100 > 0 ? "above" : "below"} the market price
            </p>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-center mt-5">
        <button
          className="send-coins px-8 py-2 bg-[#98F18A] rounded font-semibold"
          disabled={
            !minimumPrice.length ||
            !maximumPrice.length ||
            !myPrice.length ||
            !paymentMethod.length
          }
          onClick={() => handleChange()}
        >
          NEXT
        </button>
      </div>
    </div>
  );
};

export default PostFields;
