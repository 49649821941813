import React, { useState, useEffect } from "react";
import Auth from "../../common/Auth";
import OtpInput from "react-otp-input";
import { Formik } from "formik";
import * as yup from "yup";
import { UserAuth } from "../../../services";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import QRCode from "qrcode";
import QrCodeModal from "./QrCodeModal";

const VerifyOTP = () => {
  const navigate = useNavigate();
  const code = localStorage.getItem("qr");

  const [state, setState] = useState(false);
  const [timer, setTimer] = useState(60);
  const [qr, setQr] = useState(code);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const user = useSelector((state) => state.user.email);
  const loginUser = useSelector((state) => state.user);

  useEffect(() => {
    if(loginUser.id){
      navigate('/')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  function openModal() {
    setIsOpen(true);
  }

  function afterOpenModal() {}

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    if (timer > 0 && state) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
    if (timer === 0) {
      setState(false);
    }
  }, [timer, state]);

  useEffect(() => {
    QRCode.toDataURL(code)
      .then((url) => {
        setQr(url);
      })
      .catch((err) => {
        console.error(err);
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Auth>
      <div className="flex flex-col justify-center items-center p-5 h-full">
        <div className="flex flex-col justify-evenly items-center p-3 h-5/6 md:mx-10">
          <p className="font-bold text-xl mt-5 text-blue-600">
            Verify Your Email
          </p>

          <p className="text-center text-[13px] text-red-600">
            please check spam or junk email if you did not receive in your inbox
          </p>
          <div className="w-full">
            <p className="text-center font-bold">
              Enter email verifiication code
            </p>
          </div>
          <Formik
            validateOnChange={false}
            initialValues={{
              OTP: "",
            }}
            onSubmit={(values, { resetForm }) => {
              setIsLoading(true);
              resetForm({ values: "" })
              UserAuth.verification(user, values.OTP)
                .then((res) => {
                  console.log("res", res);
                  if (res.status === 200) {
                    // depositBNB(address);
                    toast.success("Verified successfully.");
                    openModal();
                  } else {
                    toast.error("Invalid OTP");
                  }
                })
                .catch((e) => {
                  toast.error("Enter valid code");
                })
                .finally(() => setIsLoading(false));
            }}
            
            validationSchema={yup.object().shape({
              OTP: yup.string().min(6).required("OTP is required"),
            })}
          >
            {({ handleChange, handleSubmit, ...props }) => (
              <form onSubmit={handleSubmit}>
                <div className="min-h-[80px]">
                  <OtpInput
                    containerStyle="gap-3 sm:gap-5"
                    inputStyle="border-2 border-[#00739D] min-w-[36px] min-h-[40px] rounded-xl focus:outline-none"
                    value={props.values.OTP}
                    onChange={handleChange("OTP")}
                    numInputs={6}
                    isInputNum={true}
                    isInputSecure={false}
                  />
                  {props.touched.OTP && props.errors.OTP && (
                    <div className="text-red-600 mt-2">{props.errors.OTP}</div>
                  )}
                  {state && (
                    <div className="w-full text-center">
                      <p className="text-[#FF6262] mt-3">00:{timer}</p>
                      <p className="text-[#FF6262] mt-3 cursor-pointer">
                        Send Again
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-center items-center mt-20">
                  <input
                    type="submit"
                    className="flex flex-row items-center text-black rounded-lg gap-3 px-20 py-2 bg-[#78FFD6] disabled:bg-slate-400 disabled:cursor-not-allowed"
                    disabled={isLoading}
                    value="Confirm"
                  />
                  
                  {/* <SearchButton
                    text="Confirm"
                    type="submit"
                    onClick={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        handleSubmit();
                      }
                    }}
                  /> */}
                </div>
              </form>
            )}
          </Formik>
        </div>
        <QrCodeModal
          modalIsOpen={modalIsOpen}
          openModal={openModal}
          closeModal={closeModal}
          afterOpenModal={afterOpenModal}
          qr={qr}
        />
      </div>
    </Auth>
  );
};

export default VerifyOTP;
