import React from "react";

const ContactInput = ({ label, type, placeholder, value, onChange, error }) => {
  const styles = {
    select:
      "form-select block w-full h-[40px] px-3 py-1 h-12 text-base font-normal text-gray-500 bg-white bg-clip-padding bg-no-repeat border border-solid border-gray-300 rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none",
  };
  return (
    <div className="w-full h-[80px]">
      <p className="text-[#78FFD6]">{label}</p>
      <input
        className={styles.select}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
      <p className="text-[#FF0000] text-[12px]">{error}</p>
    </div>
  );
};

export default ContactInput;
