import React from "react";
import NotifyTo from "./NotifyTo";
import NotifyFrom from "./NotifyFrom";
import { useSelector } from "react-redux";

const Notification = (props) => {
  const { item, update, setUpdate, setIsNotificationOpen } = props;
  const loginuser = useSelector((state) => state.user);


  return (
    <div className="z-50">
      {(loginuser.id === item.to && !item.isReceiverDeleted && !item.isRejected) ? (
        <NotifyTo item={item} update={update} setUpdate={setUpdate} />
      ) : (loginuser.id === item.from && !item.isSenderDeleted && (item.isApproved || item.isRejected)) ? (
        <NotifyFrom
          item={item}
          update={update}
          setUpdate={setUpdate}
          setIsNotificationOpen={setIsNotificationOpen}
        />
      ) : null}
    </div>
  );
};

export default Notification;
